<template>
  <div>
    <!--AR远成操作按钮-->
    <div class="AROptionsList">
      <div>
        <div class="arBnt" @click="changeAR_Mic">
          <img v-if="AR_Mic === 0" src="./img/arAudio_close.png" alt="" />
          <img v-else src="./img/arAudio_open.png" alt="" />
        </div>
        <p>{{ AR_Mic === 1 ? $t('closeAudio') : $t('openAudio') }}</p>
      </div>
      <!-- 闪光灯 -->
      <template v-if="AR_Camera===1">
        <div>
        <div
          class="arBnt"
          :class="{ active: AR_Flashlight === 1 || AR_Camera===1}"
        >
          <img src="./img/flashlight_open.png" alt="" />
        </div>
        <p>{{ AR_Flashlight === 1 || AR_Camera===1 ? $t('closeLight') : $t('openLight') }}</p>
      </div>
      </template>
      <template v-else>
        <div>
          <div
            class="arBnt"
            :class="{ active: AR_Flashlight === 1}"
            @click="changeAR_Flashlight"
          >
            <img src="./img/flashlight_open.png" alt="" />
          </div>
          <p>{{ AR_Flashlight === 1 ? $t('closeLight') : $t('openLight') }}</p>
        </div>
      </template>

       <!-- 主相机 -->
      <div>
        <div
          class="arBnt"
          :class="{ active: AR_Camera === 0 || mainCameraId === 0}"
          @click="changeAR_Camera(0)"
        >
          <img src="./img/ARVideo_open.png" alt="" />
        </div>
<!--        <p>{{ AR_CameraText[mainCameraId] }}</p>-->
        <p>{{ $t('mainCamera') }}</p>
      </div>
      <!-- 副相机 -->
      <div>
        <div
          class="arBnt"
          :class="{ active: AR_Camera === 1 || deputyCameraId === 0 }"
          @click="changeAR_Camera(1)"
        >
          <img src="./img/thermalImg_open.png" alt="" />
        </div>
<!--        <p>{{ AR_CameraText[deputyCameraId] }}</p>-->
        <p>{{ $t('secondaryCamera') }}</p>
      </div>
      <div>
        <div class="arBnt" @click="photograph">
          <img src="./img/ARCamera_open.png" alt="" />
        </div>
        <p>{{ $t('photograph') }}</p>
      </div>
      <div>
        <div
          class="arBnt"
          :class="{ disabled: AR_Record === 1 }"
          @click="openRecord"
        >
          <img src="./img/ARRecord_open.png" alt="" />
        </div>
        <p>{{ AR_Record === 1 ? $t('ARRClose') : $t('ARRecord') }}</p>
      </div>
      <template
        v-if="
          (AR_Camera === 0 && (mainCameraId === 1 || mainCameraId === 2)) ||
          (AR_Camera === 1 && (deputyCameraId === 1 || deputyCameraId === 2))
        "
      >
        <div>
          <div
            class="arBnt"
            :class="{ disabled: cameraZoom === 0 }"
            @click="zoomMinish"
          >
            <img src="./img/Camera_Zoom_Minish.png" alt="" />
          </div>
          <p>{{ $t('zoomMinish') }}</p>
        </div>
        <div>
          <div
            class="arBnt"
            :class="{ disabled: cameraZoom === 5 }"
            @click="zoomLargen"
          >
            <img src="./img/Camera_Zoom_Largen.png" alt="" />
          </div>
          <p>{{ $t('zoomLargen') }}</p>
        </div>
      </template>
      <slot name="arBottomBtn"></slot>
    </div>
    <!--热成像相关按钮-->
    <div
      class="thermalImgBtns"
      v-if="
        (AR_Camera === 0 && mainCameraId === 5) ||
        (AR_Camera === 1 && deputyCameraId === 5)
      "
    >
      <div @click="changeAR_Thermograph">
        {{ AR_Thermograph === 1 ? $t('closeHotImg') : $t('openHotImg') }}
      </div>
      <template v-if="AR_Thermograph === 1">
        <div @click="changeAR_AreaModel">
          {{ AR_AreaModel === 1 ? $t('centerMode') : $t('areaMode') }}
        </div>
        <div @click="changeAR_Thermometry">
          {{
            AR_Thermometry === 1 ? $t('closeMultipoint') : $t('openMultipoint')
          }}
        </div>
        <div @click="changeAR_color(1)" :class="{ active: AR_color === 1 }">
          {{ $t('color1') }}
        </div>
        <div @click="changeAR_color(2)" :class="{ active: AR_color === 2 }">
          {{ $t('color2') }}
        </div>
        <div @click="changeAR_color(3)" :class="{ active: AR_color === 3 }">
          {{ $t('color3') }}
        </div>
      </template>
    </div>
    <!-- 全景相机操作 -->
    <div
      class="AR_Camera360"
      v-if="
        (AR_Camera === 0 && mainCameraId === 6) ||
        (AR_Camera === 1 && deputyCameraId === 6)
      "
    >
      <div class="top" @click="changeInstaCamera('Insta_Camera_Up')"></div>
      <div class="right" @click="changeInstaCamera('Insta_Camera_Right')"></div>
      <div class="bottom" @click="changeInstaCamera('Insta_Camera_Down')"></div>
      <div class="left" @click="changeInstaCamera('Insta_camera_left')"></div>
      <div class="center"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    userWss: {
      // 引擎实例
      type: WebSocket,
      default: () => null,
    },
    mainVideoUserId: {
      // 当前主视频框AR人员id
      type: String,
      default: '',
    },
    groupId: {
      // 房间id
      type: String,
      default: '',
    },
  },
  data() {
    return {
      mainVideoUserAccountId: '', // 主视频框用户id
      AR_Mic: 1, // 麦克风状态  1 开启 0 关闭
      AR_Camera: 0, // 当前使用的相机 0 主相机  1 副相机
      AR_AreaModel: 0, // 1 区域模式 0：中心模式
      AR_Thermograph: 0, // 热力图 1 开启 0 关闭
      AR_Thermometry: 0, // 多点测温 1 开启 0 关闭
      AR_color: 1, // 色  1：模式一    2：模式二    3：模式三
      AR_Flashlight: 0, // 闪光灯 1： 开    0：关
      AR_Type: 0, // 设备类型 1： D2    2：MATE30
      AR_Record: 0, // 云录制 0 正常 1 正在录制 2已暂停
      cameraZoom: 0, // 放大挡位（0，最小，5最大）
      mainCameraId: 0, // 主相机使用的相机编号 0 未配置相机 1 手机后置 2手机前置 3 主相机的UVC 4 副相机的UVC 5 热成像相机 6 360相机 7  艾瑞电子热成像摄像头 8 第二个后置摄像头
      deputyCameraId: 0, // 副相机使用的相机编号 0 未配置相机 1 手机后置 2手机前置 3 主相机的UVC 4 副相机的UVC 5 热成像相机 6 360相机 7 艾瑞电子热成像摄像头 8 第二个后置摄像头
      AR_CameraText: {
        0: this.$t('AR_CameraText0'),
        1: this.$t('AR_CameraText1'),
        2: this.$t('AR_CameraText2'),
        3: this.$t('AR_CameraText3'),
        4: this.$t('AR_CameraText4'),
        5: this.$t('AR_CameraText5'),
        6: this.$t('AR_CameraText6'),
      },
    }
  },
  computed: {
    // 用户信息
    user() {
      return this.$store.state.user
    },
  },
  mounted() {
    this.$ajax({
      url: this.$api.userAccountFindInfo,
      data: {
        accountId: this.mainVideoUserId,
      },
    }).then((data) => {
      console.log('fasdfdasfsa', data)
      // 通过登录名获取用户id
      this.mainVideoUserAccountId = data.uid
      // 每5秒获取一次AR设备状态
      this.getARCurrentState()
    })
  },
  // 页面卸载时
  beforeDestroy() {
    if (this.ARStateTimer) {
      window.clearInterval(this.ARStateTimer)
      this.ARStateTimer = null
    }
  },
  methods: {
    // 用户消息发送
    sendUserMsg(order) {
      console.log('sendUserMsg', this.user)
      const { accountUid, accountId } = this.user
      const params = {
        type: 'RECEIVE_CUSTOM_MESSAGE',
        body: {
          userAccountUidSet: [this.mainVideoUserAccountId], // 用户id，不是accountId
          bodyJson: {
            type: 'CONTROLLER_ORDER',
            userAccountUid: accountUid,
            accountId: accountId,
            data: {
              zoom_value: -1,
              order,
            },
          },
        },
      }
      console.log(params)
      this.userWss.send(JSON.stringify(params))
    },
    // 监听用户消息
    onRecvUserMsg(res) {
      if (res.type !== 'PUB_CUSTOM_MESSAGE') return
      const { body } = res
      const { bodyJson } = body
      let type, data, order, result
      if (bodyJson) {
        type = bodyJson.type
        data = bodyJson.data
      }
      if (data) {
        order = data.order
        result = data.result
      }
      switch (type) {
        // AR端上报状态
        case 'AR_STATUS': {
          this.AR_Mic = data.microphone
          this.AR_Camera = data.camera
          this.AR_AreaModel = data.thermalImagingMode
          this.AR_Thermograph = data.thermodynamicDiagram
          this.AR_Thermometry = data.multipointTemperatureMeasurement
          this.AR_color = data.paletteModel
          this.AR_Flashlight = data.flashLamp
          this.AR_Type = data.deviceType
          this.AR_Record = data.yunRecord
          this.cameraZoom = data.cameraZoom
          this.mainCameraId = data.mainCameraId
          this.deputyCameraId = data.deputyCameraId
          break
        }
        // 控制AR
        case 'CONTROLLER_ORDER': {
          switch (order) {
            // ----------------------------------麦克风--------------------------------------
            // 打开麦克风
            case 'Open_Mic': {
              if (result === 'YES') {
                this.AR_Mic = 1
                this.$utils.showMsg(this.$t('AR_F_WIN_OpenMic_Success'))
              } else {
                this.$utils.showMsg(
                  this.$t('AR_F_WIN_OpenMic_Error'),
                  'warning'
                )
              }
              break
            }
            // 关闭麦克风
            case 'Close_Mic': {
              if (result === 'YES') {
                this.AR_Mic = 0
                this.$utils.showMsg(this.$t('AR_F_WIN_CloseMic_Success'))
              } else {
                this.$utils.showMsg(
                  this.$t('AR_F_WIN_CloseMic_Error'),
                  'warning'
                )
              }
              break
            }
            // ----------------------------------选择相机--------------------------------------
            // 打开主相机
            case 'AR_Camera_Main': {
              if (result === 'YES') {
                this.AR_Camera = 0
                this.$utils.showMsg(this.$t('AR_Camera_Main_Success'))
              } else {
                this.$utils.showMsg(this.$t('AR_Camera_Main_Error'), 'warning')
              }
              break
            }
            // 打开副相机
            case 'AR_Camera_Deputy': {
              if (result === 'YES') {
                this.AR_Camera = 1
                this.$utils.showMsg(this.$t('AR_Camera_Deputy_Success'))
              } else {
                this.$utils.showMsg(
                  this.$t('AR_Camera_Deputy_Error'),
                  'warning'
                )
              }
              break
            }
            // -------------------------------区域模式与中心模式--------------------------------
            // 打开区域模式
            case 'Regional_model': {
              if (result === 'YES') {
                this.AR_AreaModel = 1
                this.$utils.showMsg(this.$t('AR_F_WIN_Regional_model_Success'))
              } else {
                this.$utils.showMsg(
                  this.$t('AR_F_WIN_Regional_model_Error'),
                  'warning'
                )
              }
              break
            }
            // 打开中心模式
            case 'Central_mode': {
              if (result === 'YES') {
                this.AR_AreaModel = 0
                this.$utils.showMsg(this.$t('AR_F_WIN_Central_mode_Success'))
              } else {
                this.$utils.showMsg(
                  this.$t('AR_F_WIN_Central_mode_Error'),
                  'warning'
                )
              }
              break
            }
            // ----------------------------------热力图--------------------------------------
            // 打开热力图
            case 'Open_Thermograph': {
              if (result === 'YES') {
                this.AR_Thermograph = 1
                this.$utils.showMsg(this.$t('AR_F_WIN_OpenThermograph_Success'))
              } else {
                this.$utils.showMsg(
                  this.$t('AR_F_WIN_OpenThermograph_Error'),
                  'warning'
                )
              }
              break
            }
            // 关闭热力图
            case 'Close_Thermograph': {
              if (result === 'YES') {
                this.AR_Thermograph = 0
                this.$utils.showMsg(
                  this.$t('AR_F_WIN_CloseThermograph_Success')
                )
              } else {
                this.$utils.showMsg(
                  this.$t('AR_F_WIN_CloseThermograph_Error'),
                  'warning'
                )
              }
              break
            }
            // ----------------------------------多点测温--------------------------------------
            // 打开多点测温
            case 'Open_Multipoint_temperature_measurement': {
              if (result === 'YES') {
                this.AR_Thermometry = 1
                this.$utils.showMsg(
                  this.$t(
                    'AR_F_WIN_OpenMultipoint_temperature_measurement_Success'
                  )
                )
              } else {
                this.$utils.showMsg(
                  this.$t(
                    'AR_F_WIN_OpenMultipoint_temperature_measurement_Error'
                  ),
                  'warning'
                )
              }
              break
            }
            // 关闭多点测温
            case 'Close_Multipoint_temperature_measurement': {
              if (result === 'YES') {
                this.AR_Thermometry = 0
                this.$utils.showMsg(
                  this.$t(
                    'AR_F_WIN_CloseMultipoint_temperature_measurement_Success'
                  )
                )
              } else {
                this.$utils.showMsg(
                  this.$t(
                    'AR_F_WIN_CloseMultipoint_temperature_measurement_Error'
                  ),
                  'warning'
                )
              }
              break
            }
            // ----------------------------------调色--------------------------------------
            // 打开调色一
            case 'Palette_mode_1': {
              if (result === 'YES') {
                this.AR_color = 1
                this.$utils.showMsg(this.$t('AR_F_WIN_Palette_mode_1_Success'))
              } else {
                this.$utils.showMsg(
                  this.$t('AR_F_WIN_Palette_mode_1_Error'),
                  'warning'
                )
              }
              break
            }
            // 打开调色二
            case 'Palette_mode_2': {
              if (result === 'YES') {
                this.AR_color = 2
                this.$utils.showMsg(this.$t('AR_F_WIN_Palette_mode_2_Success'))
              } else {
                this.$utils.showMsg(
                  this.$t('AR_F_WIN_Palette_mode_2_Error'),
                  'warning'
                )
              }
              break
            }
            // 打开调色三
            case 'Palette_mode_3': {
              if (result === 'YES') {
                this.AR_color = 3
                this.$utils.showMsg(this.$t('AR_F_WIN_Palette_mode_3_Success'))
              } else {
                this.$utils.showMsg(
                  this.$t('AR_F_WIN_Palette_mode_3_Error'),
                  'warning'
                )
              }
              break
            }
            // ----------------------------------闪关灯--------------------------------------
            // 打开闪光灯
            case 'Open_Flash_lamp': {
              if (result === 'YES') {
                this.AR_Flashlight = 1
                this.$utils.showMsg(this.$t('AR_F_WIN_OpenFlash_lamp_Success'))
              } else {
                this.$utils.showMsg(
                  this.$t('AR_F_WIN_OpenFlash_lamp_Error'),
                  'warning'
                )
              }
              break
            }
            // 关闭闪光灯
            case 'Close_Flash_lamp': {
              if (result === 'YES') {
                this.AR_Flashlight = 0
                this.$utils.showMsg(this.$t('AR_F_WIN_CloseFlash_lamp_Success'))
              } else {
                this.$utils.showMsg(
                  this.$t('AR_F_WIN_CloseFlash_lamp_Error'),
                  'warning'
                )
              }
              break
            }
            // ----------------------------------拍照--------------------------------------
            // 拍照
            case 'Photograph': {
              if (result === 'YES') {
                this.$utils.showMsg(this.$t('AR_F_WIN_Photograph_Success'))
              } else {
                this.$utils.showMsg(
                  this.$t('AR_F_WIN_Photograph_Error'),
                  'warning'
                )
              }
              break
            }
            // ----------------------------------录制--------------------------------------
            // 打开录制
            case 'Recording': {
              if (result === 'YES') {
                this.AR_Record = 1
                this.$utils.showMsg(this.$t('AR_F_WIN_Recording_Success'))
              } else {
                this.$utils.showMsg(
                  this.$t('AR_F_WIN_Recording_Error'),
                  'warning'
                )
              }
              break
            }
            // ----------------------------------放大--------------------------------------
            case 'Camera_Zoom_Largen': {
              if (result === 'YES') {
                this.cameraZoom++
                this.$utils.showMsg(this.$t('Camera_Zoom_Largen_Success'))
              } else {
                this.$utils.showMsg(
                  this.$t('Camera_Zoom_Largen_Error'),
                  'warning'
                )
              }
              break
            }
            // ----------------------------------缩小--------------------------------------
            case 'Camera_Zoom_Minish': {
              if (result === 'YES') {
                this.cameraZoom--
                this.$utils.showMsg(this.$t('Camera_Zoom_Minish_Success'))
              } else {
                this.$utils.showMsg(
                  this.$t('Camera_Zoom_Minish_Error'),
                  'warning'
                )
              }
              break
            }
            // ----------------------------------全景相机--------------------------------------
            case 'Insta_camera_left': {
              if (result === 'YES') {
                this.$utils.showMsg(this.$t('Insta_camera_left_Success'))
              } else {
                this.$utils.showMsg(
                  this.$t('Insta_camera_left_Error'),
                  'warning'
                )
              }
              break
            }
            case 'Insta_Camera_Right': {
              if (result === 'YES') {
                this.$utils.showMsg(this.$t('Insta_Camera_Right_Success'))
              } else {
                this.$utils.showMsg(
                  this.$t('Insta_Camera_Right_Error'),
                  'warning'
                )
              }
              break
            }
            case 'Insta_Camera_Up': {
              if (result === 'YES') {
                this.$utils.showMsg(this.$t('Insta_Camera_Up_Success'))
              } else {
                this.$utils.showMsg(this.$t('Insta_Camera_Up_Error'), 'warning')
              }
              break
            }
            case 'Insta_Camera_Down': {
              if (result === 'YES') {
                this.$utils.showMsg(this.$t('Insta_Camera_Down_Success'))
              } else {
                this.$utils.showMsg(
                  this.$t('Insta_Camera_Down_Error'),
                  'warning'
                )
              }
              break
            }
            default: // do something
          }
          break
        }
        default: // do something
      }
    },
    // 每5秒获取一次AR设备状态
    getARCurrentState() {
      const { accountUid, accountId } = this.user
      const params = JSON.stringify({
        type: 'RECEIVE_CUSTOM_MESSAGE',
        body: {
          userAccountUidSet: [this.mainVideoUserAccountId], // 用户id，不是accountId
          bodyJson: {
            type: 'AR_STATUS',
            userAccountUid: accountUid,
            accountId: accountId,
          },
        },
      })
      this.userWss.send(params)
      this.ARStateTimer = setInterval(() => {
        this.userWss.send(params)
      }, 1000)
    },
    // 打开与关闭麦克风
    changeAR_Mic() {
      if (this.AR_Mic === 1) {
        this.sendUserMsg('Close_Mic')
      } else {
        this.sendUserMsg('Open_Mic')
      }
    },
    // 选择相机
    changeAR_Camera(type) {
      if (this.AR_Camera === type) return
      // 主相机无相机
      if (type === 0 && this.mainCameraId === 0) return
      // 副相机无相机
      if (type === 1 && this.deputyCameraId === 0) return
      switch (type) {
        case 0:
          this.sendUserMsg('AR_Camera_Main')
          break
        case 1:
          this.sendUserMsg('AR_Camera_Deputy')
          break
        default: // do something
      }
    },
    // 切换区域模式和中心模式
    changeAR_AreaModel() {
      if (this.AR_AreaModel === 1) {
        this.sendUserMsg('Central_mode')
      } else {
        this.sendUserMsg('Regional_model')
      }
    },
    // 打开与关闭热力图
    changeAR_Thermograph() {
      if (this.AR_Thermograph === 1) {
        this.sendUserMsg('Close_Thermograph')
      } else {
        this.sendUserMsg('Open_Thermograph')
      }
    },
    // 打开与关闭多点测温
    changeAR_Thermometry() {
      if (this.AR_Thermometry === 1) {
        this.sendUserMsg('Close_Multipoint_temperature_measurement')
      } else {
        this.sendUserMsg('Open_Multipoint_temperature_measurement')
      }
    },
    // 打开调色
    changeAR_color(type) {
      if (this.AR_color === type) return
      switch (type) {
        case 1:
          this.sendUserMsg('Palette_mode_1')
          break
        case 2:
          this.sendUserMsg('Palette_mode_2')
          break
        case 3:
          this.sendUserMsg('Palette_mode_3')
          break
        default: // do something
      }
    },
    // 打开与关闭闪光灯
    changeAR_Flashlight() {
      if (this.AR_Flashlight === 1) {
        this.sendUserMsg('Close_Flash_lamp')
      } else {
        this.sendUserMsg('Open_Flash_lamp')
      }
    },
    // 拍照
    photograph() {
      this.sendUserMsg('Photograph')
    },
    // 打开录制
    openRecord() {
      if (this.AR_Record === 1) return
      this.sendUserMsg('Recording')
    },
    // 放大
    zoomLargen() {
      if (this.cameraZoom >= 5) return
      this.sendUserMsg('Camera_Zoom_Largen')
    },
    // 缩小
    zoomMinish() {
      if (this.cameraZoom <= 0) return
      this.sendUserMsg('Camera_Zoom_Minish')
    },
    // 操作全景相机
    changeInstaCamera(type) {
      this.sendUserMsg(type)
    },
  },
}
</script>

<style lang="scss">
.AR_Camera360 {
  position: absolute;
  z-index: 11px;
  bottom: 50px;
  right: 30px;
  width: 140px;
  height: 140px;

  div {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;

    &.top,
    &.bottom,
    &.left,
    &.right {
      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }
    }

    &.top,
    &.bottom {
      width: 96px;
      height: 46px;
      left: 22px;
    }

    &.top {
      top: 0;
      background-image: url('./img/fullView_top.png');
    }

    &.bottom {
      bottom: 0;
      background-image: url('./img/fullView_bottom.png');
    }

    &.left,
    &.right {
      width: 46px;
      height: 96px;
      top: 22px;
    }

    &.left {
      left: 0;
      background-image: url('./img/fullView_left.png');
    }

    &.right {
      right: 0;
      background-image: url('./img/fullView_right.png');
    }

    &.center {
      width: 58px;
      height: 58px;
      border-radius: 50%;
      background: #a6a6a6;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.AROptionsList {
  position: absolute;
  left: 0;
  bottom: 150px;
  right: 0;
  z-index: 11px;
  text-align: center;
  color: #fff;
  height: 0;

  > div {
    display: inline-block;
    margin: 0;
    min-width: 80px;
  }

  .arBnt {
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background:#CFD8E5;
    cursor: pointer;
    transition: all 0.3s;

    &.disabled,
    &.active {
      background-color: #ccc !important;
      cursor: no-drop;
    }

    &:hover {
      background: #000;
    }
  }

  p {
    margin-top: 20px;
  }

  img {
    width: 34px;
    height: 34px;
    margin: 13px;
  }
}

.thermalImgBtns {
  position: absolute;
  left: 0;
  bottom: 220px;
  right: 0;
  z-index: 11px;
  text-align: center;
  color: #fff;
  height: 0;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -70px;
    border: 15px solid transparent;
    border-top-color: rgba(0, 0, 0, 0.5);
  }

  > div {
    display: inline-block;
    min-width: 120px;
    height: 40px;
    line-height: 40px;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    transition: all 0.3s;

    &.active {
      background-color: #000;
    }

    &:first-child {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    &:last-child {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    &:hover {
      background: rgba(0, 0, 0, 1);
    }
  }
}
</style>
