<template>
  <el-dialog
      ref="onresizeDialog"
      v-if="value"
      class="baseDialog"
      :width="width"
      :title="title"
      :center="center"
      :append-to-body="true"
      :close-on-click-modal="closeClickModal"
      :close-on-press-escape="closePressEscape"
      :visible.sync="value"
      :before-close="close">
    <div class="dialog-content" :style="{height: height}">
      <slot/>
    </div>
    <div slot="footer" class="dialog-footer" v-if="showFooter">
      <slot name="footer">
        <el-button @click="close" v-if="showClose" size="small">{{$t('cancel')}}</el-button>
        <el-button type="primary" v-if="showConfirm" @click="confirm" size="small">{{$t('confirm')}}</el-button>
      </slot>
    </div>
  </el-dialog>
</template>

<script>
import Index from './_index.js';

export default Index;
</script>

<style lang="scss">
  @import "./_index.scss";
</style>
