
// const watermark = {}

// const setWatermark = (str1, str2) => {
//   const id = '1.23452384164.123412415'

//   if (document.getElementById(id) !== null) {
//     document.body.removeChild(document.getElementById(id))
//   }

//   const can = document.createElement('canvas')
//   can.width = 260
//   can.height = 200

//   const cans = can.getContext('2d')
//   cans.rotate(-20 * Math.PI / 180) // 画布里面文字的旋转角度
//   cans.font = '13px Vedana'
//   cans.fillStyle = 'rgba(242,152,152, 0.8)'
//   cans.textAlign = 'left' // 画布里面文字的水平位置
//   cans.textBaseline = 'Middle' // 画布里面文字的垂直位置
//   cans.fillText(str1, can.width / 110, can.height / 2) // 画布里面文字的间距比例
//   cans.fillText(str2, can.width / 110, can.height / 2 + 20)

//   const div = document.createElement('div')
//   div.id = id
//   div.style.pointerEvents = 'none'
//   div.style.top = '0px'
//   div.style.left = '0px'
//   div.style.bottom = '0px'
//   div.style.position = 'absolute'
//   div.style.zIndex = '0'
//   div.style.width = document.documentElement.clientWidth + 'px'
//   div.style.height = document.documentElement.clientHeight + 'px'
//   div.style.background = 'url(' + can.toDataURL('image/png') + ') left top repeat'
//   document.body.appendChild(div)
//   return id
// }

// // 该方法只允许调用一次
// watermark.set = (str1, str2) => {
//   let id = setWatermark(str1, str2)
//   setInterval(() => {
//     if (document.getElementById(id) === null) {
//       id = setWatermark(str1, str2)
//     }
//   }, 500)
//   window.onresize = () => {
//     setWatermark(str1, str2)
//   }
// }

// export default watermark

const docHeight = document.body.clientHeight // BODY对象高度
const docWidth = document.body.clientWidth // BODY对象宽度
let standarHeight = 1
if (docWidth < 1680) {
  // const standarWidth = docWidth / 1920
  standarHeight = docHeight / 1080
}

const watermark = {};
const id = 'set-customer-watermark-id'
const setWatermark = (dom, str1) => {
  const newId = id + dom.id
  console.log('dom', dom.id)
  if (document.getElementById(newId) !== null) {
    dom ? dom.removeChild(document.getElementById(newId)) : document.body.removeChild(document.getElementById(newId));
  }

  // 创建一个画布
  const can = document.createElement("canvas");
  can.width = 50; // 380 50
  can.height = 20; // 130 20

  // 设置画布样式
  const cans = can.getContext("2d");
  cans.rotate((-20 * Math.PI) / 180); // 画布里面文字的旋转角度
  cans.font = "8px Vedana";
  cans.fillStyle = 'rgba(242,152,152, 0.4)';
  cans.textAlign = "left";
  cans.textBaseline = "Middle";
  cans.fillText(str1, 0, can.height);
  // cans.fillText(str2, 0, can.height + 15);
  // cans.fillText(str1, can.width / 110, can.height / 2) // 画布里面文字的间距比例
  // cans.fillText(str2, can.width / 110, can.height / 2 + 20)

  // 创建整体容器，设置样式
  const div = document.createElement("div");
  div.id = newId
  div.style.pointerEvents = "none";
  div.style.top = "0px";
  div.style.left = "0px";
  // div.style.bottom = '0px'
  div.style.position = "absolute";
  div.style.zIndex = "0";
  div.style.width = document.documentElement.clientWidth / standarHeight + "px";
  div.style.height = document.documentElement.clientHeight / standarHeight + "px";
  div.style.background =
    "url(" + can.toDataURL("image/png") + ") left top repeat";
  dom ? dom.appendChild(div) : document.body.appendChild(div);
  return newId;
};

// 设置水印
watermark.set = (dom, str1) => {
  let id = setWatermark(dom, str1);
  setInterval(() => {
    console.log(1111, document.getElementById(id) === null)
    if (document.getElementById(id) === null) {
      id = setWatermark(dom, str1);
    }
  }, 500);
  window.onresize = () => {
    setWatermark(dom, str1);
  };
};

// 清除水印
watermark.remove = (dom) => {
  const newId = id + dom.id
  if (document.getElementById(newId) !== null) {
    document.getElementById(newId).style.display = "none";
    // dom.removeChild(document.getElementById(newId))
  }
};

export default watermark
