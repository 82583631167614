import Vue from "vue";
import Vuex from "vuex";
import { setSessionStorage, getSessionStorage } from "./../helpers/utils";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: getSessionStorage("user") || null, // 用户信息
    isFold: false, // 是否折叠左边菜单
  },
  mutations: {
    // 更新用户信息
    updateUser(state, user) {
      this.state.user = user;
      setSessionStorage("user", user);
    },
    // 折叠左边菜单
    updateIsFold(state) {
      state.isFold = !state.isFold;
    }
  },
  actions: {},
  modules: {}
});
