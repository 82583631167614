<template>
  <div>
    <audio id="audioMic" autoplay ref="audioMic"></audio>
    <div class="devicesBox">
      <el-row>
         <el-col :span="24" class="camvideo">
           <video ref="camvideo" autoplay playsinline id="camvideo" width="400" height="234"  style="object-fit: contain" ></video>
           <!-- <canvas id="canvas" width="416" height="234" style="display: none;"></canvas> -->
         </el-col>
      </el-row>
      <el-row>
        <el-col :span="4"><div class="txtFont">{{$t('camera')}}：</div></el-col>
         <el-col :span="20">
          <el-select id="changeCmaID"
            :placeholder="$t('cameraPlaceholder')"
            v-model="defaultCamCopy"
            size="mini"
            @change="changeCamere"
            :disabled="isSupportCam"
            :popper-append-to-body="false"
            class="txtFont"
          >
            <el-option
              v-for="(item,index) in camList"
              :key="index"
              :label="item.label"
              :value="item.deviceId"
            />
          </el-select>
        </el-col>
      </el-row>
       <!-- <el-row>
         <el-col :span="12">
            <div class="txtFont"><el-checkbox disabled>{{ $t('openMirror') }}</el-checkbox></div>
         </el-col>
         <el-col :span="12" style="text-align: right;">
           <el-button type="primary" size="mini" class="mirrorBtn" @click="takePhoto" disabled>
             <img src="../../views/index/img/mirror.png" class="elmirror" /><span>{{ $t('revolveCamera') }}</span></el-button>
         </el-col>
      </el-row> -->
      <el-row>
        <el-col :span="4"><div class="txtFont">{{$t('microphone')}}：</div></el-col>
        <el-col :span="20">
          <el-select id="changeAudioID"
            :placeholder="$t('microphonePlaceholder')"
            v-model="defaultAudio"
            size="mini"
            @change="changeAudio"
            :disabled="isSupportAudio"
            :popper-append-to-body="false"
            class="txtFont"
          >
            <el-option
              v-for="(item,index) in audioList"
              :key="index"
              :label="item.label"
              :value="item.deviceId"
            />
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4"><div class="txtFont" style="margin-top: 3px;">{{this.$t('setVolume')}}：</div></el-col>
        <el-col :span="20">
          <el-slider v-model="audioVolume" size="mini" @change="changeVolumeMic" :disabled="isSupportAudio" :append-to-body="false" :show-tooltip="false"></el-slider>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="20">
          <div class="txtFont txtWidth">{{this.$t('setMicroPhoneVolume')}}</div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="5">
            <el-button type="primary" plain size="mini"  @click="closeAudio" v-if="audioCheck" :disabled="isSupportAudio" class="micBtn">{{audioBtn}}</el-button>
            <el-button type="primary" plain size="mini" class="micBtn" @click="testAudio" v-if="!audioCheck" :disabled="isSupportAudio">{{audioBtn}}</el-button>
        </el-col>
        <el-col :span="16" :offset="2">
          <volume-devices :numVal="volumeMicCompute" id="myCanvas"></volume-devices>
        </el-col>
      </el-row>
    </div>
    <div class="devicesBox" style="padding-top: 20px;">
      <el-row>
        <el-col :span="4"><div class="txtFont">{{$t('loudspeaker')}}:</div></el-col>
        <el-col :span="20">
          <el-select id="defaultSpkID"
            :placeholder="$t('loudspeakerPlaceholder')"
            v-model="defaultSpk"
            size="mini"
            @change="changeSpk"
            :disabled="isSupportSpk"
            :popper-append-to-body="false"
            class="txtFont"
          >
            <el-option
              v-for="(item,index) in spkList"
              :key="index"
              :label="item.label"
              :value="item.deviceId"
            />
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4"><div class="txtFont" style="margin-top: 3px;">{{this.$t('setVolume')}}：</div></el-col>
        <el-col :span="20">
          <el-slider v-model="volumeSpk" size="mini" @change="changeVolumeSpk" :disabled="isSupportSpk" :show-tooltip="false"></el-slider>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="20">
          <div class="txtFont txtWidth">{{this.$t('setLoudSpeakerVolume')}}</div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="5">
          <!-- <el-button type="primary" plain size="mini" @click="closeSpk" :disabled="isSupportSpk" v-if="spkCheck">{{spkBtn}}</el-button> -->
          <el-button type="primary" plain size="mini" @click="testSpk" :disabled="isSupportSpk" >{{spkBtn}}</el-button>
        </el-col>
        <el-col :span="16" :offset="2">
          <volume-devices :numVal="volumeSpkCompute"></volume-devices>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import volumeDevices from '../../components/volumeDevices'
import { agoraCameraRtcEngine } from '@/views/index/agoraRtcEngine'
import agora from "@/config/agora"
import AgoraRTC from "agora-rtc-sdk-ng";
export default {
  components: {
    volumeDevices,
  },
  props: {
    hstRtcEngine: {
      type: Object,
      default: () => null,
    },
    devicesList: {
      type: [Object, Array],
      default: () => null,
    },
    user: {
      type: Object,
      default: () => null,
    },
    mainVideoUserId: {
      type: String,
      default: ''
    }
  },
  name: "microPhone",
  data() {
    return {
      audioBtn: this.$t('noSupport'),
      spkBtn: this.$t('noSupport'),
      btnColor: "#808080",
      userMedia: false,
      aContext: null,
      bContext: null,
      audioStream: null,
      spkStream: null,
      audioSource: null,
      audioSourceB: null,
      analyser: null,
      analyserB: null,
      audioList: [],
      defaultAudio: '',
      audioVolume: 90,
      isAudioVolume: true,
      audioCheck: false,
      volumeMicCompute: 0,
      volumeSpk: 90,
      defaultSpk: '',
      spkList: [],
      volumeSpkCompute: 0,
      audioDom: null,
      spkCheck: false,
      camList: [],
      defaultCam: '',
      defaultCamCopy: '',
      changeCam: '',
      isSupportCam: false,
      isSupportAudio: false,
      isSupportSpk: false,
      camListArr: [],
      micListArr: [],
      rtcEngine: process.env.VUE_APP_RTC_ENGINE,
      localVideoTrack: null,
    }
  },
  watch: {
    audioBtn(newValue, oldValue) {
      if (newValue === this.$t('noSupport')) {
        this.btnColor = "#808080";
      } else if (newValue === this.$t('testMicrophone')) {
        this.btnColor = "green";
      } else if (newValue === this.$t('closeMicrophone')) {
        this.btnColor = "#000";
      } else if (newValue === this.$t('abnormalMicrophone')) {
        this.btnColor = "red";
      }
    }
  },
  methods: {
    initAudio() {
      if (!this.aContext) {
        const TestAudio = window.AudioContext || window.webkitAudioContext || window.mozAudioContext || window.msAudioContext;
        this.aContext = new TestAudio();
        this.analyser = this.aContext.createAnalyser();
        this.analyser.fftSize = 512;
      }
    },
    getDeviceList() {
      if (this.rtcEngine === 'HST') {
        const { camDevs, micDevs, spkDevs } = this.devicesList
        if (this.devicesList.camDevs === undefined || this.devicesList.camDevs.length <= 0) {
          this.$nextTick(() => {
            this.isSupportCam = true
            this.camBtn = this.$t('noSupport')
          })
        }
        if (this.devicesList.spkDevs === undefined || this.devicesList.spkDevs.length <= 0) {
          this.$nextTick(() => {
            this.isSupportSpk = true
            this.spkBtn = this.$t('noSupport')
          })
        }
        if (this.devicesList.micDevs === undefined || this.devicesList.micDevs.length <= 0) {
          this.$nextTick(() => {
            this.isSupportAudio = true
            this.audioBtn = this.$t('noSupport')
          })
        }
        // 摄像头
        this.camList = camDevs.map((item) => ({
          deviceId: item.devId,
          label: item.devName,
        }))
        console.log(this.defaultCam)

        if (this.camList.length) {
          if (this.defaultCam) {
            this.defaultCamCopy = this.defaultCam
          } else {
            this.defaultCamCopy = this.camList[0].deviceId
          }
        }
        // 扬声器
        this.spkList = spkDevs.map((item) => ({
          deviceId: item.devId,
          label: item.devName,
        }))
        if (this.spkList.length) {
          this.defaultSpk = this.spkList[0].deviceId
        }
        //  麦克风
        this.audioList = micDevs.map((item) => ({
          deviceId: item.devId,
          label: item.devName,
        }))
        if (this.audioList.length) {
          this.defaultAudio = this.audioList[0].deviceId
        }
      } else if (this.rtcEngine === 'AGORA') {
        const camDevs = this.getFormatDeviceData('videoinput')
        const micDevs = this.getFormatDeviceData('audioinput')
        const spkDevs = this.getFormatDeviceData('audiooutput')
        if (camDevs === undefined || camDevs.length === 0) {
          this.$nextTick(() => {
            this.isSupportCam = true
            this.camBtn = this.$t('noSupport')
          })
        }
        if (spkDevs === undefined || spkDevs.length === 0) {
          this.$nextTick(() => {
            this.isSupportSpk = true
            this.spkBtn = this.$t('noSupport')
          })
        }
        if (micDevs === undefined || micDevs.length === 0) {
          this.$nextTick(() => {
            this.isSupportAudio = true
            this.audioBtn = this.$t('noSupport')
          })
        }
        this.camList = camDevs.map((item) => ({
          deviceId: item.value,
          label: item.label,
        }))
        console.log(this.defaultCam)

        if (this.camList.length) {
          if (this.defaultCam) {
            this.defaultCamCopy = this.defaultCam
          } else {
            this.defaultCamCopy = this.camList[0].deviceId
          }
        }
        // 扬声器
        this.spkList = spkDevs.map((item) => ({
          deviceId: item.value,
          label: item.label,
        }))
        if (this.spkList.length) {
          this.defaultSpk = this.spkList[0].deviceId
        }
        //  麦克风
        this.audioList = micDevs.map((item) => ({
          deviceId: item.value,
          label: item.label,
        }))
        if (this.audioList.length) {
          this.defaultAudio = this.audioList[0].deviceId
        }
      }
    },
    getFormatDeviceData(type) {
      const data = this.devicesList.filter(item => item.kind === type)
      return data.map(item => ({
        value: item.deviceId,
        label: item.label,
      }))
    },
    // 改变麦克风设备
    changeAudio(res) {
      this.defaultAudio = res
      if (this.rtcEngine === 'HST') {
        this.hstRtcEngine.switchDevice(1, '0', this.defaultAudio)
      }
      this.$emit("changgeAudio", this.defaultAudio)
    },
    changeVolumeMic(res) {
      this.audioVolume = res
      console.log(this.audioVolume)
    },
    openAudio(audioRes) {
      if (!this.userMedia) return;
      if (!this.aContext) return;
      if (this.audioStream) { // 关闭之前
        const tracks = this.audioStream.getAudioTracks();
        for (var i = 0; i < tracks.length; i++) {
          tracks[i].stop();
        }
      }
      let sel = this;
      window.navigator.mediaDevices.getUserMedia({
        video: false,
        audio: {
          noiseSuppression: true,
          channelCount: 1,
          echoCancellation: true,
          deviceId: audioRes
        }
      }).then((stream) => {
        sel.audioStream = stream;
        sel.audioSource = sel.aContext.createMediaStreamSource(stream);
        sel.audioSource.connect(sel.analyser);
        sel.analyser.connect(sel.aContext.destination);
        var maxDecibels = 0;
        this.volumeTimeViode = setInterval(() => {
          const dataArr = new Uint8Array(this.analyser.frequencyBinCount);
          this.analyser.getByteFrequencyData(dataArr);
          const mcVolume = dataArr.reduce(function(a, b) {
            return a + b;
          }) / this.analyser.frequencyBinCount;
          maxDecibels = maxDecibels > mcVolume ? maxDecibels : mcVolume;
          this.volumeMicCompute = parseInt(mcVolume / maxDecibels * this.audioVolume);
        }, 200);
      }).catch();
    },
    // 关闭麦克风
    closeAudio() {
      this.audioCheck = !this.audioCheck
      let tracks;
      if (this.analyser) this.analyser.disconnect();
      if (this.audioSource) this.audioSource.disconnect();
      if (this.audioStream) {
        tracks = this.audioStream.getAudioTracks();
        for (var i = 0; i < tracks.length; i++) {
          tracks[i].stop();
        }
      }
      this.volumeMicCompute = 0
      this.audioStream = null;
      clearInterval(this.volumeTimeViode)
      this.audioBtn = this.$t('testMicrophone');
    },
    testAudio() {
      this.audioBtn = this.$t('openMicrophone');
      this.audioCheck = !this.audioCheck
      this.initAudio();
      this.openAudio(this.defaultAudio);
    },
    // 改变扬声器设备
    changeSpk(res) {
      this.defaultSpk = res
      this.testSpk()
      console.log(this.defaultSpk);
    },
    // 改变扬声器音量
    changeVolumeSpk(res) {
      this.volumeSpk = res
      this.testSpk()
    },
    async testSpk() {
      if (this.bContext) this.bContext.close();
      this.spkCheck = !this.spkCheck
      this.spkBtn = this.$t('testLoudSpeaker')
      this.audio = document.createElement('audio');
      this.audio.src = require("../../views/index/audio/callNotice.mp3")
      // this.audio.load()
      await this.audio.setSinkId(this.defaultSpk);
      this.audio.volume = this.volumeSpk / 100
      this.audio.play()
      // 音量显示
      const ttt = this.spkList.find((item) => {
        return item.deviceId === this.defaultSpk
      })
      // console.log(this.defaultSpk)
      console.log(ttt)
      const tB = window.AudioContext || window.webkitAudioContext || window.mozAudioContext || window.msAudioContext;
      this.bContext = new tB();
      this.analyserB = this.bContext.createAnalyser();
      this.analyserB.fftSize = 512;
      this.audioSourceB = this.bContext.createMediaElementSource(this.audio);
      this.audioSourceB.connect(this.analyserB);
      this.analyserB.connect(this.bContext.destination);
      var maxDecibels = 0;
      this.volumeTimeSpk = setInterval(() => {
        const dataArrB = new Uint8Array(this.analyserB.frequencyBinCount);
        this.analyserB.getByteFrequencyData(dataArrB);
        var mcVolume = dataArrB.reduce(function(a, b) {
          return a + b;
        }) / this.analyserB.frequencyBinCount;
        maxDecibels = maxDecibels > mcVolume ? maxDecibels : mcVolume;
        this.volumeSpkCompute = parseInt(mcVolume / maxDecibels * this.volumeSpk);
      }, 200);
    },
    closeSpk() {
      this.spkCheck = !this.spkCheck
      if (this.audio) this.audio.pause();
      this.spkBtn = this.$t('testLoudSpeaker')
      if (this.analyserB) this.analyserB.disconnect();
      if (this.audioSourceB) this.audioSourceB.disconnect();
      this.volumeSpkCompute = 0
      clearInterval(this.volumeTimeSpk)
    },
    destroy() {
      this.closeAudio();
      this.closeCamera()
      this.closeSpk()
      if (this.aContext) this.aContext.close();
      if (this.bContext) this.bContext.close();
      if (this.volumeTimeSpk) {
        clearInterval(this.volumeTimeSpk)
      }
      if (this.volumeTimeViode) {
        clearInterval(this.volumeTimeViode)
      }
    },
    // 关闭摄像头
    closeCamera () {
      // const MediaType = this.hstRtcEngine.MediaType;
      // // console.log(MediaType)
      // this.hstRtcEngine.unsetMediaRender(
      //   this.user.accountId,
      //   MediaType.AUDIO,
      //   document.getElementById('camvideo')
      // );
      // this.hstRtcEngine.destroy();
    },
    async callCamera(res) {
      console.log('callCamera', res)
      if (this.rtcEngine === 'HST') {
        const MediaType = this.hstRtcEngine.MediaType;
        const options = {
          width: 400,
          height: 234,
          frameRate: 25,
          bitRate: 0
        }
        if (MediaType) {
          this.hstRtcEngine.setMediaRender(
            this.user.accountId,
            MediaType.VIDEO,
            res,
            document.getElementById('camvideo'),
            options
          );
        }
      } else if (this.rtcEngine === 'AGORA') {
        if (!this.localVideoTrack) {
          this.localVideoTrack = await AgoraRTC.createCameraVideoTrack({
            cameraId: res,
            encoderConfig: agora.encoderConfig,
            optimizationMode: agora.optimizationMode
          })
          this.localVideoTrack.play(document.getElementById('camvideo'), {mirror: agora.mirror})
        } else {
          this.localVideoTrack.setDevice(res)
        }
      }
    },
    // 切换摄像头
    changeCamere(res) {
      this.defaultCamCopy = res
      this.defaultCam = res
      this.callCamera(res)
      if (this.rtcEngine === 'HST') {
        this.hstRtcEngine.switchDevice(2, '0', this.defaultCamCopy)
      }
      this.$emit("changgeCamere", this.defaultCamCopy)
    },
  },
  mounted() {
    this.getDeviceList()
    this.callCamera(this.defaultCamCopy)
    if (window.navigator.mediaDevices && window.navigator.mediaDevices.getUserMedia) {
      this.userMedia = true;
    } else if (window.navigator.getUserMedia || window.navigator.webkitGetUserMedia ||
      window.navigator.mozGetUserMedia || window.navigator.msGetUserMedia || window.navigator.oGetUserMedia) {
      this.userMedia = true;
    }
    if (this.userMedia) {
      this.audioBtn = this.$t('testMicroPhone')
      this.spkBtn = this.$t("testLoudSpeaker")
    } else {
      this.audioBtn = this.$t('noSupport');
      this.spkBtn = this.$t('noSupport')
      this.isSupportAudio = true
      this.isSupportSpk = true
    }
  },
  beforeDestroy() {
    this.destroy();
  },
}
</script>

<style lang="scss" scoped>
.devicesBox{
  margin-right: 25px;
  font-size: 14px;
  .micBtn{
    width: 100px;
  }
  .txtFont{
    font-size: 14px;
  }
  .txtWidth{
    width: 410px;
  }
  .el-select--mini{
    width: 330px;
  }
  .el-button--primary{
    font-size: 14px;
  }
  .el-slider{
    width: 330px;
    height: 22px;
  }
  .el-button--primary.is-plain {
    color: #0B81E0;
    background: #e6f0f7;
    border-color: #9bc2e0;
}
  .mirrorBtn {
    color: #FFFFFF;
    background-color: #CCC;
    border-color: #CCC;
    &:hover{
      color: #FFFFFF;
      background-color: #CCC;
      border-color: #CCC;
    }
    .elmirror{
      width: 14px;
      height: 15px;
      margin-right: 5px;
      vertical-align:middle;
    }
    span{
      vertical-align:middle;
      font-size: 14px;
    }
  }
}
.camvideo{
  margin-left: -5px;
}

</style>
