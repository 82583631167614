/**
 * 公用枚举
 * */

// 工单状态
export const workOrderStatus = {
  '-1': '等待服务中',
  0: '执行中',
  1: '已完结'
};
