/**
 * 图形绘制
 * */
function Draw (ctx, clientWidth, clientHeight) {
  this.ctx = ctx;
  this.clientWidth = clientWidth;
  this.clientHeight = clientHeight;
}

// 橡皮擦
Draw.prototype.eraser = function (x1, y1, x2, y2, lineWidth) {
  this.ctx.clearRect(x1 - lineWidth / 2, y1 - lineWidth / 2, lineWidth, lineWidth);
};

// 铅笔
Draw.prototype.pencil = function (x1, y1, x2, y2) {
  this.ctx.beginPath();
  this.ctx.lineCap = 'round';
  this.ctx.lineJoin = 'round';
  this.ctx.moveTo(x1, y1);
  this.ctx.lineTo(x2, y2);
  this.ctx.closePath();
  this.ctx.stroke();
};

// 直线
Draw.prototype.line = function (x1, y1, x2, y2) {
  this.ctx.beginPath();
  this.ctx.moveTo(x1, y1);
  this.ctx.lineTo(x2, y2);
  this.ctx.stroke();
};

// 矩形
Draw.prototype.rect = function (x1, y1, x2, y2) {
  this.ctx.beginPath();
  this.ctx.rect(x1, y1, x2 - x1, y2 - y1);
  this.ctx.stroke();
};

// 圆形
Draw.prototype.circle = function (x1, y1, x2, y2) {
  this.ctx.beginPath();
  const r = Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));
  this.ctx.arc(x1, y1, r, 0, 2 * Math.PI);
  this.ctx.stroke();
};

// 正多边形基础绘制
Draw.prototype.polygon = function (x1, y1, x2, y2, n) {
  this.ctx.beginPath();
  this.ctx.save();
  const r = Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));
  this.ctx.translate(x1, y1);
  this.ctx.rotate(Math.PI / 2);
  const nx = r * Math.cos(Math.PI / n);
  const ny = r * Math.sin(Math.PI / n);
  this.ctx.moveTo(nx, ny);
  for (let i = 0; i <= n; i++) {
    this.ctx.rotate(Math.PI * 2 / n);
    this.ctx.lineTo(nx, -ny);
  }
  this.ctx.stroke();
  this.ctx.restore();
};

// 三角形
Draw.prototype.triangle = function (x1, y1, x2, y2) {
  this.polygon(x1, y1, x2, y2, 3);
};

// 正方形
Draw.prototype.square = function (x1, y1, x2, y2) {
  this.polygon(x1, y1, x2, y2, 4);
};

export default Draw;
