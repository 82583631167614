<template>
  <div class="previewImage" @mousemove.stop="mousemove" @mouseup.stop="mouseup">
    <img
      v-if="showSrc"
      id="currentImg"
      class="currentImg"
      :src="showSrc"
      :style="{
        transform: `rotate(${rotate}deg) scale(${scale})`,
        left: left + 'px',
        top: top + 'px',
      }"
      @mousedown.stop.prevent="mousedown"
    />
    <div class="optionButton">
      <div @click="rotateLeft">
        <i class="el-icon-refresh-left" />
      </div>
      <div @click="rotateRight">
        <i class="el-icon-refresh-right" />
      </div>
      <div @click="scaleReduce">
        <i class="el-icon-minus" />
      </div>
      <div class="showValue" @click="resetScale">
        {{ Math.floor(scale * 100) }}%
      </div>
      <div @click="scaleAdd">
        <i class="el-icon-plus" />
      </div>
      <div @click="downLoad">
        <i class="el-icon-download" />
      </div>
      <div @click="close">
        <i class="el-icon-close" />
      </div>
    </div>
  </div>
</template>

<script>
import Index from './_index.js'
export default Index
</script>

<style lang="scss">
@import './_index.scss';
</style>
