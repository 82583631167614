export default {
  name: 'BaseDialog',
  props: {
    title: { // 显示标题
      type: String,
      default: ''
    },
    center: { // 标题和按钮是否居中
      type: Boolean,
      default: false
    },
    width: { // 宽度
      type: String,
      default: '476px'
    },
    height: { // 高度
      type: String,
      default: '500px'
    },
    value: { // 是否显示弹框
      type: Boolean,
      default: true
    },
    showFooter: { // 是否显示底部按钮
      type: Boolean,
      default: true
    },
    showClose: { // 是否显示确定按钮
      type: Boolean,
      default: true
    },
    showConfirm: { // 是否显示确定按钮
      type: Boolean,
      default: true
    },
    closeClickModal: { // 是否可以通过点击 modal 关闭 Dialog
      type: Boolean,
      default: true
    },
    closePressEscape: { // 是否可以通过按下 ESC 关闭 Dialog
      type: Boolean,
      default: true
    }
  },
  mounted() {
    const docHeight = document.body.clientHeight
    const docWidth = document.body.clientWidth
    const standarHeight = docHeight / 1080
    const ddw = document.getElementsByClassName('baseDialog')[0]
    this.$nextTick(() => {
      if (docWidth < 1680) {
        if (ddw) {
          ddw.childNodes[0].style.zoom = standarHeight;
        }
      }
    })
  },
  methods: {
    /**
     * 打开弹出框
     * */
    confirm () {
      this.$emit('confirm');
    },
    /**
     * 打开弹出框
     * */
    close () {
      this.$emit('close');
    },
    onresize() {
      console.log(this.docWidth)
    }
  }
};
