const docHeight = document.body.clientHeight // BODY对象高度
const docWidth = document.body.clientWidth // BODY对象宽度
let standarHeight = 1
if (docWidth < 1680) {
  // const standarWidth = docWidth / 1920
  standarHeight = docHeight / 1080
}

const watermarkBig = {};
const id = 'set-customer-watermarkBig-id'
const setWatermark = (dom, str1) => {
  if (document.getElementById(id) !== null) {
    dom ? dom.removeChild(document.getElementById(id)) : document.body.removeChild(document.getElementById(id));
  }

  // 创建一个画布
  const can = document.createElement("canvas");
  can.width = 380;
  can.height = 130;

  // 设置画布样式
  const cans = can.getContext("2d");
  cans.rotate((-20 * Math.PI) / 180); // 画布里面文字的旋转角度
  cans.font = "14px Vedana";
  cans.fillStyle = 'rgba(242,152,152, 0.4)';
  cans.textAlign = "left";
  cans.textBaseline = "Middle";
  cans.fillText(str1, 0, can.height);
  // cans.fillText(str2, 0, can.height + 15);
  // cans.fillText(str1, can.width / 110, can.height / 2) // 画布里面文字的间距比例
  // cans.fillText(str2, can.width / 110, can.height / 2 + 20)

  // 创建整体容器，设置样式
  const div = document.createElement("div");
  div.id = id;
  div.style.pointerEvents = "none";
  div.style.top = "0px";
  div.style.left = "0px";
  // div.style.bottom = '0px'
  div.style.position = "absolute";
  div.style.zIndex = "0";
  div.style.width = document.documentElement.clientWidth / standarHeight + "px";
  div.style.height = document.documentElement.clientHeight / standarHeight + "px";
  div.style.background =
    "url(" + can.toDataURL("image/png") + ") left top repeat";
  dom ? dom.appendChild(div) : document.body.appendChild(div);
  return id;
};

// 设置水印
watermarkBig.set = (dom, str1) => {
  let id = setWatermark(dom, str1);
  setInterval(() => {
    if (document.getElementById(id) === null) {
      id = setWatermark(dom, str1);
    }
  }, 500);
  window.onresize = () => {
    setWatermark(dom, str1);
  };
};

// 清除水印
watermarkBig.remove = () => {
  if (document.getElementById(id) !== null) {
    document.getElementById(id).style.display = "none";
  }
};

export default watermarkBig
