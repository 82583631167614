<template>
  <div class="indexPage">
    <!--图片查看器-->
    <preview-image
      v-if="showPreviewImage"
      :src="previewImageUrl"
      @close="closePreviewImage"
    />
    <!--播放屏幕共享 层级 1300 -->
    <div class="shareVideoWrap" v-if="showShareVideo">
      <video class="video-container" v-if="rtcEngine === 'HST'" autoplay playsinline id="shareVideo" muted />
      <div v-if="rtcEngine === 'AGORA'" id="shareVideo" style="width: 100%;height: 100%"/>
    </div>
    <!-- 接收系统音频流 -->
    <video autoplay playsinline id="systemAudio" style="display: none" />
    <!--白板组件 层级 1200 -->
    <white-board
      v-if="showWhiteBoard"
      :isShowSpace="isShowSpace"
      :initShowImgUrl="initShowImgUrl"
      :isTransparent="isTransparent"
      @exitWhiteBoard="exitWhiteBoard"
    />
    <!--视频邀请提示音-->
    <audio
      class="callNotice"
      id="callNotice"
      src="./audio/callNotice.mp3"
      loop
    />
    <!--左边导航-->
    <left-menu
      ref="leftMenu"
      @invite="invite"
      :onlineUserList="onlineUserList"
      :ownOnlineUserList="ownOnlineUserList"
    />
    <!--顶部导航-->
    <top-header
      ref="topHeader"
      :hstRtcEngine="hstRtcEngine"
      @confirmSet="confirmSet"
      @logout="logout"
      @invite="invite"
      @updateContacts="updateContacts"
    />
    <!--内容区域-->
    <div @click='addLinkRoom' v-trigger></div>
    <div class="mainContent" :class="{ active: isFold }">
      <div class="mainLeft">
        <div class="LeftTop">
          <!-- 远程支持时长(分钟) -->
          <div class="LeftTopBox">
            <div class="itemBox">
              <div class="itemBoxIcon">
                <img src="./img/renwushichang.png" class="iconBox"/>
              </div>
              <div class="itemBoxTitle">
                <p class="itemNum">{{isTimesYear == 1 ? $utils.changMinute(joinMeetingTimes) : totaljoinMeetingTimes }}</p>
                <p class="itemContent">{{$t('accountRemoteSupportTimes')}}</p>
              </div>
              <div class="itemBoxTxt" >
                <span @click="pemoteSupportMonth" :class="{ active: isTimesYear == 1 }">{{$t('currentMonth')}}</span>
                <span @click="pemoteSupportYear" :class="{ active: isTimesYear == 2 }">{{$t('currentYear')}}</span>
              </div>
            </div>
          </div>
          <!-- 在线时长(分钟) -->
          <div class="LeftTopBox">
            <div class="itemBox">
              <div class="itemBoxIcon">
                <img src="./img/renwuadd.png" class="iconBox"/>
              </div>
              <div class="itemBoxTitle">
                <p class="itemNum">{{isLineTimesYear == 1 ? $utils.changMinute(joinMeetingLineTimes) : $utils.changMinute(totalJoinMeetingLineTimes) }}</p>
                <p class="itemContent">{{$t('accountOnLineTimes')}}</p>
              </div>
              <div class="itemBoxTxt" >
                <span @click="joinMeetingLineMonth" :class="{ active: isLineTimesYear == 1 }">{{$t('currentMonth')}}</span>
                <span @click="joinMeetingLineYear" :class="{ active: isLineTimesYear == 2 }">{{$t('currentYear')}}</span>
              </div>
            </div>
          </div>
          <!-- 接受到报修工单(个数) -->
          <!-- <div class="LeftTopBox">
            <div class="itemBox">
              <div class="itemBoxIcon">
                <img src="./img/renwuadd.png" class="iconBox"/>
              </div>
              <div class="itemBoxTitle">
                <p class="itemNum">{{accountRepairOrderNums}}</p>
                <p>{{$t('accountRepairOrderNum')}}</p>
              </div>
              <div class="itemBoxTxt">{{$t('currentMonth')}}</div>
            </div>
          </div> -->
          <!-- 远程支持次数(次) -->
          <div class="LeftTopBox">
            <div class="itemBox">
              <div class="itemBoxIcon">
                <img src="./img/zainxianshichang.png" class="iconBox"/>
              </div>
              <div class="itemBoxTitle">
                <p class="itemNum">{{isJoinMeetingNum == 1 ? accountRemoteSupportNums : totaljoinMeetingNum}}</p>
                <p class="itemContent">{{$t('accountRemoteSupportNum')}}</p>
              </div>
              <div class="itemBoxTxt" >
                <span @click="joinMeetingNumMonth" :class="{ active: isJoinMeetingNum == 1 }">{{$t('currentMonth')}}</span>
                <span @click="joinMeetingNumYear" :class="{ active: isJoinMeetingNum == 2 }">{{$t('currentYear')}}</span>
              </div>
            </div>
          </div>
          <!-- 已共享文件(个数) -->
          <div class="LeftTopBox">
            <div class="itemBox">
              <div class="itemBoxIcon">
                <img src="./img/addwenjian.png" class="iconBox"/>
              </div>
              <div class="itemBoxTitle">
                <p class="itemNum">{{isfilePushYear == 1 ? accountSharedFiles : totalfilePushNum}}</p>
                <p class="itemContent">{{$t('accountSharedFiles')}}</p>
              </div>
              <div class="itemBoxTxt" >
                <span @click="filePushMonth" :class="{ active: isfilePushYear == 1 }">{{$t('currentMonth')}}</span>
                <span @click="filePushYear" :class="{ active: isfilePushYear == 2 }">{{$t('currentYear')}}</span>
              </div>
            </div>
          </div>
          <!-- 已处理报修工单(个数) -->
          <!-- <div class="LeftTopBox">
            <div class="itemBox">
              <div class="itemBoxIcon">
                <img src="./img/renwuadd.png" class="iconBox"/>
              </div>
              <div class="itemBoxTitle">
                <p class="itemNum">{{accountProcessRepairOrderNums}}</p>
                <p>{{$t('accountProcessRepairOrderNum')}}</p>
              </div>
              <div class="itemBoxTxt">{{$t('currentMonth')}}</div>
            </div>
          </div> -->
        </div>
        <div class="LeftBottom">
          <div class="LeftBottomBox" id="LeftBottomBox1">
          </div>
          <div class="LeftBottomBox" id="LeftBottomBox2"></div>
          <!-- <div class="LeftBottomBox" id="LeftBottomBox3"></div> -->
        </div>
      </div>
      <div class="mainRight" v-show="centerDialogVisible">
        <div class="mainRightBox">
          <div class="userRightBox1">
            <div class="lineUserEnd"  v-for="item in userqyList" :key="item.uid" @click="getinvite(item)">
              <div class="lineIconBox1">
                <div class="incoTitele">{{$utils.getLength(item.name)}}</div>
                <div v-if="item.lastLoginDeviceType === 'ANDROID' " class="iconUserPicSJ"></div>
                <div v-if="item.lastLoginDeviceType === 'ANDROID_AR' " class="iconUserPicAR"></div>
                <div v-if="item.lastLoginDeviceType === 'PC' " class="iconUserPicDN"></div>
                <div v-if="item.lastLoginDeviceType === 'WEB' " class="iconUserPicWeb"></div>
              </div>
              <div class="lineUsertitle">{{item.name}}</div>
            </div>
             <div class="lineUserEnd" v-for="item in userwbList" :key="item.uid" @click="getinvite(item)">
              <div class="lineIconBox2">
                <div class="incoTitele">{{$utils.getLength(item.name)}}</div>
                <div v-if="item.lastLoginDeviceType === 'ANDROID' " class="iconUserPicSJ"></div>
                <div v-if="item.lastLoginDeviceType === 'ANDROID_AR' " class="iconUserPicAR"></div>
                <div v-if="item.lastLoginDeviceType === 'PC' " class="iconUserPicDN"></div>
                <div v-if="item.lastLoginDeviceType === 'WEB' " class="iconUserPicWeb"></div>
              </div>
              <div class="lineUsertitle" :title="item.name">{{item.name}}</div>
            </div>
            <div class="lineUserEnd" v-for="item in userptList" :key="item.uid" @click="getinvite(item)">
              <div class="lineIconBox3">
                <div class="incoTitele">{{$utils.getLength(item.name)}}</div>
                <div v-if="item.lastLoginDeviceType === 'ANDROID' " class="iconUserPicSJ"></div>
                <div v-if="item.lastLoginDeviceType === 'ANDROID_AR' " class="iconUserPicAR"></div>
                <div v-if="item.lastLoginDeviceType === 'PC' " class="iconUserPicDN"></div>
                <div v-if="item.lastLoginDeviceType === 'WEB' " class="iconUserPicWeb"></div>
              </div>
              <div class="lineUsertitle" :title="item.name">{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>
      <template v-if="groupId && groupUserList.length">
        <!-- <template> -->
        <!--中间视频区域-->
        <div class="videoWrap">
          <!--主视频框 层级 1000 -->
          <div class="mainVideoBox">
            <div class="videoBoxtxt">{{ $t('openNoCamera') }}</div>
            <div :class="{ isFullScreen: isFullScreen }" class="directrecordwp" id="directrecordwp" >
              <video
                v-if="rtcEngine === 'HST'"
                autoplay
                playsinline
                id="mainVideo"
                class="main-video-container"
                max="false"
                @dblclick="dbClickMainVideo"
                @click="getMousePoint"
              />
              <div
                :id="'mainVideo'"
                v-if="rtcEngine === 'AGORA'"
                class="main-video-container"
                @dblclick="dbClickMainVideo"
                @click="getMousePoint"
              ></div>

              <!-- 音量调 {{ item.showName }}-->
              <template v-for="item in groupUserList">
                <div
                  class="voiceList"
                  style="left: 10px; bottom: 10px"
                  :key="item.userId"
                  v-if="item.userId === mainVideoUserId"
                >
                  <b>{{ item.showName }}</b>
                  <template v-if="item.userId===userId">
                    <div>
                    <img
                      v-if="item.activePubAudio"
                      src="./img/microphone-active.png"
                    />
                    <img v-else src="./img/microphone-disable.png" />
                    <template v-if="item.activePubAudio">
                      <span :class="{ active: currentAudioVolume > 0 }" />
                      <span :class="{ active: currentAudioVolume >= 10 }" />
                      <span :class="{ active: currentAudioVolume >= 20 }" />
                      <span :class="{ active: currentAudioVolume >= 30 }" />
                      <span :class="{ active: currentAudioVolume >= 40 }" />
                      <span :class="{ active: currentAudioVolume >= 50 }" />
                      <span :class="{ active: currentAudioVolume >= 60 }" />
                      <span :class="{ active: currentAudioVolume >= 70 }" />
                      <span :class="{ active: currentAudioVolume>= 80 }" />
                      <span :class="{ active: currentAudioVolume >= 90 }" />
                    </template>
                    <template v-else>
                      <span
                        v-for="list in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                        :key="list"
                      ></span>
                    </template>
                  </div>
                  </template>
                  <template v-else>
                    <div>
                      <template v-if="item.pubAudio">
                        <img v-if="item.myAudio" src="./img/microphone-active.png" />
                        <img v-else src="./img/microphone_grey.png"/>
                      </template>
                      <template v-else>
                        <img src="./img/microphone-disable.png"  />
                      </template>
                      <template v-if="item.pubAudio">
                        <span :class="{ active: item.volume > 0 }" />
                        <span :class="{ active: item.volume >= 10 }" />
                        <span :class="{ active: item.volume >= 20 }" />
                        <span :class="{ active: item.volume >= 30 }" />
                        <span :class="{ active: item.volume >= 40 }" />
                        <span :class="{ active: item.volume >= 50 }" />
                        <span :class="{ active: item.volume >= 60 }" />
                        <span :class="{ active: item.volume >= 70 }" />
                        <span :class="{ active: item.volume >= 80 }" />
                        <span :class="{ active: item.volume >= 90 }" />
                      </template>
                      <template v-else>
                        <span
                          v-for="list in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                          :key="list"
                        ></span>
                      </template>
                    </div>
                  </template>
                </div>
              </template>
              <div v-show="isFullScreenShow">
                 <remote-operate
                      ref="remoteOperate"
                      v-if="mainVideoIsAR && speakerUserId === userId"
                      :userWss="userWss"
                      :groupId="groupId"
                      :mainVideoUserId="mainVideoUserId"
                  >
                    <template slot="arBottomBtn" v-if="mainVideoIsAR && isFullScreen">
                    <div>
                      <div
                        class="arBnt"
                        @click="speakerOpenTransparentWhiteBoard"
                      >
                        <img src="./../../assets/noData.png" alt="" />
                      </div>
                      <p>{{ $t('transparentBoard') }}</p>
                    </div>
                    <div>
                      <div
                        class="arBnt"
                        @click="changeToStaticMark"
                      >
                        <img src="./img/staticTag.png" alt="" />
                      </div>
                      <p>{{ $t('staticMark') }}</p>
                    </div>
                    <div>
                      <div
                        class="arBnt"
                        @click="pointExit"
                      >
                        <img src="./../../assets/exit.png" alt="" />
                      </div>
                      <p>{{ $t('exitFull') }}</p>
                    </div>
                  </template>
                 </remote-operate>
              </div>
              <div class="boardOptions" id="pointboardList">
              <div class="lists" v-if="!mainVideoIsAR">
                <div class="listBox" v-if="mainVideoIsAR">
                  <div @click="photograph" class="imgBox">
                    <img src="./img/ARCamera_open.png" alt="" />
                  </div>
                  <span>{{$t('photograph')}}</span>
                </div>
                <div class="listBox">
                  <div @click="speakerOpenTransparentWhiteBoard" class="imgBox">
                    <img src="./../../assets/noData.png" alt="" />
                  </div>
                  <span>{{$t('transparentBoard')}}</span>
                </div>
                <div class="listBox">
                  <div @click="changeToStaticMark" class="imgBox">
                    <img src="./img/staticTag.png" alt="" />
                  </div>
                  <span>{{$t('staticMark')}}</span>
                </div>
                <div class="listBox">
                  <div @click="pointExit" class="imgBox">
                    <img src="./../../assets/outmeeting.png" alt="" />
                  </div>
                  <span>{{$t('exitFull')}}</span>
                </div>
                <!-- <el-tooltip :content="$t('transparentBoard')" placement="top">
                  <p class="list" @click="speakerOpenTransparentWhiteBoard"  style="background: rgba(255, 255, 255, 0.5); margin-bottom: 10px;color:#fff">
                    <img src="./../../assets/noData.png" alt="" />
                    <span>{{$t('transparentBoard')}}</span>
                  </p>
                </el-tooltip>
                <el-tooltip :content="$t('staticMark')" placement="top">
                  <p class="list" @click="changeToStaticMark" style="background: rgba(255, 255, 255, 0.5); margin-bottom: 10px;color:#fff">
                    <img src="./img/staticTag.png" alt="" />
                    <span>{{$t('staticMark')}}</span>
                  </p>
                </el-tooltip>
                <el-tooltip
                  v-if="mainVideoIsAR"
                  :content="$t('photograph')"
                  placement="top"
                >
                  <p
                    class="list" @click="photograph" style=" background: rgba(255, 255, 255, 0.5); margin-bottom: 10px;color:#fff"
                  >
                    <img src="./img/ARCamera_open.png" alt="" />
                    <span>{{$t('photograph')}}</span>
                  </p>
                </el-tooltip>
                <el-tooltip :content="$t('exit')" placement="top">
                  <p
                    class="list"
                    @click="pointExit"
                     style=" background: rgba(255, 255, 255, 0.5); margin-bottom: 10px;color:#fff"
                  >
                    <img src="./../../assets/outmeeting.png" alt="" />
                    <span>{{$t('exit')}}</span>
                  </p>
                </el-tooltip> -->
              </div>
            </div>
              <div v-if="rtcEngine === 'AGORA' && mainVideoStats" class="main-video-stats">{{mainVideoStats}}</div>
            </div>
            <!--AR远程操作-->
            <!-- <remote-operate
              ref="remoteOperate"
              v-if="mainVideoIsAR && speakerUserId === userId"
              :userWss="userWss"
              :groupId="groupId"
              :mainVideoUserId="mainVideoUserId"
            /> -->
            <!--选择颜色 层级 1100 -->
            <div class="colorList" id="pointColorList">
              <div class="leftbox"
                v-for="(item, index) in defaultColors"
                :key="index"
                :style="{ backgroundColor: item }"
                :class="{ active: currentPointColor === item }"
                @click="setCurrentPointColor(item)"
              />
            </div>
          </div>
          <!--底部视频列表-->
          <div class="footVideoLists">
            <template v-for="item in groupUserList">
              <div
                class="videoList" :id="'t' + item.userId"
                :key="item.userId"
                v-if="mainVideoUserId !== item.userId"
                @dblclick="changeMainVideo(item)"
              >
                <div class="videoBoxtxt">{{ $t('openNoCamera') }}</div>
                <div style="width: 100%;height: 100%;">
                  <video class="video-item" v-if="rtcEngine === 'HST'" autoplay playsinline :id="item.userId"/>
                  <div v-if="rtcEngine === 'AGORA'" :id="'video-' + item.userId" style="width:100%;height:100%;"/>
                  <div v-if="rtcEngine === 'AGORA' && item.videoStats" class="video-stats">{{`${item.videoStats.width}×${item.videoStats.height} ${Math.ceil(item.videoStats.bitrate / 1000)}Kbps ${item.videoStats.frameRate}fps ${item.videoStats.delay}ms`}}</div>
                </div>
                <div class="voiceList" style="left: 10px; bottom: 10px">
                  <b>{{ item.showName }}</b>
                  <template v-if="item.userId === userId ">
                    <div>
                      <template v-if="item.pubAudio">
                        <img v-if="item.myAudio" src="./img/microphone-active.png" />
                        <img v-else src="./img/microphone_grey.png"/>
                      </template>
                      <template v-else>
                        <img src="./img/microphone-disable.png"  />
                      </template>
                      <template v-if="item.activePubAudio">
                        <span :class="{ active: currentAudioVolume > 0 }" />
                        <span :class="{ active: currentAudioVolume >= 10 }" />
                        <span :class="{ active: currentAudioVolume >= 20 }" />
                        <span :class="{ active: currentAudioVolume >= 30 }" />
                        <span :class="{ active: currentAudioVolume >= 40 }" />
                        <span :class="{ active: currentAudioVolume >= 50 }" />
                        <span :class="{ active: currentAudioVolume >= 60 }" />
                        <span :class="{ active: currentAudioVolume >= 70 }" />
                        <span :class="{ active: currentAudioVolume >= 80 }" />
                        <span
                          :class="{
                            active: item.volume >= 90,
                          }"
                        />
                      </template>
                      <template v-else>
                        <span
                          v-for="list in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                          :key="list"
                        ></span>
                      </template>
                    </div>
                  </template>
                  <template v-else>
                    <div>
                      <template v-if="item.pubAudio">
                        <img v-if="item.myAudio" src="./img/microphone-active.png" />
                        <img v-else src="./img/microphone_grey.png"/>
                      </template>
                      <template v-else>
                        <img src="./img/microphone-disable.png"  />
                      </template>
                      <template v-if="item.pubAudio">
                        <span :class="{ active: item.volume > 0 }" />
                        <span :class="{ active: item.volume >= 10 }" />
                        <span :class="{ active: item.volume >= 20 }" />
                        <span :class="{ active: item.volume >= 30 }" />
                        <span :class="{ active: item.volume >= 40 }" />
                        <span :class="{ active: item.volume >= 50 }" />
                        <span :class="{ active: item.volume >= 60 }" />
                        <span :class="{ active: item.volume >= 70 }" />
                        <span :class="{ active: item.volume >= 80 }" />
                        <span
                          :class="{
                            active: item.volume >= 90,
                          }"
                        />
                      </template>
                      <template v-else>
                        <span
                          v-for="list in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                          :key="list"
                        ></span>
                      </template>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </div>
           <!-- 预约 -->
        <el-dialog
          top="20vh"
          width="510px"
          height="380px"
          ref="appointmentRemoteMeeting"
          v-if="setDialogVisible"
          :showFooter="false"
          class="metingDialogCode"
          :title="$t('appointmentRemoteMeeting')"
          :visible.sync="setDialogVisible"
          @close="closeSetDialog"
          @confirm="confirmSetDialog"
          >
          <el-row>
          <el-col :span="24"><div class="titleContTitle">{{userInfoName.name}}<span> {{$t('sendRemoteGuide')}}</span></div></el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="titleTxt">
              {{$t('guideCoding')}} :
            </div>
            </el-col>
          <el-col :span="20"><div class="titleContColor">{{$utils.replaceThree(meetingID)}}</div></el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
             <div class="titleTxt">
              {{$t('guideCodingUrl')}} :
            </div>
          </el-col>
          <el-col :span="20"><div class="titleContColor">{{invitationLink}}</div></el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="watchCode">
              <div class="qrcode" ref="qrCodeUrl" xss=removed></div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="footerBtnGuid">
              <el-button plain size="mini" @click="loadPic">{{$t('mettingLoad')}}</el-button>
              <el-button type="primary" size="mini" :data-clipboard-text="guidePicData" class="copyBtn" @click="copyContent">{{$t('meetingCopy')}}</el-button>
            </div>
          </el-col>
        </el-row>
        <div class="imgPreviewBox" ref="imageWrapper">
          <div class="previewTopBox">
            <div class="previewTopBoxItem">{{userInfoName.name}}</div>
            <div class="previewTopBoxItem">{{$t('sendRemoteGuide')}}</div>
          </div>
          <div class="previewContentBox">
            <div class="previewContentTitle">{{$t('guideCoding')}}:</div>
            <div class="previewContentNum">{{$utils.replaceThree(meetingID)}}</div>
            <div class="previewContentTitle">{{$t('guideCodingUrl')}}:</div>
            <div class="previewContentUrl">{{invitationLink}}</div>
            <div class="previeWatchBox">
              <div class="qrcode" ref="inLoadHtml" xss=removed></div>
            </div>
            <div class="previewApp">
              <p class="oneTxt">{{ $t('keepRepair') }}</p>
              <p class="twoTxt">{{ $t('AppDownloadedPc') }}</p>
            </div>
          </div>
        </div>
        <!-- 内层 -->
        </el-dialog>

        <!--右边操作区域-->
        <div class="rightTools">
          <div class="rightToolsInner">
            <div class="rightTopWrap">
              <!--参会人标题-->
              <div class="joinMemberTitle">
                <div class="meetUser">
                  <img class="users" src="./img/meetUser.png" alt="" />{{
                    $t('attendee')
                  }}
                </div>
                <div class="unlockedIcon" :style="{width: speakerUserId === userId ? '150px' : '50px'}">
                  <template v-if="isLockText === 'NO' && speakerUserId === userId ">
                    <div @click="onUnlocked" class="el-icon-lock unlocked">{{$t('locked')}}</div>
                  </template>
                  <template v-else-if="isLockText === 'YES' && speakerUserId === userId">
                     <div @click="onUnlocked" class="el-icon-unlock unlocked">{{$t('unlocked')}}</div>
                  </template>
                  <div class="el-icon-user lockedinvite" @click="openInviteComfirm">{{$t('inviteComfirm')}}</div>
                </div>
              </div>
             <!--参会人列表-->
              <div class="userLists">
                <div class="userList"
                  v-for="item in groupUserList"
                  :key="item.userId"
                  :class="{ isSpeaker: speakerUserId === item.userId }"
                  @mouseover="mouseOverEvent(item.userId)"
                  @mouseleave="mouseLeaveEvent(item.userId)"
                >
                  <div class="userFace">
                    <img src="./../../assets/defaultHead.jpg" alt="" />
                  </div>
                  <div class="dots userName">
                    {{ item.showName }}
                    <template v-if="speakerUserId === item.userId ? true : false">({{ $t('speaker') }})</template>
                  </div>
                  <!-- <el-button type="primary" size="mini" @click.native="setNickname" v-show="isSetNickNameShow">{{ $t('editNickName') }}</el-button> -->
                  <template v-if="item.userId === userId">
                    <el-button type="primary" size="mini" @click.native="setNickname" v-show="isSetNickNameShow">{{ $t('editNickName') }}</el-button>
                    <div class="userRightIcon" v-show="!isSetNickNameShow">
                      <div href="javascript:;" class="changeMicrophoneVideo"  @click="changeIsPubVideoOther(item)">
                        <img v-if="item.activePubVideo" src="./img/camera-active.png" :alt="$t('closeCamera')" />
                        <img v-else src="./img/camera-disable.png" :alt="$t('openCamera')" />
                      </div>
                      <div href="javascript:;" class="changeMicrophoneVideo"  @click="changeIsPubAudioOther(item)">
                        <img v-if="item.activePubAudio" src="./img/microphone-active.png" :alt="$t('closeAudio')" />
                        <img v-else src="./img/microphone-disable.png" :alt="$t('openAudio')" />
                      </div>
                      <template v-if="speakerUserId === userId ? true : false">
                        <div class="changeMicrophoneVideo"></div>
                      </template>
                    </div>
                  </template>
                  <template v-if="item.userId !== userId">
                    <div class="userRightIcon">
                      <div class="changeMicrophoneVideo"  @click="changeIsPubVideoOther(item)">
                        <template v-if="item.pubVideo">
                            <img v-if="item.myVideo" src="./img/camera-active.png" :alt="$t('closeAudio')" />
                            <img v-else src="./img/camera_grey.png" :alt="$t('closeAudio')" />
                        </template>
                        <template v-else>
                            <img src="./img/camera-disable.png" :alt="$t('closeAudio')" />
                        </template>
                      </div>
                      <div class="changeMicrophoneVideo"  @click="changeIsPubAudioOther(item)">
                        <template v-if="item.pubAudio">
                            <img v-if="item.myAudio" src="./img/microphone-active.png" :alt="$t('closeAudio')" />
                            <img v-else src="./img/microphone_grey.png" :alt="$t('closeAudio')" />
                        </template>
                        <template v-else>
                            <img src="./img/microphone-disable.png" :alt="$t('closeAudio')" />
                        </template>
                      </div>
                      <template v-if="speakerUserId === userId ? true : false">
                        <div class="changeMicrophoneVideo"  @click.stop="getnickMore($event,item.userId)" ref="showNickMore">
                          <img src="./img/dianmore.png" :alt="$t('openAudio')" />
                            <div class="navnickinfoShow" :id="'nick'+ item.userId" v-if="isnickinfoshow && getnickinId === item.userId" :style="{top: pageHeight + 'px' }">
                              <div class="navnickinfoItem" @click="openSpeakerAuthor(item)">{{$t('SetMasterControl')}}</div>
                              <div class="navnickinfoItem" @click="forceRemoveCommand(item.userId)">{{$t('forceRemove')}}</div>
                            </div>
                        </div>
                      </template>
                    </div>
                  </template>
                </div>
              </div>
              <!--音视频操作-->
              <div class="mainOptions">
                <div>
                  <div class="icon" @click="changeIsPubVideo">
                    <img v-if="isPubVideo" src="./img/camera_open.png" alt="" />
                    <img v-else src="./img/camera_close.png" alt="" />
                  </div>
                  <p>{{ isPubVideo ? $t('closeCamera') : $t('openCamera') }}</p>
                </div>
                <div>
                  <div class="icon" @click="changeIsPubAudio">
                    <img v-if="isPubAudio" src="./img/audio_open.png" alt="" />
                    <img v-else src="./img/audio_close.png" alt="" />
                  </div>
                  <p>{{ isPubAudio ? $t('closeAudio') : $t('openAudio') }}</p>
                </div>
                <div>
                  <div class="icon" @click="applyToSpeaker">
                    <img
                      v-if="speakerUserId === userId"
                      src="./img/speaker_open.png"
                      alt=""
                    />
                    <img v-else src="./img/speaker_close.png" alt="" />
                  </div>
                  <p>
                    {{speakerUserId === userId ? $t('giveUpSpeaker'): $t('applySpeaker')
                    }}
                  </p>
                </div>
              </div>
              <!--主讲操作-->
              <div class="subOptions">
                <template v-if="speakerUserId === userId">
                  <div @click="openWhiteBoard">
                    <img src="./img/whiteBoard.png" alt="" />{{$t('whiteBoard')}}
                  </div>
                  <div @click="openTransparentWhiteBoard">
                    <img src="./img/whiteBoardTras.png" alt="" />{{
                      $t('transparentBoard')
                    }}
                  </div>
                  <div @click="openStaticMark">
                    <img src="./img/staticTag.png" alt="" />{{
                      $t('staticMark')
                    }}
                  </div>
                  <div @click="fullScreenMainVideo">
                    <img src="./img/dynamicTag.png" alt="" />{{
                      $t('dynamicMark')
                    }}
                  </div>
                  <div @click="openShareDesk" :class="{ active: isPubShare }">
                    <img src="./img/shareDesktop.png" alt="" />{{
                      isPubShare ? $t('closeShareDesktop'): $t('openShareDesktop')
                    }}
                  </div>
                  <!-- <div @click="openSpeakerAuthor">
                    <img src="./img/speakerAuthor.png" alt="" />{{
                      $t('speakerAuthor')
                    }}
                  </div> -->
                </template>
                <!-- 报修详情 -->
                <div @click="openRepairDetails" v-show="isShowRepairDetails">
                  <img src="./img/repairDetails.png" alt="" />{{ $t('meetingRepairDetails') }}
                </div>
                <!--文件上传-->
                <div @click="openMeetingUploadFile">
                  <img src="./img/meetingUpload.png" alt="" />{{ $t('meetingUploadFile') }}
                </div>
                <!-- 水印 -->
                <div @click="openWatermark">
                  <img src="./img/meetingUpload.png" alt="" />
                  {{isWatermark ? $t('closeWatermark'): $t('openWatermark')}}
                </div>
                <!--退出会议-->
                <div @click="leaveGroup">
                  <img src="./img/outMeet.png" alt="" />{{ $t('outMeeting') }}
                </div>
              </div>
              <!--消息标题-->
              <div class="messageTitle">
                <img src="./img/message.png" alt="" />{{ $t('chat') }}
              </div>
            </div>
            <div class="rightCenterWrap">
              <!--消息内容-->
                <el-dialog
                  width="80%"
                  top="1vh"
                  ref="uploadFileBox"
                  class="uploadFileBoxRead"
                  v-if="showReadFile"
                  :visible.sync="showReadFile"
                  v-model="showReadFile"
                  :show-close="true"
                  @close="showReadFile = false"
                  :append-to-body="false"
                >
                <div class="upload_heard">{{showFileName}} {{$t('showReadFile')}}
                  <div class="uploadBtnBox">
                    <div class="uploadBtnIcon"  @click="uploadLink(uploadFileData)"></div>
                  </div>
                </div>
                <iframe class="uploadLink" :src="uploadFileShow"></iframe>
              </el-dialog>
              <div class="messageContent" id="messageContent">
                <div class="messageLists">
                  <div class="messageList"
                    :class="{ isSelf: item.userId === userId }" v-for="(item, index) in messageList" :key="index"
                  >
                    <!-- 文件推送 -->
                    <template v-if="item.fileName">
                        <div class="headName" v-if="item.userAccountUid === userAccountId">
                        <el-row>
                          <el-col :span="2" :offset="19">
                            {{ $t('my') }}
                          </el-col>
                          <el-col :span="3">
                            <img :src="item.userAccountHeadImg ? item.userAccountHeadImg : require('./../../assets/defaultHead.jpg')" alt="" />
                          </el-col>
                        </el-row>
                      </div>
                      <div class="headName" v-if="item.userAccountUid !== userAccountId">
                        <el-row>
                          <el-col :span="3">
                            <img :src="item.userAccountHeadImg ? item.userAccountHeadImg : require('./../../assets/defaultHead.jpg')" alt="" />
                          </el-col>
                          <el-col :span="21">
                             {{item.userAccountName}}({{item.accountId}})
                          </el-col>
                        </el-row>
                      </div>
                    </template>
                    <!-- 消息内容 -->
                    <template v-else>
                      <template v-if="item.userId">
                      <div class="headName" v-if="item.userId === userId">
                        <el-row>
                          <el-col :span="2" :offset="19">
                            {{ $t('my') }}
                          </el-col>
                          <el-col :span="3">
                            <img :src="user.headImg ? user.headImg : require('./../../assets/defaultHead.jpg')" alt="" />
                          </el-col>
                        </el-row>
                      </div>
                      <div class="headName" v-if="item.userId !== userId">
                        <el-row>
                          <el-col :span="3">
                            <img :src="item.headImg ? item.headImg : require('./../../assets/defaultHead.jpg')" alt="" />
                          </el-col>
                          <el-col :span="21">
                            {{ item.showName }}({{ item.userId }})
                          </el-col>
                        </el-row>
                      </div>
                      </template>
                    </template>
                    <!-- 文件推送 -->
                    <template v-if="item.fileName">
<!--                      {{userAccountId}}<br/>{{userAccountId}}-->
                      <div class="textBox" v-if="item.userAccountUid === userAccountId && item.fileName">
                        <div class="textBoxContent">
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'doc' ">
                           <img src="./img/docx.png"/>{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'xls' ">
                            <img src="./img/xls.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'pdf' ">
                            <img src="./img/pdf.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'ppt' ">
                            <img src="./img/ppt.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'png' ">
                            <img src="./img/png.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'avi' ">
                            <img src="./img/avi.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'css' ">
                            <img src="./img/css.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'csv' ">
                            <img src="./img/csv.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'eps' ">
                            <img src="./img/eps.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'html' ">
                            <img src="./img/html.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'jpg' ">
                            <img src="./img/jpg.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'jpeg' ">
                            <img src="./img/jpg.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'mov' ">
                            <img src="./img/mov.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'mp3' ">
                            <img src="./img/mp3.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'mp4' ">
                            <img src="./img/mp4.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'rar' ">
                            <img src="./img/rar.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'raw' ">
                            <img src="./img/raw.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'ttf' ">
                            <img src="./img/ttf.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'txt' ">
                            <img src="./img/txt.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'wav' ">
                            <img src="./img/wav.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'zip' ">
                            <img src="./img/zip.png" />{{item.fileName}}
                          </div>
                          <template v-if="item.isBackOut === 'YES'">
                             <div class="textBoxright">{{ $t('fileRecall') }}</div>
                          </template>
                          <template v-if="item.isRead">
                             <div class="textBoxright">{{ $t('otherSideFileAccept') }}</div>
                          </template>
                          <template v-if="item.isBackOut !== 'YES'">
                            <div class="textFoot">
                                <span v-if="!item.isRead" class="txtBt texGrey" @click="cancelMettingPushFile(item.pushFileRecordId)">{{ $t('uploadBackOut') }}</span>
                                <span v-if="item.isRead" class="txtBt texGrey" @click="delMettingFileRecord(item.pushFileRecordId)">{{ $t('delete') }}</span>
                                <span class="txtBt texColor" @click="getMettingPushFile(item.pushFileRecordId)">{{$t('uploadPreview')}}</span>
                            </div>
                          </template>
                          <template v-else>
                             <div class="textFoot">
                              <span class="txtBt texGrey" @click="delMettingFileRecord(item.pushFileRecordId)">{{ $t('delete') }}</span>
                             </div>
                          </template>
                        </div>
                      </div>
                      <div class="textBoxLeft" v-if="item.userAccountUid !== userAccountId" >
                        <div class="textBoxContent">
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'doc' ">
                           <img src="./img/docx.png"/>{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'xls' ">
                            <img src="./img/xls.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'pdf' ">
                            <img src="./img/pdf.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'ppt' ">
                            <img src="./img/ppt.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'png' ">
                            <img src="./img/png.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'avi' ">
                            <img src="./img/avi.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'css' ">
                            <img src="./img/css.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'csv' ">
                            <img src="./img/csv.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'eps' ">
                            <img src="./img/eps.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'html' ">
                            <img src="./img/html.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'jpg' ">
                            <img src="./img/jpg.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'jpeg' ">
                            <img src="./img/jpg.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'mov' ">
                            <img src="./img/mov.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'mp3' ">
                            <img src="./img/mp3.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'mp4' ">
                            <img src="./img/mp4.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'rar' ">
                            <img src="./img/rar.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'raw' ">
                            <img src="./img/raw.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'ttf' ">
                            <img src="./img/ttf.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'txt' ">
                            <img src="./img/txt.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'wav' ">
                            <img src="./img/wav.png" />{{item.fileName}}
                          </div>
                          <div class="fileTitlePic" v-if="$utils.fileSuffixType(item.fileName) === 'zip' ">
                            <img src="./img/zip.png" />{{item.fileName}}
                          </div>
                           <template v-if="item.isBackOut === 'YES'">
                               <div class="textBoxright">{{ $t('otherSideFileRecall') }}</div>
                          </template>
                         <template v-if="item.isBackOut !== 'YES'">
                            <div class="textFoot" >
                              <span class="txtBt texGrey" @click="delMettingFileRecord(item.pushFileRecordId)">{{ $t('delete') }}</span>
                              <span class="txtBt texColor" @click="getMettingPushFile(item.pushFileRecordId)">{{$t('uploadPreview')}}</span>
                            </div>
                          </template>
                          <template v-else>
                            <div class="textFoot" >
                              <span class="txtBt texGrey" @click="delMettingFileRecord(item.pushFileRecordId)">{{ $t('delete') }}</span>
                              <!-- <span class="txtBt texColor" @click="getMettingPushFile(item.pushFileRecordId)">{{$t('uploadPreview')}}</span> -->
                            </div>
                          </template>
                        </div>
                      </div>
                    </template>
                    <!-- 消息内容 -->
                    <template v-else>
                        <p v-if="item.userId" class="messageBox">
                        <template v-if="item.url">
                          <img
                            class="messageImg"
                            :src="item.url"
                            alt=""
                            @click="previewImg(item.url)"
                          />
                          <div class="imageBox">
                            <div class="imageBoxItem color" >
                              <span @click="downloadImage(item)">{{ $t('downloadImage') }}</span>
<!--                              <a :href="item.url" :download="item.showName">{{ $t('downloadImage') }}</a>-->
                            </div>
                            <div class="imageBoxItem" v-if="speakerUserId === userId ? true : false
                            "><el-link type="primary" @click="importWhiteBoard(item)" >{{ $t('importWhiteBoard') }}</el-link> </div>
                          </div>
                        </template>
                        <template v-else>
                          {{ item.msg }}
                        </template>
                      </p>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="rightBottomWrap">
              <!--输入聊天内容-->
              <div class="sendMessage">
                <div class="sendIpt">
                  <el-input
                    :placeholder="$t('chatPlaceholder')"
                    size="small"
                    v-model="inputMsg"
                    @keyup.enter.native="commitInputMsg"
                  />
                </div>
                <div class="sendBtn" @click="commitInputMsg">
                  <img src="./img/sendMessage.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <!-- 昵称 -->
    <base-dialog
      v-model="setNicknameVisible"
      v-if="setNicknameVisible"
      :title="$t('setNickName')"
      height="auto"
      @close="closeNickName"
      @confirm="confirmNickName"
    >
      <div class="nickNameBox">
        <el-input
          type="text"
          :placeholder="$t('pleaseEnterNickname')"
          v-model.trim="nickNameDefault"
          maxlength="10"
          show-word-limit
        />
      <p>{{$t('setNickNamePattern')}}</P>
      </div>
    </base-dialog>
    <!-- 文件上传 -->
    <el-dialog
      width="508px"
      height="100px"
      class="gitUploadFileBoxNew"
      v-if="showMettingUploadFile"
      v-model="showMettingUploadFile"
      :title="$t('uploadFileTitle')"
      :visible.sync="showMettingUploadFile"
      @close="showMettingUploadFile = false"
    >
    <div class="uploadFileBox" >
       <el-upload
          ref="mettingUploadFile"
          class="upload-demo"
          action="#"
          accept=".doc,.docx,.pdf,.xls,.xlsx,.ppt,.pptx,.png,.jpeg,jpg,.mp4"
          :auto-upload="false"
          :on-change="mettingHandleChange"
          :headers="mettingHeaders"
          :file-list="fileList"
          :http-request="mettingUploadFileLoad"
          :on-remove="mettingUploadFileRemove"
         >
         <el-button size="small" type="text" style="width:100px;">{{$t('uploadFileSelect')}}</el-button>
         <div class="fileSizeBox">{{fileSize}}</div>
         <el-progress class="progress" v-if="progressFlag"  :stroke-width="5" :percentage="progressPercent"></el-progress>
      </el-upload>
      <div class="uploadFooter">
          <el-button size="small" round @click="mettingUploadCancal">{{$t('uploadcancel')}}</el-button>
          <el-button size="small" id="fileOnSend" round @click="mettingUploadFileSubmit">{{$t('uploadconfirm')}}</el-button>
      </div>
    </div>
    </el-dialog>

    <!--主教授权-->
    <base-dialog
      v-model="speakerAuthorDialog"
      :title="$t('speakerAuthor')"
      width="400px"
      height="300px"
      @close="speakerAuthorDialog = false"
      @confirm="confirmSpeakerAuthor"
    >
      <el-radio-group class="unSpeakerList" v-model="speakerAuthorUserId">
        <template v-for="item in groupUserList">
          <div
            class="radioList"
            :key="item.userId"
            v-if="item.userId !== userId"
          >
            <el-radio :label="item.userId">{{ item.showName }}</el-radio>
          </div>
        </template>
      </el-radio-group>
    </base-dialog>
    <!-- 报修详情 -->
    <el-dialog
      class="equipmentInfo"
      :visible.sync="showEquipmentDetails"
      :title="$t('meetingRepairDetails')"
      width="628px"
      height="auto"
      :showFooter="false"
      :close-on-click-modal="false"
      v-if="showEquipmentDetails"
      @close="showEquipmentDetails = false"
    >
      <template v-if="equipInfo">
      <el-row>
        <el-col :span="24">
          <div class="enHead">
            <div class="enHeadLeft">
              <img :src="inviteUserInfo.headImg ? inviteUserInfo.headImg :  require('./img/touxiang.png')"  alt=""/>
            </div>
            <div class="enHeadRight">
              <p>{{ inviteUserInfo.companyName }}</p>
              <p>{{ inviteUserInfo.name }} - {{ inviteUserInfo.accountId }}</p>
              <p></p>
            </div>
        </div>
        </el-col>
      </el-row>
      <div class="enInfoBox">
        <el-row class="enInfoTop">
          <el-col :span="3"><div class="enInfoItemTitle">{{ $t('equipmentName') }}：</div></el-col>
          <el-col :span="10"><div class="enInfoItemContent">{{ equipInfo.equipment.name }} </div></el-col>
          <el-col :span="5"><div class="enInfoItemTitle">{{ $t('equipmentModel') }}：</div></el-col>
          <el-col :span="6"><div class="enInfoItemRight">{{ equipInfo.equipment.model }}</div></el-col>
        </el-row>
        <el-row>
          <el-col :span="3"><div class="enInfoItemTitle">{{ $t('saleCreateTime') }}：</div></el-col>
          <el-col :span="9"><div class="enInfoItemContent">{{ $utils.format(equipInfo.createTime, 'yyyy-MM-dd hh:mm:ss') }}</div></el-col>
          <el-col :span="6"><div class="enInfoItemTitleRight">{{ $t('saleWarrantyTime') }}：</div></el-col>
          <el-col :span="6"><div class="enInfoItemRight">{{ $utils.format(equipInfo.warrantyTime, 'yyyy-MM-dd hh:mm:ss') }}</div></el-col>
        </el-row>
         <template v-if="equipInfo.level === 'TWO' || equipInfo.level === 'THREE'">
          <el-row>
            <el-col :span="3"><div class="enInfoItemTitle">{{ $t('partsName') }}：</div></el-col>
            <el-col :span="10"><div class="enInfoItemContent">{{ equipInfo.parts.name }}</div></el-col>
            <el-col :span="5" :push=2><div class="enInfoItemTitle">{{ $t('partsModel') }}：</div></el-col>
            <el-col :span="6"><div class="enInfoItemRight">{{ equipInfo.parts.model }}</div></el-col>
          </el-row>
        </template>
        <template v-if="equipInfo.level === 'THREE'">
          <el-row>
            <el-col :span="3"><div class="enInfoItemTitle">{{ $t('faultName') }}：</div></el-col>
            <el-col :span="10">{{ equipInfo.fault.name }}</el-col>
            <el-col :span="5" :push=2><div class="enInfoItemTitle">{{ $t('faultNumber') }}：</div></el-col>
            <el-col :span="6"><div class="enInfoItemRight">{{ equipInfo.fault.number }}</div></el-col>
          </el-row>
        </template>

        <el-row v-if="equipInfo.level === 'ONE'">
          <el-col :span="3"><div class="enInfoItemTitle">{{ $t('equipmentDetail') }}：</div></el-col>
          <el-col :span="21"><div class="enInfoDescribe" v-html="equipInfo.equipment.detail"></div></el-col>
        </el-row>
        <el-row v-else-if="equipInfo.level === 'TWO'">
          <el-col :span="3"><div class="enInfoItemTitle">{{ $t('partsDetail') }}：</div></el-col>
          <el-col :span="21"><div class="enInfoDescribe" v-html="equipInfo.equipment.detail"></div></el-col>
        </el-row>
        <el-row v-else>
          <el-col :span="3"><div class="enInfoItemTitle">{{ $t('faultDetail') }}：</div></el-col>
          <el-col :span="21"><div class="enInfoDescribe" v-html="equipInfo.equipment.detail"></div></el-col>
        </el-row>
        <el-row>
          <el-col :span="3"><div class="enInfoItemTitle">{{ $t('partsImage') }}：</div></el-col>
          <el-col :span="21"></el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="enInfoSwiperBox" v-if="equipInfo.level === 'ONE' && equipInfo.equipment.imageSet && equipInfo.equipment.imageSet.length ">
            <el-carousel height="180px" indicator-position="none" arrow="always">
              <el-carousel-item v-for="(item, index) in equipInfoPicList" :key="index">
                <el-image class="enInfoImg" v-for="(item2, index2) in item" :key="index2" :src="item2.fileHost + '/' + item2.objectName" fit="cover"/>
              </el-carousel-item>
            </el-carousel>
            </div>
            <div class="equipmentSwiperBox" v-if="equipInfo.level === 'TWO' && equipInfo.parts.imageSet && equipInfo.parts.imageSet.length">
              <el-carousel height="180px" arrow="always" indicator-position="none" >
                <el-carousel-item v-for="(item, index) in equipInfoPicList" :key="index">
                <el-image class="enInfoImg" v-for="(item2, index2) in item" :key="index2" :src="item2.fileHost + '/' + item2.objectName" fit="cover"/>
              </el-carousel-item>
              </el-carousel>
            </div>
            <div class="equipmentSwiperBox" v-if="equipInfo.level === 'THREE' && equipInfo.fault.imageSet &&
                equipInfo.fault.imageSet.length">
              <el-carousel height="180px" arrow="always" indicator-position="none">
                <el-carousel-item v-for="(item, index) in equipInfoPicList" :key="index">
                <el-image class="enInfoImg" v-for="(item2, index2) in item" :key="index2" :src="item2.fileHost + '/' + item2.objectName" fit="cover"/>
              </el-carousel-item>
              </el-carousel>
            </div>
          </el-col>
        </el-row>
      </div>
        <!-- <div class="enInfo">
          <h3>
            <template v-if="equipInfo.level === 'ONE'">{{
              $t('equipmentInfo')
            }}</template>
            <template v-else-if="equipInfo.level === 'TWO'">{{
              $t('partsInfo')
            }}</template>
            <template v-else>{{ $t('faultInfo') }}</template>
          </h3>
          <ul class="infoList">
            <li>
              <span>{{ $t('equipmentName') }}：</span>
              <span>{{ equipInfo.equipment.name }}</span>
            </li>
            <li>
              <span>{{ $t('equipmentModel') }}：</span>
              <span>{{ equipInfo.equipment.model }}</span>
            </li>
            <li>
              <span>{{ $t('saleCreateTime') }}：</span>
              <span>{{
                $utils.format(equipInfo.createTime, 'yyyy-MM-dd hh:mm:ss')
              }}</span>
            </li>
            <li>
              <span>{{ $t('saleWarrantyTime') }}：</span>
              <span>{{
                $utils.format(equipInfo.warrantyTime, 'yyyy-MM-dd hh:mm:ss')
              }}</span>
            </li>
            <template
              v-if="equipInfo.level === 'TWO' || equipInfo.level === 'THREE'"
            >
              <li style="display: inline-block; width: 50%">
                <span>{{ $t('partsName') }}：</span>
                <span>{{ equipInfo.parts.name }}</span>
              </li>
              <li style="display: inline-block; width: 50%">
                <span>{{ $t('partsModel') }}：</span>
                <span>{{ equipInfo.parts.model }}</span>
              </li>
            </template>
            <template v-if="equipInfo.level === 'THREE'">
              <li style="display: inline-block; width: 50%">
                <span>{{ $t('faultName') }}：</span>
                <span>{{ equipInfo.fault.name }}</span>
              </li>
              <li style="display: inline-block; width: 50%">
                <span>{{ $t('faultNumber') }}：</span>
                <span>{{ equipInfo.fault.number }}</span>
              </li>
            </template>
            <li v-if="equipInfo.level === 'ONE'">
              <span>{{ $t('equipmentDetail') }}：</span>
              <span style="width: 740px; padding: 5px; box-sizing: border-box">
                <a class="AREquList" v-html="equipInfo.equipment.detail" />
              </span>
            </li>
            <li v-else-if="equipInfo.level === 'TWO'">
              <span>{{ $t('partsDetail') }}：</span>
              <span style="width: 740px; padding: 5px; box-sizing: border-box">
                <a class="AREquList" v-html="equipInfo.parts.detail" />
              </span>
            </li>
            <li v-else>
              <span>{{ $t('faultDetail') }}：</span>
              <span style="width: 740px; padding: 5px; box-sizing: border-box">
                <a class="AREquList" v-html="equipInfo.fault.detail" />
              </span>
            </li>
          </ul>
          <div
            class="equipmentSwiperBox"
            v-if="
              equipInfo.level === 'ONE' &&
              equipInfo.equipment.imageSet &&
              equipInfo.equipment.imageSet.length
            "
          >
            <el-carousel height="180px">
              <el-carousel-item
                v-for="item in equipInfo.equipment.imageSet"
                :key="item.id"
              >
                <el-image
                  style="width: 410px; height: 180px"
                  :src="item.fileHost + '/' + item.objectName"
                  fit="contain"
                />
              </el-carousel-item>
            </el-carousel>
          </div>
          <div
            class="equipmentSwiperBox"
            v-if="
              equipInfo.level === 'TWO' &&
              equipInfo.parts.imageSet &&
              equipInfo.parts.imageSet.length
            "
          >
            <el-carousel height="180px" arrow="always">
              <el-carousel-item
                v-for="item in equipInfo.parts.imageSet"
                :key="item.id"
              >
                <el-image
                  style="width: 410px; height: 180px"
                  :src="item.fileHost + '/' + item.objectName"
                  fit="contain"
                />
              </el-carousel-item>
            </el-carousel>
          </div>
          <div
            class="equipmentSwiperBox"
            v-if="
              equipInfo.level === 'THREE' &&
              equipInfo.fault.imageSet &&
              equipInfo.fault.imageSet.length
            "
          >
            <el-carousel height="180px">
              <el-carousel-item
                v-for="item in equipInfo.fault.imageSet"
                :key="item.id"
              >
                <el-image
                  style="width: 410px; height: 180px"
                  :src="item.fileHost + '/' + item.objectName"
                  fit="contain"
                />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div> -->
      </template>
    </el-dialog>

    <!--邀请显示设备信息-->
    <el-dialog
      class="equipmentInfo"
      :visible.sync="showEquipmentInfo"
      v-if="showEquipmentInfo"
      :title="$t('remoteGuideAccept')"
      width="700px"
      height="auto"
      :showFooter="false"
      :show-close="false"
      :close-on-click-modal="false"
    >
    <template v-if="equipInfo">
      <el-row>
        <el-col :span="24">
          <div class="enHead">
            <div class="enHeadLeft">
              <img :src="inviteUserInfo.headImg ? inviteUserInfo.headImg :  require('./img/touxiang.png')"  alt=""/>
            </div>
            <div class="enHeadRight">
              <p>{{ inviteUserInfo.companyName }}</p>
              <p>{{ inviteUserInfo.name }} - {{ inviteUserInfo.accountId }}</p>
              <p>{{ $t('hasInvite') }}( <span id="equipIDSeconds">30</span>s)</p>
            </div>
        </div>
        </el-col>
      </el-row>
       <el-row>
        <el-col :span="24">
          <div class="enFoot">
            <el-button size="small" @click="closeEquipmentInfo">{{ $t('reject') }}</el-button>
            <el-button size="small" @click="confirmEquipmentInfo">{{ $t('accept') }}</el-button>
          </div>
        </el-col>
      </el-row>
      <div class="enInfoBox">
        <el-row class="enInfoTop">
          <el-col :span="5"><div class="enInfoItemTitle">{{ $t('equipmentName') }}：</div></el-col>
          <el-col :span="7"><div class="enInfoItemContent">{{ equipInfo.equipment.name }} </div></el-col>
          <el-col :span="5"><div class="enInfoItemTitle">{{ $t('equipmentModel') }}：</div></el-col>
          <el-col :span="7"><div class="enInfoItemRight">{{ equipInfo.equipment.model }}</div></el-col>
        </el-row>
        <el-row>
          <el-col :span="5"><div class="enInfoItemTitle">{{ $t('saleCreateTime') }}：</div></el-col>
          <el-col :span="7"><div class="enInfoItemContent">{{ $utils.format(equipInfo.createTime, 'yyyy-MM-dd hh:mm:ss') }}</div></el-col>
          <el-col :span="5"><div class="enInfoItemTitle">{{ $t('saleWarrantyTime') }}：</div></el-col>
          <el-col :span="7"><div class="enInfoItemRight">{{ $utils.format(equipInfo.warrantyTime, 'yyyy-MM-dd hh:mm:ss') }}</div></el-col>
        </el-row>
         <template v-if="equipInfo.level === 'TWO' || equipInfo.level === 'THREE'">
          <el-row>
            <el-col :span="5"><div class="enInfoItemTitle">{{ $t('partsName') }}：</div></el-col>
            <el-col :span="7"><div class="enInfoItemContent">{{ equipInfo.parts.name }}</div></el-col>
            <el-col :span="5"><div class="enInfoItemTitle">{{ $t('partsModel') }}：</div></el-col>
            <el-col :span="7"><div class="enInfoItemRight">{{ equipInfo.parts.model }}</div></el-col>
          </el-row>
        </template>
        <template v-if="equipInfo.level === 'THREE'">
          <el-row>
            <el-col :span="5"><div class="enInfoItemTitle">{{ $t('faultName') }}：</div></el-col>
            <el-col :span="7"><div class="enInfoItemRight">{{ equipInfo.fault.name }}</div></el-col>
            <el-col :span="5"><div class="enInfoItemTitle">{{ $t('faultNumber') }}：</div></el-col>
            <el-col :span="7"><div class="enInfoItemRight">{{ equipInfo.fault.number }}</div></el-col>
          </el-row>
        </template>

        <el-row v-if="equipInfo.level === 'ONE'">
          <el-col :span="5"><div class="enInfoItemTitle">{{ $t('equipmentDetail') }}：</div></el-col>
          <el-col :span="19"><div class="enInfoDescribe" v-html="equipInfo.equipment.detail"></div></el-col>
        </el-row>
        <el-row v-else-if="equipInfo.level === 'TWO'">
          <el-col :span="5"><div class="enInfoItemTitle">{{ $t('partsDetail') }}：</div></el-col>
          <el-col :span="19"><div class="enInfoDescribe" v-html="equipInfo.equipment.detail"></div></el-col>
        </el-row>
        <el-row v-else>
          <el-col :span="5"><div class="enInfoItemTitle">{{ $t('faultDetail') }}：</div></el-col>
          <el-col :span="10"><div class="enInfoDescribe" v-html="equipInfo.equipment.detail"></div></el-col>
        </el-row>
        <el-row v-if="equipInfo.level === 'ONE'">
          <el-col :span="5"><div class="enInfoItemTitle">{{ $t('equipmentImage') }}：</div></el-col>
          <el-col :span="19"></el-col>
        </el-row>
        <el-row v-else-if="equipInfo.level === 'TWO'">
          <el-col :span="5"><div class="enInfoItemTitle">{{ $t('partsImage') }}：</div></el-col>
          <el-col :span="19"></el-col>
        </el-row>
        <el-row v-else>
          <el-col :span="5"><div class="enInfoItemTitle">{{ $t('faultImage') }}：</div></el-col>
          <el-col :span="19"></el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="enInfoSwiperBox" v-if="equipInfo.level === 'ONE' && equipInfo.equipment.imageSet && equipInfo.equipment.imageSet.length ">
            <el-carousel height="180px" indicator-position="none" arrow="always">
              <el-carousel-item v-for="(item, index) in equipInfoPicList" :key="index">
                <el-image class="enInfoImg" v-for="(item2, index2) in item" :key="index2" :src="item2.fileHost + '/' + item2.objectName" fit="cover"/>
              </el-carousel-item>
            </el-carousel>
            </div>
            <div class="equipmentSwiperBox" v-if="equipInfo.level === 'TWO' && equipInfo.parts.imageSet && equipInfo.parts.imageSet.length">
              <el-carousel height="180px" arrow="always" indicator-position="none" >
                <el-carousel-item v-for="(item, index) in equipInfoPicList" :key="index">
                <el-image class="enInfoImg" v-for="(item2, index2) in item" :key="index2" :src="item2.fileHost + '/' + item2.objectName" fit="cover"/>
              </el-carousel-item>
              </el-carousel>
            </div>
            <div class="equipmentSwiperBox" v-if="equipInfo.level === 'THREE' && equipInfo.fault.imageSet &&
                equipInfo.fault.imageSet.length">
              <el-carousel height="180px" arrow="always" indicator-position="none">
                <el-carousel-item v-for="(item, index) in equipInfoPicList" :key="index">
                <el-image class="enInfoImg" v-for="(item2, index2) in item" :key="index2" :src="item2.fileHost + '/' + item2.objectName" fit="cover"/>
              </el-carousel-item>
              </el-carousel>
            </div>
          </el-col>
        </el-row>
      </div>
    </template>
      <!-- <template v-if="equipInfo">
        <div class="enHead">
          <div class="enHeadLeft">
            <img :src="inviteUserInfo.headImg ? inviteUserInfo.headImg :  require('./img/touxiang.png')"  alt=""/>
          </div>
          <div class="enHeadRight">
            <p>{{ inviteUserInfo.companyName }}</p>
            <p>{{ inviteUserInfo.name }} - {{ inviteUserInfo.accountId }}</p>
            <p>{{ $t('hasInvite') }}( <span id="equipIDSeconds">30</span>s)</p>
          </div>
        </div>
        <div class="enFoot">
          <el-button size="small" @click="closeEquipmentInfo">{{
            $t('reject')
          }}</el-button>
          <el-button size="small" @click="confirmEquipmentInfo">{{
            $t('accept')
          }}</el-button>
        </div>
        <div class="enInfo">
          <h3>
            <template v-if="equipInfo.level === 'ONE'">{{
              $t('equipmentInfo')
            }}</template>
            <template v-else-if="equipInfo.level === 'TWO'">{{
              $t('partsInfo')
            }}</template>
            <template v-else>{{ $t('faultInfo') }}</template>
          </h3>
          <ul class="infoList">
            <li>
              <span>{{ $t('equipmentName') }}：</span>
              <span>{{ equipInfo.equipment.name }}</span>
            </li>
            <li>
              <span>{{ $t('equipmentModel') }}：</span>
              <span>{{ equipInfo.equipment.model }}</span>
            </li>
            <li>
              <span>{{ $t('saleCreateTime') }}：</span>
              <span>{{ $utils.format(equipInfo.createTime, 'yyyy-MM-dd hh:mm:ss') }}</span>
            </li>
            <li>
              <span>{{ $t('saleWarrantyTime') }}：</span>
              <span>{{
                $utils.format(equipInfo.warrantyTime, 'yyyy-MM-dd hh:mm:ss')
              }}</span>
            </li>
            <template
              v-if="equipInfo.level === 'TWO' || equipInfo.level === 'THREE'"
            >
              <li style="display: inline-block; width: 50%">
                <span>{{ $t('partsName') }}：</span>
                <span>{{ equipInfo.parts.name }}</span>
              </li>
              <li style="display: inline-block; width: 50%">
                <span>{{ $t('partsModel') }}：</span>
                <span>{{ equipInfo.parts.model }}</span>
              </li>
            </template>
            <template v-if="equipInfo.level === 'THREE'">
              <li style="display: inline-block; width: 50%">
                <span>{{ $t('faultName') }}：</span>
                <span>{{ equipInfo.fault.name }}</span>
              </li>
              <li style="display: inline-block; width: 50%">
                <span>{{ $t('faultNumber') }}：</span>
                <span>{{ equipInfo.fault.number }}</span>
              </li>
            </template>
            <li v-if="equipInfo.level === 'ONE'">
              <span>{{ $t('equipmentDetail') }}：</span>
              <span style="width: 740px; padding: 5px; box-sizing: border-box">
                <a class="AREquList" v-html="equipInfo.equipment.detail" />
              </span>
            </li>
            <li v-else-if="equipInfo.level === 'TWO'">
              <span>{{ $t('partsDetail') }}：</span>
              <span style="width: 740px; padding: 5px; box-sizing: border-box">
                <a class="AREquList" v-html="equipInfo.parts.detail" />
              </span>
            </li>
            <li v-else>
              <span>{{ $t('faultDetail') }}：</span>
              <span style="width: 740px; padding: 5px; box-sizing: border-box">
                <a class="AREquList" v-html="equipInfo.fault.detail" />
              </span>
            </li>
          </ul>
          <div
            class="equipmentSwiperBox"
            v-if="
              equipInfo.level === 'ONE' &&
              equipInfo.equipment.imageSet &&
              equipInfo.equipment.imageSet.length
            "
          >
            <el-carousel height="180px">
              <el-carousel-item
                v-for="item in equipInfo.equipment.imageSet"
                :key="item.id"
              >
                <el-image
                  style="width: 410px; height: 180px"
                  :src="item.fileHost + '/' + item.objectName"
                  fit="contain"
                />
              </el-carousel-item>
            </el-carousel>
          </div>
          <div
            class="equipmentSwiperBox"
            v-if="
              equipInfo.level === 'TWO' &&
              equipInfo.parts.imageSet &&
              equipInfo.parts.imageSet.length
            "
          >
            <el-carousel height="180px" arrow="always">
              <el-carousel-item
                v-for="item in equipInfo.parts.imageSet"
                :key="item.id"
              >
                <el-image
                  style="width: 410px; height: 180px"
                  :src="item.fileHost + '/' + item.objectName"
                  fit="contain"
                />
              </el-carousel-item>
            </el-carousel>
          </div>
          <div
            class="equipmentSwiperBox"
            v-if="
              equipInfo.level === 'THREE' &&
              equipInfo.fault.imageSet &&
              equipInfo.fault.imageSet.length
            "
          >
            <el-carousel height="180px">
              <el-carousel-item
                v-for="item in equipInfo.fault.imageSet"
                :key="item.id"
              >
                <el-image
                  style="width: 410px; height: 180px"
                  :src="item.fileHost + '/' + item.objectName"
                  fit="contain"
                />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </template> -->
    </el-dialog>
    <!-- 设备信息展示 -->
    <base-dialog
      ref="equimentBoxKKK"
      class="equimentBoxKKK"
      v-if="showMenuEquiment"
      :title="$t('equipmentInfo')"
      width="1250px"
      height="600px"
      :show-footer="false"
      @close="showMenuEquiment = false"
    >
      <iframe class="equimentIframe" :src="showMenuEquimentSrc"></iframe>
    </base-dialog>
    <!-- 在线人员弹窗模块 -->
  </div>
</template>

<script>
import Index from './_index.js'

export default Index
</script>

<style lang="scss">
@import './_index.scss';
</style>
