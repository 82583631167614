// 中文
export default {
  // 基础
  cancel: "取消",
  confirm: "确定",
  hangUp: "挂断",
  know: "确定",
  apply: "申请",
  state: "状态码",
  loaing: "加载中...",
  more: "更多",
  // 登录页
  welcomeUse: "欢迎使用",
  aRAfterSale: "专家在线指导系统",
  maintenanceSystem: "智能设备维修系统",
  loginWelcome: "欢迎使用AR远程指导系统",
  account: "请输入用户名",
  savePassWord: "保存密码",
  autoLogin: "自动登录",
  password: "密码",
  login: "登录",
  accountType: "账户类型不正确",
  aboutUs: "关于我们",
  contactUs: "联系我们",
  technicalSupport: "技术支持",
  copyright: "© 2017-2022 蒂姆维澳（上海）网络技术有限公司 版权所有",
  languageSwitch: "切换语言",
  accountNoEmpty: "账号不能为空",
  passwordNoEmpty: "密码不能为空",
  accountNoCorrect: "账号不正确",
  passwordNoCorrect: "密码不正确",
  accountOrPasswordError: "账号或密码错误",
  networkError: "网络连接中断",
  networkCatch: "服务器异常",
  // 工具
  tool: "工具",
  searchServiceOrder: "服务单查询",
  orderId: "工单ID",
  orderIdPlaceholder: "请输入工单ID",
  noStart: "未开始",
  noEnd: "未结束",
  startTime: "服务开始时间",
  endTime: "服务结束时间",
  useTime: "服务用时",
  serviceStatus: "服务状态",
  arId: "服务AR ID",
  arName: "服务AR 昵称",
  interPhoneId: "服务对讲机ID",
  noData: "暂无数据",
  startAttention: "开始关注",
  startAttentionTips: "工单关注成功",
  cancelAttention: "取消关注",
  cancelAttentionTips: "工单已取消关注",
  // 设置
  set: "设置",
  cameraOrAudioSet: "相机和音频设置",
  camera: "摄像头",
  cameraPlaceholder: "请选择摄像头",
  revolveCamera: "画面旋转",
  openMirror: "开启镜像",
  microphone: "麦克风",
  microphonePlaceholder: "请选择麦克风",
  closeMicrophone: "关闭麦克风",
  testMicrophone: "麦克风测试",
  abnormalMicrophone: "麦克风异常",
  openMicrophone: "录制中",
  loudspeaker: "扬声器",
  loudspeakerPlaceholder: "请选择扬声器",
  updatedHeadImg: "头像更新成功",
  downHeadImg: "头像",
  setHeadImg: "头像设置",
  selectHeadImg: "选择头像",
  selectHeadImgTxt: "说明：支持PNG、JPG、JPGE格式",
  selectOnePIc: "选择一张图片",
  selectOnePicFalse: "请选择一张图片",
  selectOnePicSize: "上传图片大小不超过1M",
  downNickName: "昵称",
  setNickName: "昵称设置",
  changNickName: "昵称没有修改",
  setNickNamePattern: "请输入远程指导中昵称，不能为空，最多输入10个字",
  setAudioDevices: "选择设备",
  setAudioCall: "音视频通话",
  setAudioLayout: "设置布局",
  setVolume: "音量",
  setMicroPhoneVolume: "请点击麦克风测试，对着麦克风说话试听",
  setLoudSpeakerVolume: "请点击播放声音，试试能否听到声音",
  noSupport: "不支持",
  testMicroPhone: "麦克风测试",
  testLoudSpeaker: "播放测试声音",
  // APP下载
  loginAppDownload: "APP下载",
  appDownload: "APP下载",
  downloadTip1: '请使用安卓手机扫码下载或',
  downloadTip2: '下载到本地',
  SystemIntroduction: "系统介绍",
  Instructions: "使用说明",
  // 帮助
  help: "帮助",
  serviceSupport: "服务支持",
  companyName: "蒂姆维澳(上海)网络技术有限公司",
  phone: "座机：021-57618272",
  email: "邮箱：sup port@tmva-sh.com",
  website: "网址：www.tmva-sh.com",
  address: "地址：松江区莘砖公路239号拉斐尔云廊6号楼606-607室",
  versionCode: 'V3.1',
  // 退出登录
  logout: "退出登录",
  // 联系人
  searchContact: "搜索联系人",
  searchUserResult: "查找联系人",
  // 右侧工具
  attendee: "参会人",
  editNickName: "修改昵称",
  unlocked: "未锁定",
  locked: "已锁定",
  inviteComfirm: "邀请",
  SetMasterControl: "设为主控",
  forceRemove: "强制移出",
  forceRemoveWarn: "您已被请出当前远程指导",
  speaker: "主控",
  openCamera: "开启相机",
  closeCamera: "关闭相机",
  openAudio: "打开音频",
  closeAudio: "静音",
  applySpeaker: "申请主控",
  giveUpSpeaker: "放弃主控",
  applySpeakerApply: "申请主控权限呼出",
  applySpeakerAccept: "申请主控权限呼入",
  addRoomApply: "申请加入远程指导呼出",
  addRoomAccept: "申请加入远程指导呼入",
  whiteBoard: "白板交流",
  staticMark: "静态标记",
  dynamicMark: "动态指引",
  speakerAuthor: "主讲授权",
  openShareDesktop: "共享桌面",
  closeShareDesktop: "停止共享",
  meetingUploadFile: "文件上传",
  meetingRepairDetails: "报修详情",
  outMeeting: "退出远程指导",
  chat: "聊天",
  chatPlaceholder: "请输入聊天内容",
  openNoCamera: '摄像头未开启',
  // AR操作
  rearCamera: "后置相机",
  ARCamera: "AR相机",
  thermalImg: "热成像",
  photograph: "拍照",
  openLight: "打开闪光灯",
  closeLight: "关闭闪光灯",
  ARRecord: "AR录制",
  ARRClose: "录制中",
  openHotImg: "打开热力图",
  closeHotImg: "关闭热力图",
  areaMode: "区域模式",
  centerMode: "中心模式",
  openMultipoint: "打开多点测温",
  closeMultipoint: "关闭多点测温",
  color1: "高敏感度",
  color2: "高对比度",
  color3: "最高温区",
  zoomLargen: "放大",
  zoomMinish: "缩小",
  // 弹框或者轻提示
  userNotOnline: "该用户不在线，请稍后重试",
  msg: "提示",
  close: "关闭",
  hasInMeeting: "该用户已在当前远程指导中",
  noAcceptInvite: "对方正忙，暂时无法接受您的邀请",
  networkDisconnection: "您的网络已断开，请退出重新登录",
  time: "时间",
  temperature: "温度",
  getSpeakerPermission: "对方申请主控权限，是否授权？",
  agree: "同意",
  disagree: "拒绝",
  toSpeaker: "成为主控",
  giveUpedSpeaker: "放弃主控",
  currentNoSpeaker: "当前无主控",
  speakerLogout: "主控已退出",
  speakerleaveRoom: "已离开",
  disagreeSpeakerPermission: "对方拒绝了您的主控申请",
  timeoutSpeakerPermission: "对方未回应",
  userinmeeting: "对方在远程指导中正忙，是否申请加入远程指导？",
  applyToJoin: "申请加入",
  userinmeetingTips: "正在等待对方同意，请稍后...",
  want_to_join_the_meeting: "申请加入当前远程指导，是否同意",
  no_meeting: "对方已拒绝",
  closeShareDesk: "请先关闭共享桌面",
  confirmExit: "确定要退出吗",
  pleaseEnterContent: "请输入内容",
  confirmCloseShareDesk: "确定要关闭桌面共享吗",
  shareDeskOpened: "桌面共享已打开",
  giveUpSpeakerTips: "确定要放弃主控吗",
  applySpeakerSendTips: "主控申请已发送，请耐心等候",
  abnormalLogin: "登录异常或该账户已登录，请退出重新登录",
  hasInvite: "对方申请加入远程指导，是否同意",
  accept: "接受",
  reject: "拒绝",
  agreeInvite: "同意了您的邀请",
  rejectInvite: "对方已拒绝",
  pleaseEnterNickname: "请输入昵称",
  editNickname: "修改昵称",
  nicknameNoNull: "昵称不能为空并且长度不能大于10",
  noNull: "不能为空",
  editNicknameSuccess: "昵称修改成功",
  joinRoom: "已加入",
  leaveRoom: "已离开",
  exit: "退出",
  exitFull: '退出全屏',
  waitingOtherJoin: "正在等待对方接听，请稍后",
  waitingOtherHandle: "正在等待对方处理，请稍后",
  hasCanced: "对方已取消",
  delete: "删除",
  deleteTips: "确定要删除吗",
  deleteSuccess: "删除成功",
  recordFile: "云录制文件",
  recordFileBox: "云录制文件",
  preview: '在线预览',
  record: "录制",
  enter: "进入",
  ignore: "忽略",
  theme: '主题',
  timer: '时间',
  enterRoom: '您预约的远程指导已经开始，是否进入?',
  // 白板
  text: "文本",
  brush: "画笔",
  straight: "直线",
  rectangle: "矩形",
  round: "圆形",
  triangle: "三角形",
  square: "正方形",
  eraser: "橡皮擦",
  empty: "清空",
  revocation: "撤销",
  import: "导入图片",
  download: "下载",
  hasLogin: "该账号已登录",
  // AR操作提示
  AR_F_WIN_OpenMic_Success: "打开麦克风成功",
  AR_F_WIN_OpenMic_Error: "打开麦克风失败",
  AR_F_WIN_CloseMic_Success: "关闭麦克风成功",
  AR_F_WIN_CloseMic_Error: "关闭麦克风失败",
  AR_F_WIN_Rear_Camera_Success: "打开后置相机成功",
  AR_F_WIN_Rear_Camera_Error: "打开后置相机失败",
  AR_F_WIN_AR_Camera_Success: "打开AR相机成功",
  AR_F_WIN_AR_Camera_Error: "打开AR相机失败",
  AR_F_WIN_Thermography_Success: "打开热成像成功",
  AR_F_WIN_Thermography_Error: "打开热成像失败",
  AR_F_WIN_OpenFlash_lamp_Success: "打开闪关灯成功",
  AR_F_WIN_OpenFlash_lamp_Error: "打开闪关灯失败",
  AR_F_WIN_CloseFlash_lamp_Success: "关闭闪关灯成功",
  AR_F_WIN_CloseFlash_lamp_Error: "关闭闪关灯失败",
  AR_F_WIN_Photograph_Success: "拍照成功",
  AR_F_WIN_Photograph_Error: "拍照失败",
  AR_F_WIN_Recording_Success: "打开录制成功",
  AR_F_WIN_Recording_Error: "对方已拒绝",
  AR_F_WIN_OpenThermograph_Success: "打开热力图成功",
  AR_F_WIN_OpenThermograph_Error: "打开热力图失败",
  AR_F_WIN_CloseThermograph_Success: "关闭热力图成功",
  AR_F_WIN_CloseThermograph_Error: "关闭热力图失败",
  AR_F_WIN_Regional_model_Success: "打开区域模式成功",
  AR_F_WIN_Regional_model_Error: "打开区域模式失败",
  AR_F_WIN_Central_mode_Success: "打开中心模式成功",
  AR_F_WIN_Central_mode_Error: "打开中心模式失败",
  AR_F_WIN_OpenMultipoint_temperature_measurement_Success: "打开多点测温成功",
  AR_F_WIN_OpenMultipoint_temperature_measurement_Error: "打开多点测温失败",
  AR_F_WIN_CloseMultipoint_temperature_measurement_Success: "关闭多点测温成功",
  AR_F_WIN_CloseMultipoint_temperature_measurement_Error: "关闭多点测温失败",
  AR_F_WIN_Palette_mode_1_Success: "打开调色一成功",
  AR_F_WIN_Palette_mode_1_Error: "打开调色一失败",
  AR_F_WIN_Palette_mode_2_Success: "打开调色二成功",
  AR_F_WIN_Palette_mode_2_Error: "打开调色二失败",
  AR_F_WIN_Palette_mode_3_Success: "打开调色三成功",
  AR_F_WIN_Palette_mode_3_Error: "打开调色三失败",
  Camera_Zoom_Largen_Success: "放大成功",
  Camera_Zoom_Largen_Error: "放大失败",
  Camera_Zoom_Minish_Success: "缩小成功",
  Camera_Zoom_Minish_Error: "缩小失败",
  AR_Camera_Main_Success: "打开主相机成功",
  AR_Camera_Main_Error: "打开主相机失败",
  AR_Camera_Deputy_Success: "打开副相机成功",
  AR_Camera_Deputy_Error: "打开副相机失败",
  Insta_camera_left_Success: "全景相机向左滚动成功",
  Insta_camera_left_Error: "全景相机向左滚动失败",
  Insta_Camera_Right_Success: "全景相机向右滚动成功",
  Insta_Camera_Right_Error: "全景相机向右滚动失败",
  Insta_Camera_Up_Success: "全景相机向上滚动成功",
  Insta_Camera_Up_Error: "全景相机向上滚动失败",
  Insta_Camera_Down_Success: "全景相机向下滚动成功",
  Insta_Camera_Down_Error: "全景相机向下滚动失败",
  // 设备信息
  equipmentInfo: "设备信息",
  partsInfo: "部件信息",
  faultInfo: "故障信息",
  equipmentName: "设备名称",
  equipmentModel: "设备型号",
  saleCreateTime: "售出时间",
  saleWarrantyTime: "质保有效期至",
  partsName: "部件名称",
  partsModel: "部件型号",
  faultName: "故障名称",
  faultNumber: "故障代码",
  equipmentDetail: "设备描述",
  partsDetail: "部件描述",
  faultDetail: "故障描述",
  equipmentImage: "设备图",
  partsImage: "部件图",
  faultImage: "故障图",
  hasReceived: "对方已收到消息",
  // 全局错误码---------------------------
  // 请求响应状态-state
  SUCCESS: "成功",
  ERROR: "错误",
  SYSTEM_ERROR: "系统错误",
  NO_PERMISSION: "没有权限",
  NO_LOGIN: "没有登录",
  KICK_OUT: "被踢出",
  // 请求响应错误码-msg-账户相关
  ACCOUNT_000001: "用户名或密码错误",
  ACCOUNT_000002: "账户异常",
  ACCOUNT_000003: "平台用户账号是不能够被添加和发起添加联系人",
  ACCOUNT_000004: "账号无法续订",
  ACCOUNT_000005: "账号已登陆",
  ACCOUNT_000006: "同企业用户账号无法添加",
  ACCOUNT_000007: "已是好友无法添加",
  ACCOUNT_000008: "已发起过申请",
  ACCOUNT_000009: "已处理过该申请（已同意或已拒绝过）",
  ACCOUNT_000010: "设备唯一码不合法",
  ACCOUNT_000011: "非本账号外部联系人",
  ACCOUNT_000012: "密码格式不正确",
  ACCOUNT_000013: "账号在该设备上无登录权限",
  ACCOUNT_000014: "当前账号不能添加外部联系人",
  ACCOUNT_000015: "对方账号不能添加外部联系人",
  ACCOUNT_000016: "登录名已存在",
  ACCOUNT_000017: "企业下已有超级管理员",
  // 请求响应错误码-msg-接口请求
  API_000001: "未登录",
  API_000002: "未授权",
  API_000003: "未知接口",
  API_000004: "非法参数",
  API_000005: "不支持的请求方法",
  API_000006: "http体读写失败",
  // 请求响应错误码-msg-数据访问
  DATA_000001: "数据访问异常",
  DATA_000002: "数据不存在",
  DATA_000003: "数据已存在",
  // 请求响应错误码-msg-文件操作
  FILE_000001: "文件大小超限",
  FILE_000002: "创建文件夹失败",
  FILE_000003: "上传失败",
  FILE_000004: "获取文件上传签名失败",
  FILE_000005: "文件上传回调验签失败",
  FILE_000006: "文件上传回调解析参数失败",
  FILE_FAILURE: "文件失效或者过期",
  // 请求响应错误码-msg-其它错误
  OTHER_000001: "未知异常",
  OTHER_000002: "操作失败",
  // 请求响应错误码-msg-设备相关
  EQUIPMENT_000001: "非本企业共享数据不可编辑",
  EQUIPMENT_000002: "设备分组下面有设备不允许删除",
  EQUIPMENT_000003: "设备/部件已经销售不允许删除",
  // 请求响应错误码-msg-销售客户相关
  CUSTOMER_000001: "删除关联客户提示",
  // 请求响应错误码-msg-地址池信息
  POOL_000001: "分配地址池信息 数量不够提示",
  POOL_000002: "池子类型不存在",
  POOL_000003: "设备池子数量不够",
  POOL_000004: "部件池子数量不够",
  POOL_000005: "企业没有销售资源/资质",
  POOL_000006: "企业AR数显信息资源不足",
  POOL_000007: "该设备/部件/故障二维码不存在",
  // 请求响应错误码-msg-EXCEL相关
  EXCEL_000001: "读取excel文件失败",
  EXCEL_000002: "读取excel数据失败",
  // 请求响应错误码-msg-部门相关
  USER_ACCOUNT_GROUP_000001: "部门下含有子部门无法删除",
  USER_ACCOUNT_GROUP_000002: "分组下含有账号无法删除",
  // 请求响应错误码-msg-授权资源
  SERVICE_RESOURCE_000001: "当前账号通话时长余量不足，已停止服务",
  SERVICE_RESOURCE_000002: "不支持的资源计费模式",
  SERVICE_RESOURCE_000003: "未找到授权类型对应的码池类型",
  SERVICE_RESOURCE_000004: "对方账号已停止服务",
  // 请求响应错误码-msg-好视通接口错误
  HST_ERROR_000001: "调取好视通接口失败",
  HST_ERROR_000002: "远程指导录制中",
  HST_ERROR_000003: "房间或远程指导记录不存在",
  HST_ERROR_000004: "云录制存储空间不足",
  // 请求响应错误码-msg-远程指导用户
  ROOM_USER_ACCOUNT_000001: "非远程指导室 用户无法申请主讲",
  ROOM_USER_ACCOUNT_000002: "已经是主讲，无法申请",
  ROOM_USER_ACCOUNT_000003: "当前用户不是主讲",
  ROOM_USER_ACCOUNT_000004: "申请编号错误",
  ROOM_USER_ACCOUNT_000005: "请等待上一次申请结果",
  ROOM_USER_ACCOUNT_000006: "请等待上一次邀请结果",
  ROOM_USER_ACCOUNT_000007: "邀请编号错误",
  ROOM_USER_ACCOUNT_000008: "账号不在线",
  ROOM_USER_ACCOUNT_000009: "当前主讲不是远程指导内的用户",
  ROOM_USER_ACCOUNT_0000010: "用户不是远程指导内的用户",
  ROOM_USER_ACCOUNT_000011: "被邀请者已被他人邀请",
  // 请求响应错误码-msg-远程指导室
  ROOM_000001: "远程指导室不存在",
  ROOM_000002: "远程指导室已解散",
  ROOM_000003: "远程指导室已存在",
  // 联系人类型
  platformContacts: "平台联系人",
  companyContacts: "企业联系人",
  externalContacts: "外部联系人",
  // 请求响应错误码-msg-数据权限
  DATA_PMS_000001: "非本企业二维码",
  // 相机描述
  mainCamera: '主相机',
  secondaryCamera: '副相机',
  AR_CameraText0: "无相机",
  AR_CameraText1: "后置相机",
  AR_CameraText2: "前置相机",
  AR_CameraText3: "主UVC相机",
  AR_CameraText4: "副UVC相机",
  AR_CameraText5: "热成像",
  AR_CameraText6: "360相机",
  // 消息相关
  msgAll: "所有",
  msgCall: "呼叫",
  msgAppointment: "预约",
  msgApply: "申请",
  msgSystem: "系统",
  msgUpload: "传输",
  msgCallIn: "呼入",
  msgCallOut: "呼出",
  msgAgree: "同意",
  msgRefuse: "拒绝",
  APPLIED: "发送申请成功",
  REJECTED: "已拒绝",
  PASS: "已通过",
  TIMEOUTA: "未接通",
  AGREEA: "已接听",
  REFUSEA: "拒绝接听",
  CANCELA: "未接通",
  TIMEOUTB: "未接通",
  AGREEB: "已接听",
  REFUSEB: "未接听",
  CANCELB: "未接通",
  MESSAGE_RECEIVED: "未接通",
  LOCKED: "当前远程指导已锁定，无法加入",
  showDays: "仅显示近7天记录",
  // 预约远程指导
  addRemoteGuides: "预约远程指导",
  addAppointment: "预约远程指导",
  remoteGuide: "远程指导主题",
  remoteCenter: "远程指导中心",
  lookGuide: "显示详情",
  cancelGuide: "取消预约",
  sendRemoteGuide: "邀请您参加远程指导",
  enterGuideAccount: '请输入主题',
  GuideAccountStartDay: '请选择开始日期',
  GuideAccountStartHours: '请选择开始时间',
  GuideAccountendDay: '请选择结束日期',
  GuideAccountendHours: '请选择结束时间',
  addGuideSend: "添加预约指导已发送",
  reserved: '已预约远程指导',
  AppointmentMeeting: "加入远程指导",
  enterAppointmentMeetingID: "请输入远程指导编号或链接",
  enterCorrectAppointmentMeetingID: "请输入正确的远程指导编号或链接",
  remoteGuideCopy: "远程指导主题：",
  remoteGuideDodeCopy: "指导编号：",
  remoteGuideTimeCopy: "预约时间：",
  remoteGuideGMTCopy: "GMT+8:00",
  remoteGuideLinkCopy: "点击链接入会:",
  remoteGuideAccept: "远程指导申请呼入",
  remoteGuideApply: "远程指导申请呼出",
  startTimeGuide: "开始时间",
  endTimeGuide: "结束时间",
  enterTimeGuide: "进入时间",
  quitTimeGuide: "退出时间",
  gudideHour: "小时",
  guideBegin: "开始",
  BeijingTime: "北京时间",
  timeCannot: "预约时间不能大于24小时",
  timeCannotEnd: "结束时间不能小于开始时间",
  timeCannotBegin: "结束时间不能和开始时间相等",
  SupportAPP: '使用AR远程指导软件扫码加入',
  AppDownloadedPc: '提示：APP可在PC客户端下载',
  reserveGuides: '预约的远程指导',
  // 外部联系人
  addOutContacts: "添加外部联系人",
  enterAccount: "请输入用户账号",
  sendApply: "发送申请",
  addGroup: "添加分组",
  enterGroupName: "请输入分组名称",
  moveGroup: "修改分组",
  selectGroup: "选择分组",
  outContactManage: "联系人管理",
  contactNickName: "昵称",
  contactNickAccount: "账号",
  contactNickNote: "备注",
  contactNickGroup: "分组",
  deleteGroup: "删除该分组",
  reName: "重命名",
  deleteContact: "删除联系人",
  delContactConfirm: "确定要删除该联系人吗",
  groupMoveSuccess: "分组移动成功",
  delGroupConfirm: "确定要删除该分组吗",
  groupAddSuccess: "分组添加成功",
  nameEditSuccess: "名称修改成功",
  addContactSend: "发送申请成功",
  notFundContact: "未查找到该用户",
  addContacts: "添加联系人",
  // 云录制状态
  recordStatus1: "初始化",
  recordStatus2: "录制中",
  recordStatus3: "排队等待编码",
  recordStatus4: "编码中",
  recordStatus5: "上传中",
  recordStatus6: "完成",
  recordStatus7: "未获取到状态",
  recordStatus8: "失效",
  recordStatus9: "录制失败",
  "recordStatus-1": "任务异常",
  noLogin: '登录失效，请重新登录',
  zh: '简体中文',
  en: '英语',
  ja: '日语',
  transparentBoard: '空间标记',
  importWhiteBoard: '导入白板',
  downloadImage: '下载图片',
  // 远程指导
  appointmentRemoteMeeting: "邀请参加远程指导",
  appointmentRemoteMeetingPic: '预约远程指导详情',
  guideCodingTheme: '指导主题:',
  guideCodingTime: '预约时间:',
  toStarted: '待开始',
  guideCoding: '指导编号',
  guideCodingUrl: '指导链接',
  mettingLoad: '保存远程指导卡片',
  meetingCopy: '复制远程指导链接',
  mettingLoadSave: '保存卡片',
  mettingLoadQuit: '取消',
  createMetting: "创建预约",
  INVITATION_MEETING_NOT_START_TIME: "远程指导未开始",
  INVITATION_MEETING_HAVE_IN_TIME: "您设定的时间段已存在其他预约，请重新设定",
  INVITATION_MEETING_IS_OVER: "远程指导结束了",
  INVITATION_MEETING_INVALID: "邀请失效",
  INVITATION_MEETING_OVERMAN: "人数超了",
  INVITATION_MEETING_NO_COMPANY: "不是一个企业的",
  INVITATION_MEETING_IS_LOCAK: "房间已锁定",
  PUSH_FILE_IS_READ: "文件已读",
  PUSH_FILE_IS_BACK_OUT: "文件已撤回",
  PARAMETERS_EXCEPTION: "参数异常",
  ANDROID_DEVICE_EXCEPTION: "android设备异常 未授权",
  SYSTEM_ENVIRONMENT_DIF: "系统环境不一致",
  USER_MOT_IN_ROOM: "用户不在远程指导里",

  // 文件上传
  uploadFileTitle: "请选择传送的文件",
  sendUploadFileTitle: "文件发送成功",
  uoloadFileList: "附件列表",
  uploadFileSelect: "添加附件",
  uploadcancel: "取消",
  uploadconfirm: "发送",
  uploadPreview: "预览",
  uploadBackOut: "撤回",
  uploadClose: "关闭",
  uploadTitle: "文件推送",
  uploadTitleText: "收到新的文件",
  uploadIsRead: "是否立即预览？",
  showReadFile: "文件浏览",
  fileSend: "发出",
  fileAccept: "接收",
  fileRecall: "已撤回",
  otherSideFileRecall: "对方已撤回",
  otherSideFileAccept: "对方已接收",
  fileReread: "文件已读",
  fileNodata: "暂无数据",
  startInvite: "发起",
  startInvitePer: "发起人",
  toBeInvited: "受邀",
  my: "我",
  fileName: "文件",
  keepRepair: "可通过APP“扫码报修”功能扫码加入",
  WechatScan: "支持微信扫一扫快速加入",

  // 账号统计数据导出
  accountRemoteSupportTimes: "远程支持时长(分钟)",
  accountRemoteSupportNum: "远程支持次数(次)",
  accountOnLineTimes: "在线时长(分钟)",
  accountRepairOrderNum: "接受到报修工单(个数)",
  accountProcessRepairOrderNum: "已处理报修工单(个数)",
  accountSharedFiles: "已共享文件(个数)",
  yearRemoteSupportTimes: "年度远程支持时长",
  yearRemoteSupportNums: "年度远程支持次数",
  yearRepairOrderNum: "年度处理工单",
  tableMinute: "(分钟)",
  tableNumber: "(次)",
  tableJanuary: '1月',
  tableFebruary: '2月',
  tableMarch: '3月',
  tableApril: '4月',
  tableMay: '5月',
  tableJune: '6月',
  tableJuly: '7月',
  tableAugust: '8月',
  tableSeptember: '9月',
  tableOctober: '10月',
  tableNovember: '11月',
  tableDecember: '12月',
  currentMonth: "本月",
  currentYear: '本年',
  defaultDrouping: '默认分组',
  sendARRecord: '发起AR端录制',
  closeARRecord: '关闭AR端录制',
  Mon: "周一",
  Tues: "周二",
  Wed: "周三",
  Thur: "周四",
  Fri: "周五",
  Sat: "周六",
  Sun: "周日",
  news: "信息",
  Shrink: "收缩",
  Unfold: "展开",
  openWatermark: "开启水印",
  closeWatermark: "关闭水印",
  fileAppName: '文件名：',
  hitdownload: '点击下载',
  COMPANY_ONE_ROOM_ACCOUNTS_OVERSTEP: '超出参会人数限制',
};
