<template>
  <transition name="el-fade-in-linear">
    <div class="whiteBoard-wrap" :style="{backgroundColor: isTransparent ? 'transparent' : '#fff'}">
      <!-- <div class="videoRight">
        <div class="titleBox" @click="playHide">
          <div class="left">{{$t('remoteCenter')}}</div>
          <div :class="isShowpaly ? 'Right' : 'titleImg'"></div>
        </div>
        <video autoplay playsinline id="rightAudio" v-show="isShowpaly"/>
      <div class="titlefoot" v-show="isShowpaly">{{accountId}}</div>
    </div> -->
      <!--画板-->
      <canvas id="whiteBoard" class="whiteBoard" />
      <!--颜色设置-->
      <div class="colorList">
        <div
          v-for="(item, index) in defaultColors"
          :key="index"
          :style="{ backgroundColor: item }"
          :class="{ active: currentDrawColor === item && !colorPicker }"
          @click="setStrokeStyle(item)"
        />
        <div class="colorPicker" :class="{ active: colorPicker }">
          <el-color-picker v-model="colorPicker" @change="changeColorPicker" />
        </div>
      </div>
      <!--线条大小-->
      <div class="slider">
        <el-slider
          v-model="lineWidth"
          vertical
          :max="50"
          :min="1"
          height="200px"
          @change="changeSlider"
        />
      </div>
      <!--画板操作-->
      <div class="boardOptions">
        <div class="lists">
          <el-tooltip :content="$t('brush')" placement="top">
            <div
              class="list"
              :class="{ active: shapeType === 'pencil' }"
              @click="selectShapeType('pencil')"
            >
              <img
                v-if="shapeType === 'pencil'"
                src="./../../assets/pencil-active.png"
                alt=""
              />
              <img v-else src="./../../assets/pencil.png" alt="" />
            </div>
          </el-tooltip>
          <el-tooltip :content="$t('straight')" placement="top">
            <div
              class="list"
              :class="{ active: shapeType === 'line' }"
              @click="selectShapeType('line')"
            >
              <img
                v-if="shapeType === 'line'"
                src="./../../assets/line-active.png"
                alt=""
              />
              <img v-else src="./../../assets/line.png" alt="" />
            </div>
          </el-tooltip>
          <el-tooltip :content="$t('rectangle')" placement="top">
            <div
              class="list"
              :class="{ active: shapeType === 'rect' }"
              @click="selectShapeType('rect')"
            >
              <img
                v-if="shapeType === 'rect'"
                src="./../../assets/rect-active.png"
                alt=""
              />
              <img v-else src="./../../assets/rect.png" alt="" />
            </div>
          </el-tooltip>
          <el-tooltip :content="$t('round')" placement="top">
            <div
              class="list"
              :class="{ active: shapeType === 'circle' }"
              @click="selectShapeType('circle')"
            >
              <img
                v-if="shapeType === 'circle'"
                src="./../../assets/circle-active.png"
                alt=""
              />
              <img v-else src="./../../assets/circle.png" alt="" />
            </div>
          </el-tooltip>
          <el-tooltip :content="$t('triangle')" placement="top">
            <div
              class="list"
              :class="{ active: shapeType === 'triangle' }"
              @click="selectShapeType('triangle')"
            >
              <img
                v-if="shapeType === 'triangle'"
                src="./../../assets/triangle-active.png"
                alt=""
              />
              <img v-else src="./../../assets/triangle.png" alt="" />
            </div>
          </el-tooltip>
          <!-- <el-tooltip :content="$t('square')" placement="top">
            <div
              class="list"
              :class="{ active: shapeType === 'square' }"
              @click="selectShapeType('square')"
            >
              <img
                v-if="shapeType === 'square'"
                src="./../../assets/square-active.png"
                alt=""
              />
              <img v-else src="./../../assets/square.png" alt="" />
            </div>
          </el-tooltip> -->
          <el-tooltip :content="$t('text')" placement="top">
            <div
              class="list"
              :class="{ active: shapeType === 'text' }"
              @click="selectShapeType('text')"
            >
              <img
                v-if="shapeType === 'text'"
                src="./../../assets/text-active.png"
                alt=""
              />
              <img v-else src="./../../assets/text.png" alt="" />
            </div>
          </el-tooltip>
          <el-tooltip :content="$t('revocation')" placement="top">
            <div class="list" @click="backOut">
              <img
                v-if="shapeType === 'backOut'"
                src="./../../assets/backOut-active.png"
                alt=""
              />
              <img v-else src="./../../assets/backOut.png" alt="" />
            </div>
          </el-tooltip>
          <el-tooltip :content="$t('empty')" placement="top">
            <div class="list" @click="clearRect">
              <img
                v-if="shapeType === 'empty'"
                src="./../../assets/empty-active.png"
                alt=""
              />
              <img v-else src="./../../assets/empty.png" alt="" />
            </div>
          </el-tooltip>
<!--          <el-tooltip :content="$t('eraser')" placement="top">-->
<!--            <div-->
<!--              class="list"-->
<!--              :class="{ active: shapeType === 'eraser' }"-->
<!--              @click="selectShapeType('eraser')"-->
<!--            >-->
<!--              <img-->
<!--                v-if="shapeType === 'eraser'"-->
<!--                src="./../../assets/eraser-active.png"-->
<!--                alt=""-->
<!--              />-->
<!--              <img v-else src="./../../assets/eraser.png" alt="" />-->
<!--            </div>-->
<!--          </el-tooltip>-->
          <el-tooltip
            v-if="isShowSpace"
            :content="$t('import')"
            placement="top"
          >
            <div class="list">
              <el-upload
                action=""
                accept="image/*"
                :before-upload="beforeUpload"
              >
                <img src="./../../assets/import.png" alt="" />
              </el-upload>
            </div>
          </el-tooltip>
          <el-tooltip :content="$t('download')" placement="top" v-if="isShowSpace">
            <div
              class="list"
              :class="{ active: shapeType === 'save' }"
              @click="saveCanvas"
            >
              <img src="./../../assets/save.png" alt="" />
            </div>
          </el-tooltip>
          <el-tooltip :content="$t('exit')" placement="top">
            <div class="list" @click="exitWhiteBoard">
              <img src="./../../assets/exit.png" alt="" />
            </div>
          </el-tooltip>
        </div>
      </div>
      <!--文本框处理-->
      <div id="textWrap">
        <div class="textInput" id="textInput" @mousedown="inputMousedown">
          <div
            contenteditable="true"
            id="textString"
            @input="textInputChange"
          ></div>
        </div>
      </div>
      <!--图片处理-->
      <img
        v-if="defaultUploadImg"
        class="defaultUploadImg"
        id="defaultUploadImg"
        :src="defaultUploadImg"
        alt=""
      />
    </div>
  </transition>
</template>

<script>
import Draw from './draw'
export default {
  name: 'whiteBoard',
  props: {
    initShowImgUrl: {
      // 画板初始化展示的图片
      type: String,
      default: '',
    },
    // 白板是否透明
    isTransparent: {
      type: Boolean,
      default: false
    },
    // 空间标记空间控制
    isShowSpace: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      lineWidth: 3, // 线条宽度
      currentDrawColor: '#ff3333', // 线条颜色
      historyData: [], // 操作历史数据
      defaultColors: [
        // 默认显示的颜色列表
        '#ff3333',
        '#000000',
        '#999999',
        '#0066ff',
        '#ffff33',
        '#33CC66',
      ],
      colorPicker: '', // 选择颜色
      shapeType: 'pencil', // 当前选择的绘制类型
      defaultUploadImg: this.initShowImgUrl, // 标记图
      zoomScal: 1,
      isShowpaly: true
    }
  },
  mounted() {
    this.init()
    // this.callCamera()
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.onmousemove)
    window.removeEventListener('mouseup', this.onmousemove)
    window.removeEventListener('mouseleave', this.onmousemove)
  },
  methods: {
    // 画板初始化
    init() {
      // 缩放兼容画板
      const docHeight = document.body.clientHeight
      const docWidth = document.body.clientWidth
      if (docWidth < 1680) {
        this.zoomScal = docHeight / 1080
      }
      console.log(this.zoomScal)

      // 初始化canvas和尺寸
      this.clientWidth = document.documentElement.clientWidth / this.zoomScal
      this.clientHeight = document.documentElement.clientHeight / this.zoomScal
      this.whiteBoard = document.getElementById('whiteBoard')
      this.textInput = document.getElementById('textInput')
      this.textString = document.getElementById('textString')
      this.whiteBoard.width = this.clientWidth
      this.whiteBoard.height = this.clientHeight
      this.ctx = this.whiteBoard.getContext('2d')
      // 如果有默认图片的话，这边开始画
      this.draw = new Draw(this.ctx, this.clientWidth, this.clientHeight)
      // 注册绘图事件
      this.whiteBoard.onmousedown = this.onmousedown
      window.addEventListener('mousemove', this.onmousemove)
      window.addEventListener('mouseup', this.onmouseup)
      // 禁用右键
      this.whiteBoard.oncontextmenu = () => false
      this.textInput.oncontextmenu = () => false
    },
    callCamera() {
      const options = {
        width: 200,
        height: 150,
        frameRate: 25,
        bitRate: 0
      }
      const MediaType = this.hstRtcEngine.MediaType;
      if (MediaType) {
        this.hstRtcEngine.setMediaRender(
          this.accountId,
          MediaType.VIDEO,
          this.currentCamId,
          document.getElementById('rightAudio'),
          options
        );
      }
    },
    playHide() {
      this.isShowpaly = !this.isShowpaly
    },
    // 上传
    beforeUpload(file) {
      this.ctx.clearRect(0, 0, this.clientWidth, this.clientHeight)
      this.defaultUploadImg = window.URL.createObjectURL(file)
      this.historyData = []
      return false
    },
    // 监听文本框实时输入
    textInputChange(e) {
      const textInput = document.getElementById('textInput')
      const { offsetLeft, clientWidth } = textInput
      if (offsetLeft + clientWidth >= this.clientWidth - 10) {
        e.target.style.width = this.clientWidth - offsetLeft - 10 + 'px'
      }
    },
    // 绘制文字
    drawText() {
      if (this.textString.innerHTML) {
        this.setHistoryData()
        this.ctx.beginPath()
        this.ctx.fillStyle = this.cacheColor
        this.ctx.font = `${this.cacheFontSize}px 微软雅黑`
        const nodes = Array.prototype.slice.call(this.textString.childNodes)
        let nextNodeHeight = 0
        nodes.forEach((node, index) => {
          console.log(node, index)
          const text = node.nodeType === 3 ? node.nodeValue : node.innerText
          if (text) {
            let nodeHeight = this.cacheFontSize * 1.5
            if (!index) nodeHeight = nodeHeight * 0.8888888888888888
            nextNodeHeight += nodeHeight
            this.ctx.fillText(
              text,
              this.textInput.offsetLeft + 6,
              this.textInput.offsetTop + nextNodeHeight
            )
          }
        })
        this.textString.innerHTML = ''
      }
    },
    // 改变文字大小
    changeSlider() {
      this.drawText()
      this.textInput.style.display = 'none'
    },
    // 鼠标按下事件
    onmousedown(e) {
      this.x1 = e.pageX
      this.y1 = e.pageY
      // 文本框处理
      if (this.shapeType === 'text') {
        const fontSize = this.lineWidth >= 12 ? this.lineWidth : 12
        this.drawText()
        this.textInput.style.display = 'block'
        this.textString.style.width = 'auto'
        // this.textInput.style.left = e.pageX + 'px'
        // this.textInput.style.top = e.pageY + 'px'
        this.textInput.style.left = e.pageX / this.zoomScal + 'px'
        this.textInput.style.top = e.pageY / this.zoomScal + 'px'
        this.textInput.style.fontSize = fontSize + 'px'
        this.cacheFontSize = fontSize
        this.textInput.style.color = this.currentDrawColor
        this.cacheColor = this.currentDrawColor
      } else {
        this.isDrawing = true
        this.ctx.lineWidth = this.lineWidth
        this.ctx.strokeStyle = this.currentDrawColor
        this.ctx.fillStyle = this.currentDrawColor
        this.setHistoryData()
      }
    },
    // 鼠标移动事件
    onmousemove(e) {
      //  console.log(e)
      if (this.shapeType === 'text' && this.inputIsDrawing) {
        const moveX = e.pageX - this.startPageX
        const moveY = e.pageY - this.startPageY
        // 获取移动距离
        let newLeft = this.oldLeft + moveX
        let newTop = this.oldTop + moveY
        // 边界检测
        if (newLeft < 0) newLeft = 0
        if (newTop < 0) newTop = 0
        if (newLeft > this.clientWidth - this.oldWidth) {
          newLeft = this.clientWidth - this.oldWidth
        }
        if (newTop > this.clientHeight - this.oldHeight) {
          newTop = this.clientHeight - this.oldHeight
        }
        if (newLeft !== 5 && newTop !== 5) {
          this.textInput.style.left = +newLeft.toFixed(0) + 'px'
          this.textInput.style.top = +newTop.toFixed(0) + 'px'
        }
        this.textString.style.width = 'auto'
        return
      }
      if (this.isDrawing) {
        this.x2 = e.pageX
        this.y2 = e.pageY
        // 不是铅笔和橡皮擦
        if (this.shapeType !== 'pencil' && this.shapeType !== 'eraser') {
          this.ctx.clearRect(0, 0, this.clientWidth, this.clientHeight)
          if (this.historyData.length) {
            this.ctx.drawImage(
              this.historyData[0],
              0,
              0,
              this.clientWidth,
              this.clientHeight
            )
          }
        }
        // 铅笔和橡皮擦 this.zoomScal 兼容缩放率
        this.draw[this.shapeType](
          this.x1 / this.zoomScal,
          this.y1 / this.zoomScal,
          this.x2 / this.zoomScal,
          this.y2 / this.zoomScal,
          this.lineWidth / this.zoomScal
        )
        if (this.shapeType === 'pencil' || this.shapeType === 'eraser') {
          this.x1 = this.x2
          this.y1 = this.y2
        }
      }
    },
    // 鼠标抬起事件
    onmouseup() {
      this.isDrawing = false
      this.inputIsDrawing = false
    },
    // 文本框按下事件
    inputMousedown(e) {
      this.inputIsDrawing = true
      this.startPageX = e.pageX
      this.startPageY = e.pageY
      const { clientWidth, clientHeight, offsetLeft, offsetTop } = e.target
      this.oldLeft = offsetLeft
      this.oldTop = offsetTop
      this.oldWidth = clientWidth
      this.oldHeight = clientHeight
      return false
    },
    // 设置线条颜色
    setStrokeStyle(item) {
      this.drawText()
      this.textInput.style.display = 'none'
      this.colorPicker = ''
      this.currentDrawColor = item
    },
    // 选择线条颜色
    changeColorPicker(e) {
      this.drawText()
      this.textInput.style.display = 'none'
      if (!e) return
      this.currentDrawColor = e.toLowerCase()
    },
    // 选择功能按钮
    selectShapeType(shapeType) {
      console.log('shapeType', shapeType)
      if (shapeType !== 'text') {
        this.drawText()
        this.textInput.style.display = 'none'
      }
      this.shapeType = shapeType
    },
    // 清空画布
    clearRect() {
      if (this.shapeType === 'text') {
        this.textInput.style.display = 'none'
        this.textString.innerHTML = ''
      }
      this.historyData = []
      this.ctx.clearRect(0, 0, this.clientWidth, this.clientHeight)
    },
    // 保存撤销数据
    setHistoryData() {
      const base64 = this.whiteBoard.toDataURL('image/png')
      const img = new Image()
      img.width = this.clientWidth
      img.height = this.clientHeight
      img.src = base64
      this.historyData.unshift(img)
    },
    // 撤销
    backOut() {
      // 清空文本框
      if (this.shapeType === 'text') {
        this.textInput.style.display = 'none'
        this.textString.innerHTML = ''
      }
      // 撤销
      if (this.historyData.length <= 0) return
      this.ctx.clearRect(0, 0, this.clientWidth, this.clientHeight)
      this.ctx.drawImage(
        this.historyData[0],
        0,
        0,
        this.clientWidth,
        this.clientHeight
      )
      this.historyData.shift()
    },
    // 保存画布
    saveCanvas() {
      this.drawText()
      this.textInput.style.display = 'none'
      // 处理标记图片
      if (this.defaultUploadImg) {
        const img = new Image()
        img.src = this.defaultUploadImg
        img.onload = () => {
          // 新的画板
          const newCanvas = document.createElement('canvas')
          newCanvas.width = this.clientWidth
          newCanvas.height = this.clientHeight
          const newCtx = newCanvas.getContext('2d')
          // 原来画板数据
          const whiteBoardImgUrl = this.whiteBoard.toDataURL('image/png')
          const whiteBoardImg = new Image()
          whiteBoardImg.src = whiteBoardImgUrl
          whiteBoardImg.onload = () => {
            // 重新绘图
            const imgEle = document.getElementById('defaultUploadImg')
            const { offsetLeft, offsetTop, clientWidth, clientHeight } = imgEle
            console.log(offsetLeft)
            console.log(offsetTop)
            console.log(imgEle)
            newCtx.drawImage(
              img,
              offsetLeft - clientWidth / 2,
              offsetTop,
              clientWidth,
              clientHeight
            )
            newCtx.drawImage(
              whiteBoardImg,
              0,
              0,
              this.clientWidth,
              this.clientHeight
            )
            const imgUrl = newCanvas.toDataURL('image/png')
            this.saveImg(imgUrl)
          }
        }
      } else {
        const imgUrl = this.whiteBoard.toDataURL('image/png')
        this.saveImg(imgUrl)
      }
    },
    // 保存图片
    saveImg(imgUrl) {
      const saveA = document.createElement('a')
      document.body.appendChild(saveA)
      saveA.href = imgUrl
      saveA.download = this.$utils.format(new Date(), 'yyyy-MM-dd hh_mm_ss')
      saveA.target = '_blank'
      saveA.click()
    },
    // 退出白板
    exitWhiteBoard() {
      this.drawText()
      this.textInput.style.display = 'none'
      this.$emit('exitWhiteBoard')
    },
  },
}
</script>

<style lang="scss">
.whiteBoard-wrap {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1200;
  background-color: #fff;
  user-select: none;
  overflow: hidden;

  .whiteBoard {
    position: absolute;
    z-index: 1;
  }
  .videoRight{
    position: absolute;
    top: 0;
    z-index: 5;
    right: 5px;
    bottom: 0;
    width: 200px;
    height: 1px;
    background: red;
    .titleBox{
      background: #000;
      padding: 5px;
      width: 190px;
      height: 25px;
      cursor: pointer;
      .left{
        color: #fff;
        width: 150px;
        float: left;
        height: 25px;
        line-height: 25px;
      }
      .Right{
        float:right;
        padding-top: 15px;
        padding-right:25px;
        height: 13px;
        background: url('../../views/index/img/leftnumenone.png') no-repeat center center;
        background-size: 13px 8px ;
      }
      .titleImg{
        float: right;
        padding-top: 15px;
        padding-right:25px;
        height: 13px;
        background: url('../../views/index/img/upplay.png') no-repeat center center;
        background-size: 13px;
      }
    }
    .titlefoot{
      background: #000;
      padding-left: 5px;
      width: 195px;
      height: 20px;
      color: #fff;
      line-height: 20px;
      margin-top: -5px;
    }
  }
  .defaultUploadImg {
    position: absolute;
    left: 50%;
    top: 5%;
    transform: translate(-50%, 0);
    height: 80%;
    -webkit-user-select: none;
    cursor: default;
    pointer-events: none;
  }

  .textInput {
    position: absolute;
    z-index: 2;
    padding: 5px;
    border: 1px dashed #999999;
    cursor: move;
    display: none;
    box-sizing: border-box;

    > div {
      border: none;
      outline: none;
      cursor: auto;
      min-width: 20px;
      line-height: 1.5;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .colorList {
    position: absolute;
    left: 30px;
    top: 45%;
    z-index: 9;
    transform: translateY(-50%);

    > div {
      width: 30px;
      height: 30px;
      margin: 10px 0;
      border-radius: 50%;
      box-sizing: border-box;
      border: 3px solid white;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
      cursor: pointer;
      transition: 0.3s;

      // &:hover,
      // &.active {
      //   box-shadow: 0 0 20px #409eff;
      // }
    }
  }

  .slider {
    position: absolute;
    top: 46%;
    right: 30px;
    z-index: 9;
    transform: translateY(-50%);
  }

  .boardOptions {
    position: absolute;
    bottom: 100px;
    left: 0;
    right: 0;
    z-index: 9;
    text-align: center;
    height: 0;
    min-width: 700px;

    .lists {
      display: inline-block;

      img {
        width: 30px;
        height: 30px;
        margin: 9px;
        vertical-align: top;
      }

      .list {
        display: inline-block;
        width: 50px;
        height: 50px;
        margin: 3px;
        border-radius: 50%;
        background: #fff;
        box-sizing: border-box;
        border: 1px solid #9F9F9F;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        transition: 0.3s;

        // &:hover,
        // &.active {
        //    box-shadow: 0 0 20px #409eff;
        // }
      }
    }
  }

  .colorPicker {
    .el-color-picker,
    .el-color-picker__trigger,
    .el-color-picker__color,
    .el-color-picker__color-inner {
      width: 24px;
      height: 24px;
      margin: 0;
      padding: 0;
      border: none;
      border-radius: 50%;
    }
  }
}
</style>
