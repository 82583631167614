import AgoraRTC from "agora-rtc-sdk-ng"

AgoraRTC.enableLogUpload()
AgoraRTC.setLogLevel(1)

export const agoraCameraRtcEngine = AgoraRTC.createClient({
  mode: "rtc",
  codec: "vp8"
});
export const agoraScreenRtcEngine = AgoraRTC.createClient({
  mode: "rtc",
  codec: "vp8"
})
