<template>
  <div class="box">
    <p>{{deviceName}} </p>
    <p>{{referUrl}}</p>
  </div>
</template>
<script>
import device from "current-device";
export default {
  data() {
    return {
      deviceName: "",
      url: this.$route.fullPath,
      urlAll: window.location.href,
      referUrl: ''
    };
  },

  mounted() {
    this.currentDev();
  },
  methods: {
    currentDev() {
      this.referUrl = this.$utils.getLocalStorage("referUrl")
      if (this.referUrl) {
        if (device.mobile()) {
          this.deviceName = "移动手机端";
        } else if (device.ipad) {
          this.deviceName = "ipad";
          this.$router.push({ path: "/" });
        } else if (device.ios) {
          this.deviceName = "ios";
        } else if (device.desktop) {
          this.deviceName = "电脑";
        }
      }
    },
  },
};
</script>

<style lang="scss">
.box {
  width: 600px;
  margin: 0 auto;
  height: 600px;
  padding-top: 40px;
}
</style>