import Vue from "vue";
import VueI18n from "vue-i18n";
import { getLocalStorage, setLocalStorage } from "./../helpers/utils";
import zhLocale from "./zh";
import enLocale from "./en";
import jaLocale from "./ja";
import elementZhLocale from "element-ui/lib/locale/lang/zh-CN";
import elementEnLocale from "element-ui/lib/locale/lang/en";
import elementJaLocale from "element-ui/lib/locale/lang/ja";

const locale = getLocalStorage("lang") || "zh";
setLocalStorage("lang", locale);

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: locale, // set locale，默认中文
  messages: {
    zh: {
      ...zhLocale,
      ...elementZhLocale
    },
    en: {
      ...enLocale,
      ...elementEnLocale
    },
    ja: {
      ...jaLocale,
      ...elementJaLocale
    }
  }
});
export default i18n;
