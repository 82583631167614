export default {
  encoderConfig: '720p_1',
  // 相机的配置
  customEncoderConfig: {
    bitrateMax: 4780,
    bitrateMin: 2080,
    frameRate: { // 帧率
      max: 30, min: 15
    },
    height: 1080,
    width: 1920,
  },
  encoderScreenConfig: '1080p_1',
  // 共享桌面配置
  customScreenEncoderConfig: {
    bitrateMax: 4780,
    bitrateMin: 2080,
    frameRate: {
      max: 30, min: 25
    },
    height: 1080,
    width: 1920,
  },
  lowStreamConfig: {
    bitrate: 400,
    framerate: { // 帧率
      max: 15, min: 10
    },
    height: 360,
    width: {
      max: 640, min: 360
    },
  },
  optimizationMode: 'motion', // detail 清晰度优先 motion 流畅优先
  screenOptimizationMode: 'detail', // detail 清晰度优先 motion 流畅优先
  mirror: false, // 视频镜像
  fit: 'contain',
  // 声网私有云配置
  privateCloudConfig: {
    log: {},
    report: {},
    accessPoints: {
      serverList: ['private.rtcdeveloper.com'],
      domain: 'rtcdeveloper.com',
    },
  }
}
