<template>
  <el-dialog
      top="15vh"
      v-if="value"
      class="baseDialog"
      :meetingList="meetingList"
      :width="width"
      :title="title"
      :isshowReserved="isshowReserved"
      :center="center"
      :append-to-body="true"
      :visible.sync="value"
      :show-close="showClose"
      :before-close="close">
    <div class="dialog-content" :style="{height: height}">
      <slot/>
    </div>
    <div slot="footer" class="dialog-footer" v-if="showFooter">
      <slot name="footer">
        <el-button @click="close" size="small">{{$t('cancel')}}</el-button>
        <el-button type="primary" v-if="showConfirm" @click="confirm" size="small">{{$t('confirm')}}</el-button>
      </slot>
    </div>
    <!--查看预约子弹窗-->
    <el-dialog
      v-if="innerVisible"
      top="15vh"
      width="510px"
      v-model="pageID"
      class="metingDialog"
      :modal="false"
      :title="$t('appointmentRemoteMeeting')"
      :visible.sync="innerVisible"
      >
        <el-row>
          <el-col :span="3"><div class="titleTxt floatRight">{{$t('startInvitePer')}}:</div></el-col>
          <el-col :span="21"><div class="titleCont txtColor">{{companyName}}<span>({{nickname}})</span></div></el-col>
        </el-row>
        <el-row>
          <el-col :span="3"><div class="titleTxt">{{$t('guideCodingTheme')}}</div></el-col>
          <el-col :span="21"><div class="titleCont">{{theme}}</div></el-col>
        </el-row>
        <el-row>
          <el-col :span="3"><div class="titleTxt">{{$t('guideCodingTime')}}</div></el-col>
          <el-col :span="21"><div class="titleCont">{{meetingTimeStart | dateFilter}} {{meetingTimeStart | hoursFilter}} - {{meetingTimeEnd | dateFilter}} {{meetingTimeEnd | hoursFilter}}<span style="float:right;margin-right:15px;">GMT 8:00</span></div></el-col>
        </el-row>
        <el-row>
          <el-col :span="3"><div class="titleTxt">{{$t('guideCoding')}}:</div></el-col>
          <el-col :span="20"><div class="titleCont txtColor">{{$utils.replaceThree(meetingID)}}</div></el-col>
        </el-row>
        <el-row>
          <el-col :span="3"><div class="titleTxt">{{$t('guideCodingUrl')}}:</div></el-col>
          <el-col :span="21"><div class="titleCont txtColor">{{URl}}</div></el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="watchCode">
              <div class="qrcode" ref="qrCodeUrl" xss=removed></div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="footerBtnGuid">
              <el-button plain size="mini" @click="loadPic">{{$t('mettingLoad')}}</el-button>
              <el-button type="primary" size="mini" :data-clipboard-text="guidePicData" class="copyBtn" @click="copyContent">{{$t('meetingCopy')}}</el-button>
            </div>
          </el-col>
        </el-row>
        <!-- 图片 -->
        <div class="pcAppoint" ref="imageWrapper">
          <div class="guidePicLoad">
            <div class="titleTop">{{$t('appointmentRemoteMeetingPic')}}</div>
            <div class="titleTop">{{companyName}}{{$t('reserveGuides')}}</div>
            <div class="titleFoot ">【{{theme}}】</div>
            <div class="timer">
              <div class="tleft">
                <p class="one">{{meetingTimeStart | hoursFilter}}</p>
                <p class="two">{{meetingTimeStart | dateFilter}}</p>
              </div>
              <div class="tcenter">
                <p class="one">{{$t('toStarted')}}</p>
                <p class="two"><el-divider>{{meetingHours}}{{$t('gudideHour')}}</el-divider></p>
                <p class="three">(GMT +08:00)</p>
              </div>
              <div class="tleft">
                <p class="one">{{meetingTimeEnd | hoursFilter}}</p>
                <p class="two">{{meetingTimeEnd | dateFilter}}</p>
              </div>
            </div>
            <div class="line"></div>
            <div class="guideid">
              {{$t('guideCoding')}}：{{$utils.replaceThree(meetingID)}}
            </div>
            <div class="guidePIcWatch">
              <div class="qrcode" ref="inLoadHtml" xss=removed></div>
            </div>
            <div class="tfoot">
              {{ $t('keepRepair') }}
            </div>
            <div class="ttip">
              {{ $t('AppDownloadedPc') }}
            </div>
          </div>
        </div>
    </el-dialog>

    <div class="showReserved" v-show="isshowReserved">
        <div class="reserved">{{$t('reserved')}}</div>
        <div class="guideContent" v-for="item in meetingList" :key="item.id">
          <div class="guideContentLeft">
            <div class="guideContentLeftTop"> {{item.startTime | dateFilter}}  {{ $t('BeijingTime') }}<span>{{item.startTime | hoursFilter}}</span>{{ $t('guideBegin') }}</div>
            <div class="guideContentLeftBotm">{{item.theme}}</div>
          </div>
          <div class="guideContentRight">
            <div class="guideContentRightTop"> {{$utils.replaceThree(item.roomIntegerNumber)}}</div>
            <div class="guideContentListBox">
              <div class="guideListItem">
                <div class="guideListItemTitle" @click="enterMetting(item.roomIntegerNumber)">{{$t('enter')}}</div>
                <div class="guideListItemBoxIcon" ref="showDropMore" @click.stop="getDropdownShow(item.id)"><img src="../../views/index/img/arrowdown.png" /></div>
                <div class="dropdownBox" v-if="dropdownShow && getdropId === item.id" :id="'drop'+ item.id">
                  <p @click="innerVisibleCom(item.id)"><span style="color:#000000">{{ $t('lookGuide') }}</span></p>
                  <p @click="cancelMeeting(item.id)"><span style="color:#FF4D4F">{{ $t('cancelGuide') }}</span></p>
                </div>
              </div>
              <!-- <el-dropdown split-button type="primary" placement="bottom-end" size="mini" @click="enterMetting(item.roomIntegerNumber)">
                {{$t('enter')}}
                <el-dropdown-menu slot="dropdown" :append-to-body="false" class="rightdropdown">
                  <el-dropdown-item :command="item.id" @click.native="innerVisibleCom(item.id)"><span style="color:#000000">{{ $t('lookGuide') }}</span></el-dropdown-item>
                  <el-dropdown-item :command="item.id" @click.native="cancelMeeting(item.id)"><span style="color:#FF4D4F">{{ $t('cancelGuide') }}</span></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown> -->
            </div>
          </div>
        </div>
    </div>
  </el-dialog>
</template>

<script>
import Index from './_index.js';
export default Index;
</script>

<style lang="scss">
  @import "./_index.scss";
  .metingDialog{
    font-size: 13px;
    line-height: 25px;
    .floatRight{
      float: right;
    }
    .titleTxt{
      box-sizing: border-box;
    }
    .titleCont{
      margin-left: 10px;
    }

    .footerBtnGuid{
      text-align: center;
    }
    .watchCode{
      text-align: center;
    }
    .txtColor{
      color: #0B81E0;
    }
    .qrcode{
        display: inline-block;
        padding: 10px;
        img {
          width: 150px;
          height: 150px;
          background-color: #fff; //设置白色背景色
          // padding: 6px; // 利用padding的特性，挤出白边
          box-sizing: border-box;
          }
        }
  }
  .pcAppoint {
    margin-top:-9999999px;
    text-align: center;
    background: url('../../assets/bg2.png') no-repeat;
    background-size: 750px 1334px;
    width: 750px;
    height: 1334px;
    position: relative;
    .guidePicLoad {
      padding-top:48px;
      text-align: center;
    .titleTop{
      width: 100%;
      height: 59px;
      font-size: 42px;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 59px;
    }
    .titleFoot{
      margin-top: 80px;
      text-align: center;
      height: 56px;
      font-size: 40px;
      font-weight: 600;
      color: #000000;
      height: 56px;
    }
    .company{
     margin-bottom: 5px;
    }
    .timer{
      width: 600px;
      display: flex;
      border-radius: 10px;
      -moz-border-radius: 10px;
      -webkit-border-radius: 10px;
      text-align: center;
      margin-left: 75px;
      box-sizing: border-box;
      div{
        flex: 1;
        flex-wrap: wrap;
      }
      .tleft {
        .one{
          width: 100%;
          height: 95px;
          font-size: 68px;
          font-weight: 500;
          color: #000000;
          line-height: 95px;
        }
        .two{
          height: 37px;
          font-size: 26px;
          font-weight: 400;
          color: #000000;
          line-height: 37px;
        }
      }

      .tcenter{
        font-size: 26px;
        .one {
          margin-top: 15px;
          color: chocolate;
        }
        .two {
          margin-top: 10px;
          .el-divider__text{
            width: 85px;
            font-size: 22px;
            color: #6E818B;
          }
        }
        .three{
          margin-top: 30px;
        }
        .el-divider--horizontal {
          margin: 25px 0px 0px 8px !important;
        }
      }
    }
    .line{
      width: 616px;
      height: 3px;
      background: #F7F7F7;
      border-radius: 20px;
      margin: 30px auto;
    }
    .guideid {
      width: 397px;
      height: 50px;
      font-size: 36px;
      font-weight: 400;
      color: #232531;
      line-height: 50px;
      text-align: center;
      margin: 0px auto;
    }
    p {
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
    .guidePIcWatch{
      width: 100%;
      height: 360px;
      text-align: center;
      padding: 10px 10px 10px 10px;
      .qrcode{
        display: inline-block;
        img {
          width: 360px;
          height: 360px;
          background-color: #fff; //设置白色背景色
          padding: 6px; // 利用padding的特性，挤出白边
          box-sizing: border-box;
          border: 1px solid #DCDFE6;
          }
        }
    }
    .tfoot {
       line-height: 45px;
       padding: 10px;
       height: 45px;
       font-size: 27px;
       font-weight: 500;
       color: #232531;
    }
    .ttip{
      height: 33px;
      font-size: 22px;
      font-weight: 400;
      color: #6E818B;
      line-height: 33px;
      margin: 0px auto;
    }
  }
  }
  .footerPic {
    text-align: center;
    margin: 0 auto;
  }
  .showReserved{
    padding: 0px 15px 0px 15px;
    margin-top: 10px;
    text-align: center;
    .reserved {
      width: 95%;
      margin: 0 auto;
      line-height: 35px;
      border-top: 1px dashed #eee ;
    }
    .guideContent {
      box-sizing:border-box;
      width: 100%;
      border: 1px solid #eee;
      border-radius:5px;
      padding: 10px 8px;
      margin-bottom: 10px;
      box-shadow:0px 0px 5px #F7F7F7;
      &::after{
        content: '';
        display: block;
        clear: both;
      }
      .guideContentLeft{
        width: 70%;
        float: left;
        line-height: 30px;
        .guideContentLeftTop{
          width: 100%;
          text-align: left;
          font-size: 13px;
          color: #83939C;
          span{
            color: #0B81E0;
          }
        }
        .guideContentLeftBotm{
          width: 100%;
          text-align: left;
          font-size: 14px;
          font-weight: 600;
        }
      }
      .guideContentRight{
        width: 25%;
        float: right;
        line-height: 30px;
        .guideContentListBox{
         .rightdropdown{
           margin-left:50px;
         }
          .guideListItem{
            width: 90px;
            background: #0B81E0;
            color: #fff;
            height: 30px;
            border-radius: 5px;
            position: relative;
            .dropdownBox{
              position:absolute;
              right: -30px;
              top:-50px;
              z-index: 999;
              border-radius: 5px;
              width: 85px;
              background: #ffffff;
              box-shadow: 0px 0px 6px 0px rgba(110, 129, 139, 0.3);
              p{
                cursor: pointer;
                font-size: 14px;
                &:hover{
                  background: #F7F7F7;
                }
              }
            }
            .guideListItemTitle{
              width: 60px;
              float: left;
              cursor: pointer;
            }
            .guideListItemBoxIcon{
              width: 28px;
              float: right;
              border-left: 1px solid #fff;
              cursor: pointer;
              img{
                vertical-align: middle;
              }
            }
          }
        }
        .guideContentRightTop{
          width: 100%;
          color: #83939C;
          font-size: 13px;
        }
        .guideContentRightBotm{
          width: 100%;
        }
      }

      .guideContentTitle {
        margin-top: 15px;
        font-size: 16px;
        line-height: 25px;
        width: 100%;
        &::after{
        content: '';
        display: block;
        clear: both;
      }
        .guideContentTitleLeft{
          overflow: hidden;
          float: left;
          width: 70%;
        }
        .guideContentTitleRight{
          float: right;
          width: 25%;
        }
        div{
          flex: 1;
          flex-wrap: wrap;
          justify-content: space-around;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
     }
    }
  }
</style>
