/**
 * 路由表
 * */
import Login from './../views/login';
import Index from './../views/index';
import Invitaion from './../views/invitation';

export default [
  {
    path: '/',
    component: Index,
  },
  {
    path: '/login',
    name: "login",
    component: Login
  },
  {
    path: '/meeting/invitation/:id', // /home/${id}
    name: 'invitation',
    meta: {
      requireAuth: true
    },
    component: Invitaion
  }
];
