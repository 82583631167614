// 中文
export default {
  // 基础
  cancel: "止めになる",
  confirm: "の先を打ち曲げる",
  hangUp: "電話を切る",
  know: "の先を打ち曲げる",
  apply: "申し込み",
  state: "ステータスコードです",
  loaing: "ロード中です...",
  more: "さらに多く",
  // 登录页
  welcomeUse: "ご利用を歓迎します",
  aRAfterSale: "専門家によるオンライン指導システムです",
  loginWelcome: "AR遠隔指導システムのご利用を歓迎します",
  account: "ユーザー名の入力をお願いします",
  savePassWord: "パスワードを保存します",
  autoLogin: "自動登録です",
  password: "あんごうふごう",
  login: "ログオンする",
  accountType: "アカウントの種類が間違っています",
  aboutUs: "私たちについてです",
  contactUs: "連絡してきます",
  technicalSupport: "技術サポート",
  copyright: "© 2017-2022 ティム維澳(上海)網路技術有限公司著作権所有です",
  languageSwitch: "言語を切り替えます",
  accountNoEmpty: "アカウントを空にすることはできません。",
  passwordNoEmpty: "パスワードは空にできません。",
  accountNoCorrect: "アカウントが正しくありません。",
  passwordNoCorrect: "パスワードが正しくありません。",
  accountOrPasswordError: "アカウントやパスワードが間違っています",
  networkError: "インターネット接続が切れました",
  networkCatch: "サーバー異常です",
  // 工具
  tool: "いんぷりめんと",
  searchServiceOrder: "サービスリスト照会です",
  orderId: "作業明細IDです",
  orderIdPlaceholder: "作業明細IDの入力をお願いします。",
  noStart: "始まりません",
  noEnd: "終わりません",
  startTime: "サービス開始時間です",
  endTime: "サービス終了時間です",
  useTime: "サービス使用時間です",
  serviceStatus: "サービス状態です",
  arId: "サービスAR IDです",
  arName: "サービスAR愛称です",
  interPhoneId: "サービス・インターフォンIDです",
  noData: "仮無数の根拠です",
  startAttention: "関心を持つようになりました",
  startAttentionTips: "成功に注目します",
  cancelAttention: "フォローをキャンセルします",
  cancelAttentionTips: "発注はキャンセルされましたフォロー",
  // 设置
  set: "備え付け",
  cameraOrAudioSet: "カメラと音声設定です",
  camera: "カメラ",
  cameraPlaceholder: "カメラを選択します",
  revolveCamera: "画面が回転します",
  openMirror: "ミラーリングします",
  microphone: "まいくろほん",
  microphonePlaceholder: "マイクをお願いします",
  closeMicrophone: "マイクをオフにします",
  testMicrophone: "マイクテストです",
  abnormalMicrophone: "マイク異常です",
  openMicrophone: "収録中です",
  loudspeaker: "かくせいき",
  loudspeakerPlaceholder: "スピーカーをお願いします",
  updatedHeadImg: "プロフィール画像の更新成功です",
  downHeadImg: "アイコン",
  setHeadImg: "アバター設定",
  selectHeadImg: "アバターを選択",
  selectHeadImgTxt: "説明：PNG、JPG、JPGEフォーマットをサポートする",
  selectOnePIc: "画像を選択",
  selectOnePicFalse: "画像を選択してください",
  selectOnePicSize: "アップロード画像のサイズは1 M以下",
  downNickName: "ニックネーム",
  setNickName: "ニックネームの設定",
  changNickName: "ニックネームは変更されていません",
  setNickNamePattern: "リモートアドバイザにニックネームを入力してください。空白にすることはできません。最大10文字入力してください",
  setAudioDevices: "デバイスの選択",
  setAudioCall: "オーディオビデオ通話",
  setAudioLayout: "レイアウトの設定",
  setVolume: "音量",
  setMicroPhoneVolume: "マイクテストをクリックして、マイクに向かって話してみてください",
  setLoudSpeakerVolume: "クリックして音を再生して、音が聞こえるかどうか試してみてください",
  noSupport: "サポートされていません",
  testMicroPhone: "マイクテスト",
  testLoudSpeaker: "テストサウンドを再生",
  // APP下载
  loginAppDownload: "APPダウンロード",
  appDownload: "APPダウンロード",
  SystemIntroduction: "システムの概要",
  Instructions: "使用説明書です",
  // 帮助
  help: "ヘルプ",
  serviceSupport: "サービス・サポート",
  companyName: "ティムヴィオーストラリア（上海）ネットワーク技術有限公司",
  phone: "搭乗机:021-57618272です",
  email: "メールボックス：sup port@tmva-sh.com",
  website: "Webサイト：www.tmva-sh.com",
  address: "住所：松江区カルボニルレンガ道路239号ラファエロ雲廊6号棟606-607室",
  versionCode: 'V3.1',
  // 退出登录
  logout: "ログオンの終了",
  // 联系人
  searchContact: "連絡先の検索",
  searchUserResult: "連絡先の検索",
  // 右侧工具
  attendee: "出席者",
  editNickName: "ニックネームの変更",
  unlocked: "ロック解除",
  locked: "ロックされている",
  inviteComfirm: "に勧める",
  SetMasterControl: "マスタ制御",
  forceRemove: "強制的に移動",
  forceRemoveWarn: "現在のリモートアドバイザを呼び出されました",
  speaker: "マスタコントロール",
  openCamera: "カメラをオンにする",
  closeCamera: "カメラを閉じる",
  openAudio: "オーディオを開く",
  closeAudio: "ミュート",
  applySpeaker: "申請マスター",
  giveUpSpeaker: "マスターの破棄",
  applySpeakerApply: "マスター権限の呼び出しの申請",
  applySpeakerAccept: "マスター権限呼出の申請",
  addRoomApply: "リモートアドバイザ呼出への参加を申請する",
  addRoomAccept: "リモートアドバイザコールへの参加を申請する",
  whiteBoard: "ホワイトボードこうりゅう",
  staticMark: "スタティツクマーカ",
  dynamicMark: "ダイナミックガイド",
  speakerAuthor: "プライマリライセンス",
  openShareDesktop: "デスクトップの共有",
  closeShareDesktop: "共有の停止",
  meetingUploadFile: "ファイルのアップロード",
  meetingRepairDetails: "修理の詳細",
  outMeeting: "リモートアドバイザの終了",
  chat: "チャット",
  chatPlaceholder: "チャット内容を入力してください",
  openNoCamera: 'カメラが開いていない',
  // AR操作
  rearCamera: "バックカメラ",
  ARCamera: "ARカメラ",
  thermalImg: "サーモイメージング",
  photograph: "写真を撮る",
  openLight: "フラッシュをあける",
  closeLight: "フラッシュを消す",
  ARRecord: "AR記録",
  ARRClose: "録画中",
  openHotImg: "熱力学図を開く",
  closeHotImg: "クローズ熱力学図",
  areaMode: "ゾーンモード",
  centerMode: "中心モード",
  openMultipoint: "マルチポイント温度測定をオンにする",
  closeMultipoint: "多点温度測定をオフにする",
  color1: "高感度",
  color2: "高コントラスト",
  color3: "さいこうおんいき",
  zoomLargen: "拡大",
  zoomMinish: "縮小",
  // 弹框或者轻提示
  userNotOnline: "このユーザーはオンラインではありません。後で再試行してください",
  msg: "ヒント",
  close: "閉じる",
  hasInMeeting: "ユーザーは現在のリモートアドバイザにいます",
  noAcceptInvite: "相手が忙しいので、しばらくお招きには応じかねます",
  networkDisconnection: "ネットワークが切断されました。再ログインを終了してください",
  time: "時間",
  temperature: "おんど",
  getSpeakerPermission: "相手はマスター権限を申請し、許可しますか？",
  agree: "に同意",
  disagree: "拒否",
  toSpeaker: "マスターになる",
  giveUpedSpeaker: "マスターの破棄",
  currentNoSpeaker: "現在マスターなし",
  speakerLogout: "マスターが終了しました",
  speakerleaveRoom: "出て行った",
  disagreeSpeakerPermission: "相手はあなたのマスター申請を拒否しました",
  timeoutSpeakerPermission: "相手が応答していない",
  userinmeeting: "相手はリモート・アドバイザで忙しいので、リモート・アドバイザへの参加を申請しますか？",
  applyToJoin: "加入申請",
  userinmeetingTips: "相手の同意を待っていますので、しばらくお待ちください...",
  want_to_join_the_meeting: "現在のリモート・アドバイザへの参加を申請し、同意するかどうか",
  no_meeting: "相手が拒否しました",
  closeShareDesk: "まず共有デスクトップを閉じてください",
  confirmExit: "本当に終了しますか",
  pleaseEnterContent: "内容を入力してください",
  confirmCloseShareDesk: "デスクトップ共有を閉じてもよろしいですか",
  shareDeskOpened: "デスクトップ共有が開かれました",
  giveUpSpeakerTips: "マスターを破棄しますか？",
  applySpeakerSendTips: "マスター申請が発送されましたので、お待ちください",
  abnormalLogin: "ログイン例外またはアカウントがログインしているので、再ログインを終了してください",
  hasInvite: "相手がリモート・アドバイザへの参加を申請し、同意するかどうか",
  accept: "受け入れる",
  reject: "拒否",
  agreeInvite: "あなたの招待に同意しました",
  rejectInvite: "相手が拒否しました",
  pleaseEnterNickname: "ニックネームを入力してください",
  editNickname: "ニックネームの変更",
  nicknameNoNull: "ニックネームは空ではなく、長さは10以上ではありません",
  noNull: "空にすることはできません",
  editNicknameSuccess: "ニックネームの修正に成功しました",
  joinRoom: "参加済み",
  leaveRoom: "出て行った",
  exit: "終了",
  exitFull: '全画面表示を終了',
  waitingOtherJoin: "相手が出るのを待っていますので、しばらくお待ちください",
  waitingOtherHandle: "相手の処理を待っています。しばらくお待ちください。",
  hasCanced: "相手がキャンセルされました",
  delete: "削除＃サクジョ＃",
  deleteTips: "本当に削除しますか？",
  deleteSuccess: "削除に成功しました",
  recordFile: "クラウド記録ファイル",
  recordFileBox: "クラウド記録ファイル",
  preview: 'オンラインプレビュー',
  record: "レコーディング",
  enter: "に入る",
  ignore: "無視する",
  theme: 'トピック＃トピック＃',
  timer: '時間',
  enterRoom: '予約したリモートアドバイザが開始されました?',
  // 白板
  text: "テキスト",
  brush: "ブラシ本",
  straight: "直線",
  rectangle: "長方形",
  round: "円形",
  triangle: "三角形",
  square: "ちょつかくじょうぎ",
  eraser: "消しゴム",
  empty: "クリアランス",
  revocation: "元に戻す",
  import: "画像のインポート",
  download: "ダウンロード",
  hasLogin: "このアカウントはログインされています",
  // AR操作提示
  AR_F_WIN_OpenMic_Success: "マイクを開けました。",
  AR_F_WIN_OpenMic_Error: "マイクを開けませんでした",
  AR_F_WIN_CloseMic_Success: "マイクを閉じました。",
  AR_F_WIN_CloseMic_Error: "マイクを閉じられませんでした",
  AR_F_WIN_Rear_Camera_Success: "バックカメラを開いて成功しました",
  AR_F_WIN_Rear_Camera_Error: "バックカメラを開けませんでした",
  AR_F_WIN_AR_Camera_Success: "ARカメラを開けました",
  AR_F_WIN_AR_Camera_Error: "ARカメラを開けませんでした",
  AR_F_WIN_Thermography_Success: "熱イメージングをオンにしました",
  AR_F_WIN_Thermography_Error: "サーモイメージングのオープンに失敗しました",
  AR_F_WIN_OpenFlash_lamp_Success: "点滅点灯に成功しました",
  AR_F_WIN_OpenFlash_lamp_Error: "点滅点灯に失敗しました",
  AR_F_WIN_CloseFlash_lamp_Success: "フラッシュオフに成功しました",
  AR_F_WIN_CloseFlash_lamp_Error: "フラッシュオフに失敗しました",
  AR_F_WIN_Photograph_Success: "写真撮影に成功しました",
  AR_F_WIN_Photograph_Error: "写真撮影に失敗しました",
  AR_F_WIN_Recording_Success: "記録を開いて成功しました",
  AR_F_WIN_Recording_Error: "相手が拒否しました",
  AR_F_WIN_OpenThermograph_Success: "熱力学図を開くことに成功しました",
  AR_F_WIN_OpenThermograph_Error: "熱マップを開くことができませんでした",
  AR_F_WIN_CloseThermograph_Success: "熱力学図を閉じることに成功しました",
  AR_F_WIN_CloseThermograph_Error: "熱力学マップを閉じることに失敗しました",
  AR_F_WIN_Regional_model_Success: "ゾーンモードを開いて成功しました",
  AR_F_WIN_Regional_model_Error: "ゾーンモードを開くことができませんでした",
  AR_F_WIN_Central_mode_Success: "中心モードを開いて成功しました",
  AR_F_WIN_Central_mode_Error: "中心モードを開くことができませんでした",
  AR_F_WIN_OpenMultipoint_temperature_measurement_Success: "複数点温度測定に成功しました",
  AR_F_WIN_OpenMultipoint_temperature_measurement_Error: "マルチポイント温度測定を開くことに失敗しました",
  AR_F_WIN_CloseMultipoint_temperature_measurement_Success: "シャットダウン多点測温成功",
  AR_F_WIN_CloseMultipoint_temperature_measurement_Error: "マルチポイント温度測定のシャットダウンに失敗しました",
  AR_F_WIN_Palette_mode_1_Success: "カラー調整に成功しました",
  AR_F_WIN_Palette_mode_1_Error: "カラー調整を開けませんでした",
  AR_F_WIN_Palette_mode_2_Success: "カラー調整2を開いて成功しました",
  AR_F_WIN_Palette_mode_2_Error: "カラー調整2を開くことができませんでした",
  AR_F_WIN_Palette_mode_3_Success: "カラー調整3を開いて成功しました",
  AR_F_WIN_Palette_mode_3_Error: "カラー調整3を開けませんでした",
  Camera_Zoom_Largen_Success: "拡大成功",
  Camera_Zoom_Largen_Error: "拡大に失敗しました",
  Camera_Zoom_Minish_Success: "縮小成功",
  Camera_Zoom_Minish_Error: "縮小失敗",
  AR_Camera_Main_Success: "メインカメラを開けました",
  AR_Camera_Main_Error: "メインカメラを開けませんでした",
  AR_Camera_Deputy_Success: "サブカメラを開けました",
  AR_Camera_Deputy_Error: "サブカメラを開けませんでした",
  Insta_camera_left_Success: "パノラマカメラの左スクロールに成功しました",
  Insta_camera_left_Error: "パノラマカメラの左スクロールに失敗しました",
  Insta_Camera_Right_Success: "パノラマカメラの右スクロールに成功しました",
  Insta_Camera_Right_Error: "パノラマカメラの右スクロールに失敗しました",
  Insta_Camera_Up_Success: "パノラマカメラのスクロールに成功しました",
  Insta_Camera_Up_Error: "パノラマカメラのスクロールに失敗しました",
  Insta_Camera_Down_Success: "パノラマカメラを下にスクロール成功",
  Insta_Camera_Down_Error: "パノラマカメラの下スクロールに失敗しました",
  // 设备信息
  equipmentInfo: "デバイス情報",
  partsInfo: "部品情報",
  faultInfo: "障害情報",
  equipmentName: "デバイス名",
  equipmentModel: "設備型番",
  saleCreateTime: "販売時間",
  saleWarrantyTime: "品質保証の有効期間:至",
  partsName: "部品名",
  partsModel: "部品モデル",
  faultName: "障害名",
  faultNumber: "こしょうコード",
  equipmentDetail: "デバイスの説明",
  partsDetail: "部品の説明",
  faultDetail: "障害の説明",
  equipmentImage: "設備図",
  partsImage: "部品図",
  faultImage: "こしょうず",
  hasReceived: "相手がメッセージを受信しました",
  // 全局错误码---------------------------
  // 请求响应状态-state
  SUCCESS: "せいこうほう",
  ERROR: "エラー",
  SYSTEM_ERROR: "システムエラー",
  NO_PERMISSION: "権限がありません",
  NO_LOGIN: "ログインしていません",
  KICK_OUT: "蹴飛ばされる",
  // 请求响应错误码-msg-账户相关
  ACCOUNT_000001: "ユーザー名またはパスワードが間違っています",
  ACCOUNT_000002: "アカウント例外",
  ACCOUNT_000003: "プラットフォームユーザーアカウントは、連絡先の追加と追加を開始することはできません",
  ACCOUNT_000004: "アカウントを更新できません",
  ACCOUNT_000005: "アカウントが登録されました",
  ACCOUNT_000006: "同エンタープライズユーザーアカウントは追加できません",
  ACCOUNT_000007: "友達だから追加できない",
  ACCOUNT_000008: "応募が開始されました",
  ACCOUNT_000009: "この購買依頼は処理されました（同意済みまたは却下済み）",
  ACCOUNT_000010: "デバイス固有コードが不正です",
  ACCOUNT_000011: "本アカウント以外の外部連絡先",
  ACCOUNT_000012: "パスワードフォーマットが正しくありません",
  ACCOUNT_000013: "アカウントはこのデバイスにログイン権限がありません",
  ACCOUNT_000014: "現在のアカウントに外部連絡先を追加することはできません",
  ACCOUNT_000015: "相手のアカウントに外部連絡先を追加することはできません",
  ACCOUNT_000016: "ログイン名はすでに存在します",
  ACCOUNT_000017: "エンタープライズにスーパー管理者がいる",
  // 请求响应错误码-msg-接口请求
  API_000001: "ログインしていません",
  API_000002: "権限なし",
  API_000003: "不明なインタフェース",
  API_000004: "不正なパラメータ",
  API_000005: "サポートされていない要求方法",
  API_000006: "httpボリュームの読み書きに失敗しました",
  // 请求响应错误码-msg-数据访问
  DATA_000001: "データアクセス例外",
  DATA_000002: "データが存在しません",
  DATA_000003: "データはすでに存在します",
  // 请求响应错误码-msg-文件操作
  FILE_000001: "ファイルサイズのオーバーライド",
  FILE_000002: "フォルダの作成に失敗しました",
  FILE_000003: "アップロードに失敗しました",
  FILE_000004: "ファイルのアップロード署名の取得に失敗しました",
  FILE_000005: "ファイルアップロードのコールバックチェックに失敗しました",
  FILE_000006: "ファイルアップロードコールバック解析パラメータに失敗しました",
  FILE_FAILURE: "ファイルの有効期限または有効期限",
  // 请求响应错误码-msg-其它错误
  OTHER_000001: "不明な例外",
  OTHER_000002: "操作に失敗しました",
  // 请求响应错误码-msg-设备相关
  EQUIPMENT_000001: "非自社共有データは編集不可",
  EQUIPMENT_000002: "デバイスグループの下には削除できないデバイスがあります",
  EQUIPMENT_000003: "デバイス/部品の販売による削除は許可されていません",
  // 请求响应错误码-msg-销售客户相关
  CUSTOMER_000001: "関連顧客プロンプトの削除",
  // 请求响应错误码-msg-地址池信息
  POOL_000001: "割当先プールの情報量が不足していることを示すプロンプト",
  POOL_000002: "プールタイプが存在しません",
  POOL_000003: "設備プールの数が足りない",
  POOL_000004: "部品プールの数が足りません",
  POOL_000005: "企業には販売資源/資質がない",
  POOL_000006: "企業ARデジタル情報リソース不足",
  POOL_000007: "デバイス/部品/故障した二次元コードは存在しません",
  // 请求响应错误码-msg-EXCEL相关
  EXCEL_000001: "excelファイルの読み取りに失敗しました",
  EXCEL_000002: "excelデータの読み取りに失敗しました",
  // 请求响应错误码-msg-部门相关
  USER_ACCOUNT_GROUP_000001: "部門の下に子部門が含まれていて削除できません",
  USER_ACCOUNT_GROUP_000002: "グループに含まれるアカウントは削除できません",
  // 请求响应错误码-msg-授权资源
  SERVICE_RESOURCE_000001: "現在のアカウントの通話時間の余裕が不足しており、サービスを停止しています",
  SERVICE_RESOURCE_000002: "サポートされていないリソース課金モデル",
  SERVICE_RESOURCE_000003: "認証タイプに対応するコードプールタイプが見つかりませんでした",
  SERVICE_RESOURCE_000004: "相手のアカウントがサービスを停止しました",
  // 请求响应错误码-msg-好视通接口错误
  HST_ERROR_000001: "ビューポートの呼び出しに失敗しました",
  HST_ERROR_000002: "リモートアドバイザ録画中",
  HST_ERROR_000003: "ルームまたはリモートアドバイザレコードは存在しません",
  HST_ERROR_000004: "クラウド録画ストレージが不足している",
  // 请求响应错误码-msg-远程指导用户
  ROOM_USER_ACCOUNT_000001: "リモートアドバイザルーム以外のユーザはPresentationを申請できません",
  ROOM_USER_ACCOUNT_000002: "すでに主説であり、申請することはできない",
  ROOM_USER_ACCOUNT_000003: "現在のユーザーはプライマリではありません",
  ROOM_USER_ACCOUNT_000004: "購買依頼番号エラー",
  ROOM_USER_ACCOUNT_000005: "前回の申し込み結果をお待ちください",
  ROOM_USER_ACCOUNT_000006: "前回の招待結果をお待ちください",
  ROOM_USER_ACCOUNT_000007: "招待番号エラー",
  ROOM_USER_ACCOUNT_000008: "アカウントがオンラインではありません",
  ROOM_USER_ACCOUNT_000009: "現在の説明はリモート・アドバイザ内のユーザーではありません",
  ROOM_USER_ACCOUNT_0000010: "ユーザーはリモートアドバイザ内のユーザーではありません",
  ROOM_USER_ACCOUNT_000011: "招待された人は他の人に招待されている",
  // 请求响应错误码-msg-远程指导室
  ROOM_000001: "リモートアドバイザルームは存在しません",
  ROOM_000002: "リモートアドバイザルームが解散しました",
  ROOM_000003: "リモートアドバイザルームはすでに存在します",
  // 联系人类型
  platformContacts: "プラットフォームの連絡先",
  companyContacts: "エンタープライズ連絡先",
  externalContacts: "外部連絡先",
  // 请求响应错误码-msg-数据权限
  DATA_PMS_000001: "非自社QRコード",
  // 相机描述
  AR_CameraText0: "カメラなし",
  AR_CameraText1: "バックカメラ",
  AR_CameraText2: "フロントカメラ",
  AR_CameraText3: "メインUVCカメラ",
  AR_CameraText4: "サブUVCカメラ",
  AR_CameraText5: "サーモイメージング",
  AR_CameraText6: "360カメラ",
  // 消息相关
  msgAll: "すべて",
  msgCall: "よびだし",
  msgAppointment: "予約する",
  msgApply: "申し込み",
  msgSystem: "システム",
  msgUpload: "トランスファ",
  msgCallIn: "よびだし",
  msgCallOut: "よびだし",
  msgAgree: "に同意",
  msgRefuse: "拒否",
  APPLIED: "購買依頼の送信に成功しました",
  REJECTED: "拒否されました",
  PASS: "合格",
  TIMEOUTA: "未投入",
  AGREEA: "受信済み",
  REFUSEA: "受信拒否",
  CANCELA: "未投入",
  TIMEOUTB: "未投入",
  AGREEB: "受信済み",
  REFUSEB: "受信していません",
  CANCELB: "つながりませんでした",
  MESSAGE_RECEIVED: "つながりませんでした",
  LOCKED: "現在、遠隔ガイダンスはロックされており、参加できません。",
  showDays: "直近7日間の記録しか表示されません",
  // 预约远程指导
  addRemoteGuides: "遠隔指導の予約をします",
  addAppointment: "遠隔指導の予約をします",
  remoteGuide: "テーマを遠隔指導します",
  remoteCenter: "遠隔指導センターです",
  lookGuide: "詳細を表示します",
  cancelGuide: "予約をキャンセルします",
  sendRemoteGuide: "遠隔指導にご参加いただきます",
  enterGuideAccount: 'テーマ入力お願いします',
  GuideAccountStartDay: '開始日をお願いします',
  GuideAccountStartHours: '開始時間をお願いします',
  GuideAccountendDay: '終了日をお願いします',
  GuideAccountendHours: '終了時間をお願いします',
  addGuideSend: "追加予約ガイドを送信しました",
  reserved: '遠隔指導を予約しまし',
  AppointmentMeeting: "遠隔指導に参加します",
  enterAppointmentMeetingID: "遠隔案内番号かリンクをお願いします",
  enterCorrectAppointmentMeetingID: "正しい遠隔案内番号またはリンクを入力します",
  remoteGuideCopy: "テーマを遠隔指導します：",
  remoteGuideDodeCopy: "指導番号です：",
  remoteGuideTimeCopy: "予約時間です：",
  remoteGuideGMTCopy: "GMT+8:00",
  remoteGuideLinkCopy: "リンクをクリックして入会します:",
  remoteGuideAccept: "遠隔指導で呼込申請をします",
  remoteGuideApply: "呼び出し申請を遠隔指導します",
  startTimeGuide: "開始時間です",
  endTimeGuide: "終了時間です",
  enterTimeGuide: "時間に入ります",
  quitTimeGuide: "退出時間です",
  gudideHour: "じかん",
  guideBegin: "始める",
  BeijingTime: "北京時間",
  timeCannot: "24時間以上の予約はできません",
  timeCannotEnd: "終了時間が開始時間より小さくなってはいけません",
  timeCannotBegin: "終了時間と開始時間を等しくしてはいけません",
  SupportAPP: 'AR遠隔指導ソフトでコードを読み込んで参加します。',
  AppDownloadedPc: 'ヒント:アプリはPCクライアントからダウンロードできます。',
  reserveGuides: '予約した遠隔指導です',
  // 外部联系人
  addOutContacts: "外部連絡先を追加します",
  enterAccount: "ユーザーアカウントの入力をお願いします",
  sendApply: "申請を送ります",
  addGroup: "パケットを追加します",
  enterGroupName: "パケット名の入力をお願いします",
  moveGroup: "パケットを修正します。",
  selectGroup: "パケットを選択します",
  outContactManage: "連絡先管理です",
  contactNickName: "と愛称で呼ぶ",
  contactNickAccount: "账号",
  contactNickNote: "備考欄",
  contactNickGroup: "グル—プ分けする",
  deleteGroup: "パケットを削除します",
  reName: "名前を変えます",
  deleteContact: "連絡先を削除します",
  delContactConfirm: "連絡先を削除しますか",
  groupMoveSuccess: "パケット移動成功です",
  delGroupConfirm: "パケットを削除しますか",
  groupAddSuccess: "パケット追加成功です",
  nameEditSuccess: "名称変更に成功しました",
  addContactSend: "発送申し込み成功です",
  notFundContact: "検索できませんでした",
  addContacts: "連絡先を追加します",
  // 云录制状态
  recordStatus1: "イニシャライズする",
  recordStatus2: "収録中です",
  recordStatus3: "コード待ち待ちです",
  recordStatus4: "コード化しています",
  recordStatus5: "アップクロスです",
  recordStatus6: "成し遂げる",
  recordStatus7: "未取得状態です",
  recordStatus8: "き·れる",
  recordStatus9: "収録に失敗します",
  "recordStatus-1": "任務異常です",
  noLogin: '登録が無効になりましたので,再登録してください',
  zh: '簡体字中国語',
  en: '英語',
  ja: '日本語',
  transparentBoard: '空間マーカーです',
  importWhiteBoard: 'ホワイトボードを導入します',
  downloadImage: '画像をダウンロードします',
  // 远程指导
  appointmentRemoteMeeting: "遠隔指導への参加を要請します",
  appointmentRemoteMeetingPic: '遠隔指導予約の詳細です',
  guideCodingTheme: '指導テーマです:',
  guideCodingTime: '予約時間です:',
  toStarted: 'これからです',
  guideCoding: '指導番号です',
  guideCodingUrl: 'ガイドリンクです',
  mettingLoad: '遠隔指導カードを保存します',
  meetingCopy: '遠隔指導リンクをコピーします',
  mettingLoadSave: 'カードを保存します',
  mettingLoadQuit: '止めになる',
  createMetting: "予約を作成します",
  INVITATION_MEETING_NOT_START_TIME: "遠隔指導はまだ始まりません",
  INVITATION_MEETING_HAVE_IN_TIME: "ご設定いただいた時間帯は、他のご予約がございますので、再設定をお願いいたします。",
  INVITATION_MEETING_IS_OVER: "遠隔指導終了です",
  INVITATION_MEETING_INVALID: "招待は無効です",
  INVITATION_MEETING_OVERMAN: "人数オーバーです",
  INVITATION_MEETING_NO_COMPANY: "企業のものではありません",
  INVITATION_MEETING_IS_LOCAK: "部屋はロックされています",
  PUSH_FILE_IS_READ: "書類は読みました",
  PUSH_FILE_IS_BACK_OUT: "書類は撤回しました",
  PARAMETERS_EXCEPTION: "パラメーター異常です",
  ANDROID_DEVICE_EXCEPTION: "android機器異常未許可です",
  SYSTEM_ENVIRONMENT_DIF: "システム環境が一致しません",
  USER_MOT_IN_ROOM: "ユーザーは遠隔指導にいません",

  // 文件上传
  uploadFileTitle: "転送ファイルの選択をお願いします。",
  sendUploadFileTitle: "ファイル送信成功です",
  uoloadFileList: "添付ファイル一覧です",
  uploadFileSelect: "添付ファイルを添付します。",
  uploadcancel: "止めになる",
  uploadconfirm: "送る",
  uploadPreview: "ぷれびゅー",
  uploadBackOut: "引っ込み",
  uploadClose: "しめいかいひ",
  uploadTitle: "ファイルプッシュ",
  uploadTitleText: "新しい書類を受け取りました",
  uploadIsRead: "すぐにプレビューしますか？",
  showReadFile: "ファイル閲覧",
  fileSend: "出す",
  fileAccept: "応答します",
  fileRecall: "撤回しました",
  otherSideFileRecall: "先方は撤回されました",
  otherSideFileAccept: "先方は受け取りました",
  fileReread: "書類は読みました",
  fileNodata: "仮無数の根拠です",
  startInvite: "発起する",
  startInvitePer: "発起人",
  toBeInvited: "招待されます",
  my: "わし",
  fileName: "ふぁいる",
  keepRepair: "アプリで利用できます“コードチェックです”機能コード加入です。",
  WechatScan: "wechatのスワイプに対応しています。",

  // 账号统计数据导出
  accountRemoteSupportTimes: "遠隔サポート時間(分)です。",
  accountRemoteSupportNum: "遠隔サポート回数(回)",
  accountOnLineTimes: "オンライン時間(分)です。",
  accountRepairOrderNum: "発注書(個数)を受け取ります。",
  accountProcessRepairOrderNum: "発注書処理済み(個数)です。",
  accountSharedFiles: "共有済みファイル(個数)です。",
  yearRemoteSupportTimes: "年間リモートサポート期間です",
  yearRemoteSupportNums: "年間リモートサポート回数です",
  yearRepairOrderNum: "年間処理工票",
  tableMinute: "(分です)",
  tableNumber: "(次です)",
  tableJanuary: 'いちがつ',
  tableFebruary: 'にがつ',
  tableMarch: 'さんがつ',
  tableApril: 'しがつ',
  tableMay: 'ごがつ',
  tableJune: 'ろくがつ',
  tableJuly: 'しちがつ',
  tableAugust: 'はちがつ',
  tableSeptember: 'くがつ',
  tableOctober: 'じゅうがつ',
  tableNovember: 'じゅういちがつ',
  tableDecember: 'じゅうにがつ',
  currentMonth: "ほんげつ",
  currentYear: 'ほんねん',
  defaultDrouping: 'デフォルトパケット化です',
  sendARRecord: 'AR録画を開始します',
  closeARRecord: 'AR録画をオフにします',
  Mon: "げつようび",
  Tues: "かようび",
  Wed: "すいようび",
  Thur: "もくようび",
  Fri: "きんようび",
  Sat: "どようび",
  Sun: "にちようび",
  news: "じょうほう",
  Shrink: "縮まる",
  Unfold: "てんかい",
  openWatermark: "透かしを開けます",
  closeWatermark: "透かしを消します",
  fileAppName: 'ファイル名です：',
  hitdownload: 'ダウンロード'
};
