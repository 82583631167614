<template>
  <div id="app" ref="app">
    <transition name="el-fade-in-linear">
      <router-view />
    </transition>
  </div>
</template>
<script>
import _ from 'lodash'
export default {
  mounted() {
    this.$nextTick(() => {
      const $app = this.$refs.app
      // const standarScale = 1920 / 1080
      window.addEventListener(
        'resize',
        _.debounce(() => {
          const docHeight = document.body.clientHeight // BODY对象高度
          const docWidth = document.body.clientWidth // BODY对象宽度
          console.log(docWidth)
          // let standarWidth
          // if (docHeight / 1920 !== 0 && ((docWidth / 1920) / (docHeight / 1080)) >= 1) {
          //   standarWidth = docHeight / 1080
          // } else {
          //   standarWidth = docWidth / 1920
          // }
          // $app.style.zoom = standarWidth

          if (docWidth < 1680) {
            const standarWidth = docWidth / 1920
            const standarHeight = docHeight / 1080
            console.log('standarWidth', standarWidth, 'standarHeight', standarHeight)
            $app.style.zoom = standarHeight;
          }
        })
      );
      if (document.createEvent) {
        var event = document.createEvent("HTMLEvents")
        event.initEvent('resize', true, true)
        window.dispatchEvent(event)
      } else if (document.createEventObject) {
        window.fireEvent("onresize")
      }
    })
  }
}
</script>
<style lang="scss">
@import './styles/reset';
@import './styles/index';
</style>
