<template>
  <div>
    <ul class="volumeBox" :style="{ width: config.width + 'px', height: config.height + 'px' }">
      <li class="volumeItem" :class="{ active: numVal > 0 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 2.5 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 5 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 7.5 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 10 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 12.5 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 15 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 17.5 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 20 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 22.5 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 25 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 27.5 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 30 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 32.5 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 35 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 37.5 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 40 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 42.5 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 45 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 47.5 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 50 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 52.5 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 55 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 57.5 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 60 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 62.5 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 65 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 67.5 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 70 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 72.5 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 75 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 77.5 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 80 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 82.5 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 85 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 87.5 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 90 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 92.5 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 95 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 97.5 }"></li>
      <li class="volumeItem" :class="{ active: numVal >= 100 }"></li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "volumeDevices",
  components: {},
  props: {
    // 传入百分比数值
    numVal: {
      type: Number,
      default: 0,
    },
    config: {
      type: Object,
      default: () => ({
        width: 285,
        height: 20,
      }),
    },
  },
};
</script>
<style lang="scss">
  .volumeBox {
    display: flex;
    justify-content: space-around;
    overflow: hidden;
    width: 170px;
    padding-top: 5px;
    .volumeItem{
      width: 2px;
      border-radius: 2px;
      background: #E3E7E9;
      position: relative;
      &.active {
        background: #73e000;
      }
    }
  }

</style>
