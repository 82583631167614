/*
 * 常用工具类
 * */
import vueInstance from "./../main";
import { Message, MessageBox } from "element-ui";
/**
 * 格式化日期
 * @param {string | Date} value  时间格式
 * @param {string} style  需要转成的时间格式yyyy-MM-dd hh:mm:ss
 * @return {string} style 格式化后的日期
 * */
export const format = (value, style) => {
  if (!style || !(style = style.toString().trim()))
    style = "yyyy-MM-dd hh:mm:ss";
  const time = new Date(value);
  const o = {
    "M+": time.getMonth() + 1, // month
    "d+": time.getDate(), // day
    "h+": time.getHours(), // hour
    "m+": time.getMinutes(), // minute
    "s+": time.getSeconds(), // second
    S: time.getMilliseconds(), // millisecond
    "q+": Math.floor((time.getMonth() + 3) / 3) // quarter
  };
  if (/(y+)/.test(style)) {
    style = style.replace(
      RegExp.$1,
      time
        .getFullYear()
        .toString()
        .substr(4 - RegExp.$1.length)
    );
  }
  for (const k in o) {
    if (new RegExp("(" + k + ")").test(style)) {
      style = style.replace(
        RegExp.$1,
        RegExp.$1.length === 1
          ? o[k]
          : ("00" + o[k]).substr(o[k].toString().length)
      );
    }
  }
  return style;
};
/**
 * 设置sessionStorage值
 * @param {string} key 键
 * @param {object|*} value 值
 */
export const setSessionStorage = (key, value) => {
  value = typeof value !== "object" ? value : JSON.stringify(value);
  window.sessionStorage.setItem(key, value);
};

/**
 * 获取sessionStorage值
 * @param {string} key 键
 */
export const getSessionStorage = key => {
  const obj = window.sessionStorage.getItem(key);
  return obj && (obj.startsWith("{") || obj.startsWith("["))
    ? JSON.parse(obj)
    : obj;
};

/**
 * 删除sessionStorage值
 * @param {string|undefined} key 键
 */
export const deleteSessionStorage = key => {
  key ? window.sessionStorage.removeItem(key) : window.sessionStorage.clear();
};

/**
 * 设置localStorage值
 * @param {string} key 键
 * @param {object|*} value 值
 */
export const setLocalStorage = (key, value) => {
  value = typeof value !== "object" ? value : JSON.stringify(value);
  window.localStorage.setItem(key, value);
};

/**
 * 获取localStorage值
 * @param {string} key 键
 */
export const getLocalStorage = key => {
  const obj = window.localStorage.getItem(key);
  return obj && (obj.startsWith("{") || obj.startsWith("["))
    ? JSON.parse(obj)
    : obj;
};

/**
 * 删除localStorage值
 * @param {string|undefined} key 键
 */
export const deleteLocalStorage = key => {
  key ? window.localStorage.removeItem(key) : window.localStorage.clear();
};

/**
 * 防抖函数
 */
let debounceTimer;
export const debounce = (callback, wait = 500) => {
  if (debounceTimer) clearTimeout(debounceTimer);
  debounceTimer = setTimeout(callback, wait);
};

/**
 * 防抖函数
 */
export const copyData = data => {
  return JSON.parse(JSON.stringify(data));
};

// 兼容性缩放
export const setonresize = (classCss) => {
  const messageResize = document.getElementsByClassName(classCss)[0]
  const docHeight = document.body.clientHeight
  const docWidth = document.body.clientWidth
  const standarHeight = docHeight / 1080
  if (docWidth < 1680) {
    if (messageResize) {
      messageResize.childNodes[0].style.zoom = standarHeight;
    }
  }
}

/**
 * 消息提示
 * @param {string} msg // 提示信息
 * @param {string|undefined} type // 提示类型、success/warning/info/error
 * */
export const showMsg = (msg, type = "success") => {
  Message({
    dangerouslyUseHTMLString: true,
    message: `<strong>${msg}</strong>`,
    customClass: "tipsMsg",
    type: type,
    offset: 15,
    duration: 3000
  });
};

/**
 * 消息弹框
 * @param {string} msg 提示信息
 * */
export const alert = (
  msg,
  confirmButtonText = vueInstance.$t("close"),
  title = vueInstance.$t("msg")
) => {
  return new Promise(resolve => {
    MessageBox.confirm(msg, title, {
      confirmButtonText: confirmButtonText,
      customClass: "videoCustomClass",
      showCancelButton: false,
      center: false,
      showClose: false,
      closeOnClickModal: false,
      dangerouslyUseHTMLString: true
    }).then(() => {
      resolve();
    });
  });
};
/**
 * 消息弹框
 * @param {string} msg 提示信息
 * */
export const alertCss = (
  customClass,
  msg,
  confirmButtonText = vueInstance.$t("close"),
  title,
) => {
  return new Promise((resolve, reject) => {
    MessageBox.confirm(msg, title, {
      confirmButtonText: confirmButtonText,
      customClass: customClass,
      title: title,
      showCancelButton: false,
      showClose: false,
      closeOnClickModal: false,
      dangerouslyUseHTMLString: true
    }).then(() => {
      resolve();
    })
  });
};
/**
 * 确认弹框
 * @param {string} title 标题
 * @param {string} string 内容
 * @param {string} confirmText 确定按钮文字
 * @param {string} cancelText 取消按钮文字
 * */
export const confirmApply = ({
  title = vueInstance.$t("msg"),
  content,
  confirmText = vueInstance.$t("confirm"),
  cancelText = vueInstance.$t("cancel")
}) => {
  return new Promise((resolve, reject) => {
    MessageBox.confirm(content, title, {
      confirmButtonText: confirmText,
      cancelButtonText: cancelText,
      customClass: "applyWebCustomClass",
      showClose: false,
      closeOnClickModal: false,
      dangerouslyUseHTMLString: true
    })
      .then(() => {
        resolve();
      })
      .catch(() => {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject();
      });
  });
};

export const confirmCss = ({
  title = vueInstance.$t("msg"),
  content,
  customClass,
  showClose,
  confirmText = vueInstance.$t("confirm"),
  cancelText = vueInstance.$t("cancel")
}) => {
  return new Promise((resolve, reject) => {
    MessageBox.confirm(content, title, {
      confirmButtonText: confirmText,
      cancelButtonText: cancelText,
      customClass: customClass,
      showClose: showClose,
      closeOnClickModal: false,
      dangerouslyUseHTMLString: true
    })
      .then(() => {
        resolve();
      })
      .catch(() => {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject();
      });
  });
};

/**
 * 确认弹框
 * @param {string} title 标题
 * @param {string} string 内容
 * @param {string} confirmText 确定按钮文字
 * @param {string} cancelText 取消按钮文字
 * */
export const confirm = ({
  title = vueInstance.$t("msg"),
  content,
  confirmText = vueInstance.$t("confirm"),
  cancelText = vueInstance.$t("cancel")
}) => {
  return new Promise((resolve, reject) => {
    MessageBox.confirm(content, title, {
      confirmButtonText: confirmText,
      cancelButtonText: cancelText,
      customClass: "videoCustomClass",
      center: false,
      showClose: false,
      closeOnClickModal: false,
      dangerouslyUseHTMLString: true
    })
      .then(() => {
        resolve();
      })
      .catch(() => {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject();
      });
  });
};
// 弹窗
export const prompt = ({
  title = vueInstance.$t("msg"),
  content,
  confirmText = vueInstance.$t("confirm"),
  cancelText = vueInstance.$t("cancel"),
  // inputPattern: '' ,
  // inputErrorMessage: '请输入正确远程指导编码',
}) => {
  return new Promise((resolve, reject) => {
    MessageBox.prompt(content, title, {
      confirmButtonText: confirmText,
      cancelButtonText: cancelText,
      showClose: false,
      closeOnClickModal: false,
    })
      .then(() => {
        resolve();
      })
      .catch(() => {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject();
      });
  });
};

// 下载图片
export const downloadImg = imgsrc => {
  // const image = new Image();
  // image.crossOrigin = "anonymous";
  // image.src = imgsrc;
  // image.onload = function() {
  //   const canvas = document.createElement("canvas");
  //   canvas.width = image.width;
  //   canvas.height = image.height;
  //   const context = canvas.getContext("2d");
  //   context.drawImage(image, 0, 0, image.width, image.height);
  //   const imgUrl = canvas.toDataURL("image/png");
  //   const saveA = document.createElement("a");
  //   document.body.appendChild(saveA);
  //   saveA.href = imgUrl;
  //   saveA.download = format(new Date(), "yyyy-MM-dd hh_mm_ss");
  //   saveA.target = "_blank";
  //   saveA.click();
  // };
  window.open(imgsrc)
};

/**
 * 时间小时差
 * @param {Date} 时间格式
 */
export const diffHours = (startTime, endTime) => {
  // return Math.floor((new Date(endTime).getTime() - new Date(startTime).getTime()) / (3600 * 1000))
  return Math.floor(endTime - startTime) / (3600 * 1000)
}
/**
 *  预约时间段内有没有预约
 * @param {Date} endTime 开始时间
 * @param {Date} startTime 结束时间
 * @param {Array} dataAll 全部会议
 */
export const uniqueOne = (dataAll, startTime, endTime) => {
  if (dataAll && dataAll.length > 0) {
    dataAll = dataAll.filter((item) => {
      return item.startTime >= startTime && item.validTime <= endTime
    })
    return dataAll
  }
}
// 排序
export const sortByKeyAsc = (array, key) => {
  return array.sort(function(a, b) {
    var x = a[key];
    var y = b[key];
    return ((x < y) ? -1 : (x > y) ? 1 : 0)
  })
}
export const sortByKeyDesc = (array, key) => {
  return array.sort(function(a, b) {
    var x = a[key];
    var y = b[key];
    return ((x > y) ? -1 : (x < y) ? 1 : 0)
  })
}
export const lambdaDesc = (arr) => {
  let newArr = []
  newArr = arr.filter(key => key.uid).concat(arr.filter(key => {
    return key.uid === -999 || key.uid === null
  }))
  return copyData(newArr)
}

// 按字母排序
export const resultArray = (array, key) => {
  return array.sort(function(a, b) {
    return a[key].localeCompare(b[key], "zh");
  })
}

// date-picker显示周几格式
export const setNowTimes = (timerDate) => {
  const myData = new Date(timerDate)
  const wk = myData.getDay()
  const yy = String(myData.getFullYear())
  const mm = myData.getMonth() + 1
  const dd = String(myData.getDate() < 10 ? '0' + myData.getDate() : myData.getDate())
  // const hou = String(myData.getHours() < 10 ? '0' + myData.getHours() : myData.getHours())
  // const min = String(myData.getMinutes() < 10 ? '0' + myData.getMinutes() : myData.getMinutes())
  // const sec = String(myData.getSeconds < 10 ? '0' + myData.getSeconds() : myData.getSeconds())
  const weeks = [vueInstance.$t("Sun"), vueInstance.$t("Mon"), vueInstance.$t("Tues"), vueInstance.$t("Wed"), vueInstance.$t("Thur"), vueInstance.$t("Fri"), vueInstance.$t("Sat")]
  const week = weeks[wk]
  const nowDate = yy + '-' + mm + '-' + dd + ' ' + week
  // this.nowTime = hou + '-' + min + '-' + sec
  return nowDate
}
// 24小时以内
export const diffDay = (newDay) => {
  const newDayStr = newDay + 86400000
  return setNowTimes(newDayStr)
}
/**
 * 时间选择器 日期 + 时间 = 转化时间戳
 * tTime 时间戳
 * hoursTime 时间 hh:mm
 */
export const changeTimestamp = (tTime, hoursTime) => {
  const dataTime = format(tTime, 'yyyy-MM-dd') // 时间戳 转化时间格式
  const timeString = dataTime + ' ' + hoursTime + ':00' // 拼接日期格式
  const ctime = timeString.substring(0, 19).replace(/-/g, '/') // 转化格式统一 格式2022/00/01
  return new Date(ctime).getTime(); // 返回拼接后的时间戳
}

// 获取整点半点
export const pointTime = () => {
  const t = new Date()
  const now = t.toLocaleTimeString('chinese', { hour12: false }).replace(/,/g, ':')
  const num = now.indexOf(':') // 返回2
  const hou = now.substring(0, num) // 小时截取0，2
  const snum = now.indexOf(':', 3) // 返回2
  const min = now.substring(3, snum) // 分钟截取
  if (parseInt(min) < 30) {
    const houTime = hou + ':00'
    return houTime
  }
  if (parseInt(min) >= 30) {
    const houTime = hou + ':30'
    return houTime
  }
}

export const pointTimeEndStart = (startDay) => {
  const timeEnd = startDay + (3600 * 24 * 1000)
  console.log(new Date())
  // const now = timeEnd.toLocaleTimeString('chinese', { hour12: false }).replace(/,/g, ':')
  // const num = now.indexOf(':') // 返回2
  // const hou = now.substring(0, num) // 小时截取0，2
  // const snum = now.indexOf(':', 3) // 返回2
  // const min = now.substring(3, snum) // 分钟截取
  // if (parseInt(min) < 30) {
  //   const houTime = hou + ':30';
  //   return houTime
  // }
  // if (parseInt(min) >= 30) {
  //   const houTime = (parseInt(hou) + 1) + ':00';
  //   return houTime
  // }
}

export const pointTimeEnd = () => {
  const t = new Date()
  // 转化24小时
  const now = t.toLocaleTimeString('chinese', { hour12: false }).replace(/,/g, ':')
  const num = now.indexOf(':') // 返回2
  const hou = now.substring(0, num) // 小时截取0，2
  const snum = now.indexOf(':', 3) // 返回2
  const min = now.substring(3, snum) // 分钟截取
  if (parseInt(min) < 30) {
    const houTime = hou + ':30'
    return houTime
  }
  if (parseInt(min) >= 30) {
    const houTime = (parseInt(hou) + 1) + ':00';
    return houTime
  }
}

// 默认值
export const giveStartHours = () => {
  const t = new Date()
  const now = t.toLocaleTimeString('chinese', { hour12: false }).replace(/,/g, ':')
  const num = now.indexOf(':') // 返回2
  const hou = now.substring(0, num) // 小时截取0，2
  const snum = now.indexOf(':', 3) // 返回2
  const min = now.substring(3, snum) // 分钟截取
  if (parseInt(min) < 30) {
    let houTime = hou + ':30';
    if (hou === '24') {
      houTime = '00:30'
    }
    return houTime
  }
  if (parseInt(min) >= 30) {
    const ttH = (parseInt(hou) + 1) < 10 ? '0' + (parseInt(hou) + 1) : (parseInt(hou) + 1)
    let houTime = ttH + ':00';
    if (houTime === '24:00') {
      houTime = '00:00'
    }
    return houTime
  }
}
export const giveEndHours = () => {
  const t = new Date()
  const now = t.toLocaleTimeString('chinese', { hour12: false }).replace(/,/g, ':')
  const num = now.indexOf(':') // 返回2
  const hou = now.substring(0, num) // 小时截取0，2
  const snum = now.indexOf(':', 3) // 返回2
  const min = now.substring(3, snum) // 分钟截取
  if (parseInt(min) < 30) {
    const ttH = (parseInt(hou) + 1) < 10 ? '0' + (parseInt(hou) + 1) : (parseInt(hou) + 1)
    let houTime = ttH + ':00'
    if (houTime === '24:00') {
      houTime = '00:00'
    }
    return houTime
  }
  if (parseInt(min) >= 30) {
    const ttH = (parseInt(hou) + 1) < 10 ? '0' + (parseInt(hou) + 1) : (parseInt(hou) + 1)
    let houTime = ttH + ':30'
    if (hou === '23') {
      houTime = '00:30'
    }
    return houTime
  }
}
// 默认结束时间
export const giveStartHoursEnd = () => {
  const t = new Date()
  const now = t.toLocaleTimeString('chinese', { hour12: false }).replace(/,/g, ':')
  const num = now.indexOf(':') // 返回2
  const hou = now.substring(0, num) // 小时截取0，2
  const snum = now.indexOf(':', 3) // 返回2
  const min = now.substring(3, snum) // 分钟截取
  let houTime
  if (parseInt(min) < 30) {
    const ttH = (parseInt(hou) + 1) < 10 ? '0' + (parseInt(hou) + 1) : (parseInt(hou) + 1)
    houTime = ttH + ':00'
  }
  if (parseInt(min) >= 30) {
    const ttH = (parseInt(hou) + 1) < 10 ? '0' + (parseInt(hou) + 1) : (parseInt(hou) + 1)
    houTime = ttH + ':30'
  }
  const dayhour = ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30']
  const tt = dayhour.indexOf(houTime)
  const tq = dayhour.slice(0, tt)
  const th = dayhour.slice(tt, dayhour.length)
  const tc = th.concat(tq)
  return copyData(tc)
}
// 默认开始时间
export const giveStartHoursStart = () => {
  const t = new Date()
  const now = t.toLocaleTimeString('chinese', { hour12: false }).replace(/,/g, ':')
  const num = now.indexOf(':') // 返回2
  const hou = now.substring(0, num) // 小时截取0，2
  const snum = now.indexOf(':', 3) // 返回2
  const min = now.substring(3, snum) // 分钟截取
  let houTime
  if (parseInt(min) < 30) {
    const ttH = hou === 0 ? '0' + hou : hou
    houTime = ttH + ':30';
  }
  if (parseInt(min) >= 30) {
    const ttH = (parseInt(hou) + 1) < 10 ? '0' + (parseInt(hou) + 1) : (parseInt(hou) + 1)
    houTime = ttH + ':00';
  }
  const dayhour = ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30']
  const tt = dayhour.indexOf(houTime)
  const th = dayhour.slice(tt, dayhour.length)
  return copyData(th)
}
// 改变的时间
export const changeHours = (startTime) => {
  const dayhour = ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30']
  const tt = dayhour.indexOf(startTime)
  const tq = dayhour.slice(0, tt)
  const th = dayhour.slice(tt + 1, dayhour.length)
  const tc = th.concat(tq)
  return copyData(tc)
}

// 改变的时间
export const changeDayHours = (startTime) => {
  const dayhour = ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30']
  const tt = dayhour.indexOf(startTime)
  const th = dayhour.slice(tt, dayhour.length)
  // console.log(tc)
  return copyData(th)
}
// 开始时间大于结束时间 结束时间始终不开始时间大1小时
export const diffHoursTime = (startHours) => {
  const hou = startHours.split(':')
  const min = parseInt(hou[1])
  let he
  if (min === 30) {
    const ttH = (parseInt(hou[0]) + 1) < 10 ? '0' + (parseInt(hou[0]) + 1) : (parseInt(hou[0]) + 1)
    he = ttH + ':00'
  } else {
    const ttH = parseInt(hou[0]) < 10 ? '0' + parseInt(hou[0]) : parseInt(hou[0])
    he = ttH + ':30'
  }
  // let he = h + ':' + hou[1]
  if (he === '24:00') {
    he = '00:00'
  }
  return he
}

// 匹配文件后缀
export const matchFileSuffixType = (fileName) => {
  let suffix = ''
  let result = '' // 获取类型
  try {
    const fileArr = fileName.split('.')
    suffix = fileArr[fileArr.length - 1]
  } catch (err) {
    suffix = ''
  }

  if (!suffix) {
    result = false
    return result
  }
  // 图片格式
  const imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'tif', 'tiff']
  result = imglist.some(item => item === suffix)
  if (result) {
    result = 'image'
    return result
  }
  // txt
  const txtlist = ['txt']
  result = txtlist.some(item => item === suffix)
  if (result) {
    result = 'txt'
    return result
  }
  // xml
  const xmllist = ['xml']
  result = xmllist.some(item => item === suffix)
  if (result) {
    result = 'xml'
    return result
  }

  // office
  const officelist = ['doc', 'docx', 'pdf', 'xls', 'xlsx', 'ppt', 'pptx']
  result = officelist.some(item => item === suffix)
  if (result) {
    result = 'office'
    return result
  }

  // 其他文件
  result = 'other'
  return result
}
// 获取文件扩展名
export const fileSuffixType = (fileName) => {
  let suffix = ''
  let result = '' // 获取类型
  const fileArr = fileName.split('.')
  suffix = fileArr[fileArr.length - 1]
  result = suffix
  if (suffix === 'doc' || suffix === 'docx') {
    result = 'doc'
  }
  if (suffix === 'xls' || suffix === 'xlsx') {
    result = 'xls'
  }
  if (suffix === 'pdf') {
    result = 'pdf'
  }
  if (suffix === 'ppt' || suffix === 'pptx') {
    result = 'ppt'
  }
  return result
}

// 移入
// targetW 最终位置 宽度
// targetH 最终位置 高度
export const animateEnter = (element, targetW, targetH) => {
  clearInterval(element.timer)
  let flagY = false
  let flagX = false
  element.timer = setInterval(() => {
    let currentW = element.offsetLeft
    let currentH = element.offsetTop
    let stepX = 4
    let stepY = 2
    stepX = currentW < targetW ? stepX : -stepX // 正负 表示向左向右
    stepY = currentH < targetH ? stepY : -stepY
    currentW += stepX // 计算移动的位置
    currentH += stepY
    if (Math.abs(targetW - currentW) > Math.abs(stepX)) {
      element.style.left = currentW + 'px'
    } else {
      flagX = true
      element.style.left = currentW + 'px'
    }
    if (Math.abs(targetH - currentH) > Math.abs(stepY)) {
      element.style.top = currentH + 'px'
    } else {
      flagY = true
      element.style.top = currentH + 'px'
    }
    if (flagY) {
      debounce(() => {
        currentH += stepY
      })
    }
    if (flagX && flagY) {
      element.classList.replace('lineUser', 'lineUserEnd')
      clearInterval(element.timer)
    }
  }, 20)
}
// 截取字符串第一个字符
export const getLength = (str) => {
  return str.substring(0, 1)
}
// 去数组中间的
export const findMiddleIndex = (nums) => {
  let midSum
  if (nums.length % 2 === 0) {
    midSum = nums.length / 2
    console.log('整除')
  } else {
    console.log('bu整除')
  }
  return midSum
  // const total = nums.reduce((a, b) => a + b, 0)
  // let sum = 0
  // for (let i = 0; i < nums.length; i++) {
  //   if (2 * sum + nums[i] === total) {
  //     return i
  //   }
  //   sum += nums[i]
  //   // console.log(sum)
  //   return i
  // }
  // return -1
}
// 判断是否为整数
export const isInteger = (obj) => {
  return typeof obj === 'number' && obj % 1 === 0
}

// 多层数组变单一数组
export const flattenObj = (obj) => {
  return [].concat(...obj.map((item) => {
    if (item.children) {
      let arr = [].concat(item, ...flattenObj(item.children))
      delete item.children
      return arr
    }
    return [].concat(item)
  }))
}
// 分组 剔除不需要的
export const getGroupArr = (data, key) => {
  let groups = []
  data.forEach((item) => {
    let value = item[key]
    groups[value] = groups[value] || []
    groups.push(item)
  })
  return groups
}

// 数组对象去重复 根据key值
export const nodup = (arr, key) => {
  const map = new Map()
  for (const item of arr) {
    if (!map.has(item[key])) {
      map.set(item[key], item)
    }
  }
  return [...map.values()]
}
export const nodupchang = (arr, key) => {
  const map = new Map()
  for (const item of arr) {
    if (!map.has(item[key])) {
      map.set(item[key], item)
    }
  }
  const ttt = [...map.values()]
  ttt.map(el => { el.companyChildren = copyData(getGroupArr(arr, key)) })
  return ttt
}

/**
 * 数组换行
 * arr 数据
 * num 每行几个
 */
export const changeLine = (arr, num) => {
  let j = 0
  const newArr = []
  for (let i = 0; i < arr.length; i++) {
    j = parseInt(i / num)
    if (newArr.length <= j) {
      newArr.push([])
    }
    newArr[j].push(arr[i])
  }
  return newArr
}
// 字节转换
export const changeFileSize = (bytes) => {
  if (bytes === 0) return '0 B';
  var k = 1024;
  var sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  var i = Math.floor(Math.log(bytes) / Math.log(k));
  return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i];
}
// 毫秒转化分钟
export const changMinute = (time) => {
  return Math.floor(time / 60000)
}
/**
 * 图表数据
 * res 数据
 * key 数据key
 * type 类型 1为时间戳
 */
export const monthListData = (res, key, type = 0) => {
  const newArr = []
  for (let i = 1; i < 13; i++) {
    const index = res.findIndex((item) => {
      return item.month === i
    })
    if (index >= 0) {
      if (type === 1) {
        newArr.push(changMinute(res[index][key]))
      } else {
        newArr.push(res[index][key])
      }
    } else {
      newArr.push(0)
    }
  }
  return copyData(newArr)
}
// 间隔3位
export const replaceThree = (res) => {
  return res.replace(/\s/g, '').replace(/(.{3})/g, "$1 ");
}
/**
 * 百分比
 * @param  num  当前数
 * @param total  总数
 * */
export const getPercent = (num, total) => {
  num = parseFloat(num)
  total = parseFloat(total);
  if (isNaN(num) || isNaN(total)) {
    return "-";
  }
  return total <= 0 ? 0 : Math.round(num / total);
}
// 四舍五入保留2位小数（不够位数，则用0替补）
export const keepTwoDecimalFull = (num) => {
  var result = parseFloat(num);
  if (isNaN(result)) {
    return false;
  }
  result = Math.round(num * 100) / 100;
  var s_x = result.toString();
  var pos_decimal = s_x.indexOf('.');
  if (pos_decimal < 0) {
    pos_decimal = s_x.length;
    s_x += '.';
  }
  while (s_x.length <= pos_decimal + 2) {
    s_x += '0';
  }
  return s_x;
}

export const verification = (val) => {
  const valString = val + ""
  const verificationType = ["", "null", "undefined"]
  return verificationType.includes(valString)
}
// 轮播图显示多张
export const formatArrPic = (picList, num) => {
  const picData = []
  for (let i = 0; i < picList.length; i += num) {
    picData.push(picList.slice(i, i + num))
  }
  return picData
}
// 公司名分组
// array 数组 res 字段名
export const changeUserArr = (array, res) => {
  const newArray = [];
  array.map(item => {
    return [item]
  }).forEach(([{ ...item }]) => {
    const flag = newArray.find(([{ ...o }]) => o.sex === item[res]);
    if (!flag) {
      newArray.push([{ ...item }])
    } else {
      newArray.forEach(([{ ...y }], index) => {
        if (y.sex === item[res]) {
          newArray[index].push(item)
        }
      })
    }
  })
  return newArray
}

// 数组对象去重复 根据key值
export const nodupkey = (arr, key) => {
  const map = new Map()
  for (const item of arr) {
    if (!map.has(item[key])) {
      map.set(item[key], item)
    }
  }
  return nodupkey
}
