import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import { getSessionStorage, setLocalStorage } from './../helpers/utils';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// 页面白名单
const whiteList = ['login', 'invitation'];
router.beforeEach((to, from, next) => {
  if (!whiteList.includes(to.name) && !getSessionStorage('user')) {
    next({ path: '/login' });
    return;
  } else {
    if (to.matched.some(res => res.meta.requireAuth)) {
      if (!getSessionStorage('user')) {
        setLocalStorage("referUrl", to.fullPath);
        next({
          name: 'login',
          params: { referUrl: to.fullPath }
        })
      } else {
        next();
      }
    } else {
      next();
    }
  }
  next();
});
export default router;
