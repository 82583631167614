<template>
  <div class="leftNavs" :class="{ active: isFold }">
    <div class="logo">
<!--      <img src="./img/logopic.png" />-->
    </div>
    <div class="leftSearch">
        <el-row>
          <el-col :span="user.canAddOutsideUserAccount === 'YES' ? 20 : 24">
            <el-input
              :placeholder="$t('searchContact')"
              size="small"
              v-model="searchStr"
              @input="searchContact"
              @blur="searchBlur"
            >
              <i slot="suffix" class="el-input__icon el-icon-search" />
            </el-input>
          </el-col>
          <el-col v-if="user.canAddOutsideUserAccount === 'YES'" :span="4">
            <!--<el-button
              style="margin-left: 20px"
              icon="el-icon-plus"
              circle
              size="small"
              @click="openExternalContacts"
            ></el-button>-->

              <div class="dropdownBox" @click.stop="getleftdropdownBox">
                <span class="el-dropdown-link">
                  <i class="el-icon-circle-plus-outline"></i>
                </span>
                <div class="dropdown-content" ref="contactsdropdown" v-show="leftdropdownBox">
                  <div class="dropdownItem" @click="openExternalContacts">
                    <p>{{$t('addContacts')}}</p>
                  </div>
                  <div class="dropdownItem" @click="openRemoteGuideOut"><p>{{ $t('addAppointment') }}</p></div>
                  <div class="dropdownItem" @click="openAppointmentMeeting"><p>{{ $t('AppointmentMeeting') }}</p></div>
                </div>
              </div>
            <!-- <el-dropdown trigger="click"
              class="dropdownBox">
              <span class="el-dropdown-link">
                <i class="el-icon-circle-plus-outline"></i>
              </span>
              <el-dropdown-menu slot="dropdown" :append-to-body="false" >
                <el-dropdown-item @click.native="openExternalContacts"
                  >添加联系人</el-dropdown-item
                >
                <el-dropdown-item @click.native="openRemoteGuideOut"
                  >预约远程指导</el-dropdown-item
                >
                <el-dropdown-item @click.native="openAppointmentMeeting"
                  >加入远程指导</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown> -->

          </el-col>
        </el-row>
      </div>
    <div class="leftContent">
      <template v-if="searchUserList && searchUserList.length">
        <div class="searchBox">
          <div class="searchBTitleBox" @click="getSearchShow">
            <div class="searchBoxTitle">{{$t('searchUserResult')}}</div>
            <div :class="leftSearchShow ? 'titleImgNone' : 'titleImg'"></div>
          </div>
          <div v-show="leftSearchShow">
            <div v-for="(item, index) in searchUserList" :key="index" class="searchListBox">
              <div class="titleline" :class="{active:onlineUserList.indexOf(item.accountId) >= 0 && ownOnlineUserList.indexOf(item.accountId) >= 0}">
                <template v-if="onlineUserList.indexOf(item.accountId) >= 0 && ownOnlineUserList.indexOf(item.accountId) >= 0">
                  <div class="nametitleleft" @click.stop="invite(item)" :title="item.accountId">{{item.name}}</div>
                  <div class="nametitleright">
                      <div class="cameraIcon" v-if="item.nodeType === 'ACCOUNT'" @click.stop="invite(item)"></div>
                      <div class="uploadIcon" @click.stop="openUploadFileVisible(item)"></div>
                  </div>
                </template>
                <template v-else>
                  <div class="nametitleleft" :title="item.accountId">{{item.name}}</div>
                  <div class="nametitleright">
                      <div class="cameraIcon" v-if="item.nodeType === 'ACCOUNT'"></div>
                      <div class="uploadIcon" @click.stop="openUploadFileVisible(item)"></div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div v-if="noDataShow" class="noDatacontent">{{ noDataShow }}</div>
      <template v-if="searchUser">
        <div v-for="(item, index) in newNodes" :key="index" class="leftTitle">
          <template v-if="index==0">
            <div :class="leftShowB ? 'parantNodeCur' : 'parantNode'" @click="getMenuShowA(item.id)">
              <div class="titleTxt" :title="item.accountId">{{item.name}}</div>
              <div :class="leftShowB ? 'titleImgNone' : 'titleImg'"></div>
            </div>
          </template>
          <template v-if="index==1">
            <div :class="leftShowC ? 'parantNodeCur' : 'parantNode'" @click="getMenuShowA(item.id)" @contextmenu.prevent="nodeContextmenu($event,newNodes[index])">
              <div class="titleTxt">{{item.name}}</div>
              <div :class="leftShowC ? 'titleImgNone' : 'titleImg'"></div>
            </div>
          </template>
          <template v-if="index==2">
            <div :class="leftShowA ? 'parantNodeCur' : 'parantNode'" @click="getMenuShowA(item.id)">
              <div class="titleTxt">{{item.name}}</div>
              <div :class="leftShowA ? 'titleImgNone' : 'titleImg'"></div>
            </div>
          </template>
          <template v-if="item.children">
            <div v-if="index==0">
               <div v-for="child in item.children" :key="child.uid" class="nodetitle" v-show="leftShowB">
                <div v-show="child.name" class="childtitleBox" @click="getGgroupShow(child.uid)" :ref="'title' + child.uid">
                  <div class="childtitle"><img src="./img/nextLevel.png"/>
                    {{ child.name }}
                  </div>
                  <div class="childImgNone" :ref="'img' + child.uid"></div>
                </div>
                <template v-if="child.children">
                  <div class="childGroup childShow" :ref="child.uid">
                    <div v-for="node in child.children" :key="node.uid">
                      <template v-if="node.children">
                        <div class="childGroupBoxCompany" @click.stop="getGgroupShow(node.uid)" :ref="'title' + node.uid">
                          <div class="childGroupBoxTitle" :title="node.name"><img src="./img/nextLevel.png" v-show="node.companyName"/>{{node.name}} </div>
                          <div class="childImgNone" :ref="'img' + node.uid"></div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="childGroup childShow">
                        <div class="nametitle" :class="{active:onlineUserList.indexOf(node.accountId) >= 0 && ownOnlineUserList.indexOf(node.accountId) >= 0}">
                          <template v-if="onlineUserList.indexOf(node.accountId) >= 0 && ownOnlineUserList.indexOf(node.accountId) >= 0">
                            <div class="titleline">
                              <div class="nametitleleft" @click.stop="invite(node)" :title="node.accountId">{{node.name}}</div>
                              <div class="nametitleright">
                                  <div class="cameraIcon"  v-if="node.nodeType === 'ACCOUNT'" @click.stop="invite(node)"><img src=""/></div>
                                  <div class="uploadIcon" @click.stop="openUploadFileVisible(node)"></div>
                              </div>
                            </div>
                            </template>
                          <template v-else>
                            <div class="titleline">
                            <div class="nametitleleft" :title="node.accountId">{{node.name}}</div>
                            <div class="nametitleright">
                                <div class="cameraIcon"  v-if="node.nodeType === 'ACCOUNT'"><img src=""/></div>
                                <div class="uploadIcon" @click.stop="openUploadFileVisible(node)"></div>
                            </div>
                          </div>
                          </template>
                        </div>
                      </div>
                      </template>
                      <template v-if="node.children">
                        <div class="childGroup childShow" :ref="node.uid" >
                          <div v-for="(childrenItem,index) in node.children" :key="index">
                            <template v-if="childrenItem.companyId === node.companyId">
                              <template  v-if="onlineUserList.indexOf(childrenItem.accountId) >= 0 && ownOnlineUserList.indexOf(childrenItem.accountId) >= 0">
                                <div class="nametitle" :class="{active:onlineUserList.indexOf(childrenItem.accountId) >= 0 && ownOnlineUserList.indexOf(childrenItem.accountId) >= 0}">
                                  <div class="titleline">
                                    <div class="nametitleleft" @click.stop="invite(childrenItem)" :title="childrenItem.accountId">{{childrenItem.name}}</div>
                                    <div class="nametitleright">
                                        <div class="cameraIcon" v-if="childrenItem.nodeType === 'ACCOUNT'" @click.stop="invite(childrenItem)"></div>
                                        <div class="uploadIcon" @click.stop="openUploadFileVisible(childrenItem)"></div>
                                    </div>
                                  </div>
                                </div>
                              </template>
                              <template v-else>
                                <div class="nametitle">
                                  <div class="titleline">
                                    <div class="nametitleleft" :title="childrenItem.accountId">{{childrenItem.name}}</div>
                                    <div class="nametitleright">
                                        <div class="cameraIcon" v-if="childrenItem.nodeType === 'ACCOUNT'" ></div>
                                        <div class="uploadIcon" @click.stop="openUploadFileVisible(childrenItem)"></div>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </template>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </template>
              </div>
              <!-- <div v-for="child in item.children" :key="child.uid" class="nodetitle" v-show="leftShowB">
                <div class="childtitleBox" @click="getGgroupShow(child.uid)" :ref="'title' + child.uid">
                 <div class="childtitle"><img src="./img/nextLevel.png"/>{{child.name}}</div>
                  <div class="childImgNone" :ref="'img' + child.uid"></div>
                </div>
                <template v-if="child.children">
                <div class="childGroup childShow" :ref="child.uid">
                  <div v-for="node in child.children" :key="node.uid" class="nametitle" :class="{active:onlineUserList.indexOf(node.accountId) >= 0 && ownOnlineUserList.indexOf(node.accountId) >= 0}">
                    <template v-if="onlineUserList.indexOf(node.accountId) >= 0 && ownOnlineUserList.indexOf(node.accountId) >= 0">
                    <div class="titleline">
                      <div class="nametitleleft" @click.stop="invite(node)" :title="node.accountId">{{node.name}}</div>
                      <div class="nametitleright">
                          <div class="cameraIcon"  v-if="node.nodeType === 'ACCOUNT'" @click.stop="invite(node)"><img src=""/></div>
                          <div class="uploadIcon" @click.stop="openUploadFileVisible(node)"></div>
                      </div>
                    </div>
                    </template>
                    <template v-else>
                      <div class="titleline">
                      <div class="nametitleleft" :title="node.accountId">{{node.name}}</div>
                      <div class="nametitleright">
                          <div class="cameraIcon"  v-if="node.nodeType === 'ACCOUNT'"><img src=""/></div>
                          <div class="uploadIcon" @click.stop="openUploadFileVisible(node)"></div>
                      </div>
                    </div>
                    </template>
                  </div>
                </div>
                </template>
              </div> -->
            </div>
            <div v-if="index==1">
<!--              {{item.children}}-->
              <div v-for="child in $utils.lambdaDesc(item.children)" :key="child.uid" class="nodetitle" v-show="leftShowC">
<!--                {{child}}-->
                <div v-show="child.name" class="childtitleBox" @contextmenu.prevent="nodeContextmenu($event,child)" @click="getGgroupShow(child.uid)" :ref="'title' + child.uid">
                  <div class="childtitle"><img src="./img/nextLevel.png"/>
                  <template></template>
                  {{child.uid==="-999" ? $t('defaultDrouping') : child.name }}
                  </div>
                  <div class="childImgNone" :ref="'img' + child.uid"></div>
                </div>
                <template v-if="child.children">
                <div class="childGroup childShow" :ref="child.uid">
                  <div v-for="node in $utils.nodupchang(child.children, 'companyName')" :key="node.uid">
                    <div class="childGroupBoxCompany" @click.stop="getGgroupShow(node.uid)" :ref="'title' + node.uid">
                      <div class="childGroupBoxTitle" :title="node.companyName"><img src="./img/nextLevel.png" v-show="node.companyName"/>{{node.companyName}}</div>
                      <div class="childImgNone" :ref="'img' + node.uid"></div>
                    </div>
                    <div class="childGroup childShow" :ref="node.uid" >
                      <div v-for="(companyItem,index) in node.companyChildren" :key="index">
                        <template v-if="companyItem.companyId === node.companyId">
                          <template  v-if="onlineUserList.indexOf(companyItem.accountId) >= 0 && ownOnlineUserList.indexOf(companyItem.accountId) >= 0">
                            <div class="nametitle" :class="{active:onlineUserList.indexOf(companyItem.accountId) >= 0 && ownOnlineUserList.indexOf(companyItem.accountId) >= 0}">
                              <div class="titleline">
                                <div class="nametitleleft" @click.stop="invite(companyItem)" @contextmenu.prevent="nodeContextmenu($event,companyItem)" :title="companyItem.accountId">{{companyItem.name}}</div>
                                <div class="nametitleright">
                                    <div class="cameraIcon" v-if="companyItem.nodeType === 'ACCOUNT'" @click.stop="invite(companyItem)"></div>
                                    <div class="uploadIcon" @click.stop="openUploadFileVisible(companyItem)"></div>
                                </div>
                              </div>
                            </div>
                          </template>
                          <template v-else>
                            <div class="nametitle">
                              <div class="titleline">
                                <div class="nametitleleft" @contextmenu.prevent="nodeContextmenu($event,companyItem)" :title="companyItem.accountId">{{companyItem.name}}</div>
                                <div class="nametitleright">
                                    <div class="cameraIcon" v-if="companyItem.nodeType === 'ACCOUNT'" ></div>
                                    <div class="uploadIcon" @click.stop="openUploadFileVisible(companyItem)"></div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
                </template>
              </div>
            </div>
            <div v-if="index==2">
              <div v-for="child in item.children" :key="child.uid" class="nodetitle" v-show="leftShowA">
                <div class="childtitleBox" @click="getGgroupShow(child.uid)" :ref="'title' + child.uid">
                  <div class="childtitle"><img src="./img/nextLevel.png"/>{{child.name}}</div>
                  <div class="childImgNone " :ref="'img'+ child.uid" ></div>
                </div>
                <template v-if="child.children">
                <div class="childGroup childShow" :ref="child.uid" >
                  <div v-for="node in child.children" :key="node.uid" class="nametitle" :class="{active:onlineUserList.indexOf(node.accountId) >= 0 && ownOnlineUserList.indexOf(node.accountId) >= 0}">
                    <template v-if="onlineUserList.indexOf(node.accountId) >= 0 && ownOnlineUserList.indexOf(node.accountId) >= 0">
                      <div class="titleline">
                        <div class="nametitleleft" @click.stop="invite(node)" :title="node.accountId">{{node.name}}</div>
                        <div class="nametitleright">
                            <div class="cameraIcon" v-if="node.nodeType === 'ACCOUNT'" @click.stop="invite(node)"></div>
                            <div class="uploadIcon" @click.stop="openUploadFileVisible(node)"></div>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="titleline">
                        <div class="nametitleleft" :title="node.accountId">{{node.name}}</div>
                        <div class="nametitleright">
                            <div class="cameraIcon" v-if="node.nodeType === 'ACCOUNT'" ></div>
                            <div class="uploadIcon" @click.stop="openUploadFileVisible(node)"></div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
                </template>
              </div>
            </div>
          </template>
        </div>
      </template>
      <!-- <el-tree
        class="leftree"
        :data="newNodes"
        default-expand-all
        @node-contextmenu="nodeContextmenu"
      >
        <template slot-scope="{ data }">
          <div
            class="listFlex"
            v-if="data.nodeType === 'ACCOUNT'"
            :class="{
              active:
                onlineUserList.indexOf(data.accountId) >= 0 &&
                ownOnlineUserList.indexOf(data.accountId) >= 0,
            }"
            @click="invite(data)"
          >
            <div class="nickName">{{ data.name }}</div>
            <div class="leftSubRight">
              <div class="cameraIcon"></div>
              <div class="uploadIcon" @click.stop="openUploadFileVisible(data)"></div>
            </div>
          </div>
          <div v-else>{{ data.name }}</div>
        </template>
      </el-tree> -->
    </div>
    <!-- 文件上传 -->
    <el-dialog
      width="508px"
      height="100px"
      class="gitUploadFileBoxNew"
      v-if="uploadFileVisibleShow"
      v-model="uploadFileVisibleShow"
      :title="$t('uploadFileTitle')"
      :visible.sync="uploadFileVisibleShow"
       @close="uploadFileVisibleShow = false"
    >
      <div class="uploadFileBox" >
        <div class="uploadname">
          <div class="uploadPic"><img :src="require('./img/touxiang.png')" /></div>
          <div class="uploadContent">
            <p>{{uploadCompanyName}}</p>
            <p>{{uploadName}}-{{uploadAccountNickname ? uploadAccountNickname : uploadID}}</p>
          </div>
        </div>
        <el-upload
          ref="uploadFile"
          class="upload-demo"
          action="#"
          accept=".doc,.docx,.pdf,.xls,.xlsx,.ppt,.pptx"
          :auto-upload="false"
          :on-change="handleChange"
          :file-list="fileList"
          :http-request="uploadFileLoad"
          :on-remove="uploadFileRemove"
         >
        <el-button size="small"  type="text" class="uploadBut">{{$t('uploadFileSelect')}}</el-button>
         <div class="fileSizeBox">{{fileSize}}</div>
         <el-progress class="progress" v-if="progressFlag"  :stroke-width="5" :percentage="progressPercent"></el-progress>
       </el-upload>
      <div class="uploadFooter">
          <el-button size="small" round @click="uploadCancal">{{$t('uploadcancel')}}</el-button>
          <el-button size="small" id="fileOnSend" round @click="uploadFileSubmit">{{$t('uploadconfirm')}}</el-button>
      </div>
      </div>
    </el-dialog>
    <!-- 添加外部联系人 -->
    <base-dialog
      ref="externalContacts"
      v-model="showExternalContacts"
      :visible.sync="showExternalContacts"
      :title="$t('addOutContacts')"
      v-if="showExternalContacts"
      height="auto"
      class="contactsDialog"
      @close="showExternalContacts = false"
      @confirm="confirmExternalContacts"
    >
      <el-form ref="externalContact" class="externalContact">
        <el-form-item>
          <el-input
            v-model="value"
            :placeholder="$t('enterAccount')"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item>
          <el-input v-model="notes" placeholder="请输入备注"></el-input>
        </el-form-item> -->
      </el-form>
    </base-dialog>
    <!-- 添加外部联系人确定 -->
    <base-dialog
      ref="sendApply"
      v-model="showSendApply"
      :title="$t('sendApply')"
      width="476px"
      height="auto"
      :showFooter="false"
      @close="showSendApply = false"
    >
      <el-row class="sendApply">
        <el-col :span="4">
          <el-image
            class="sendApplyImg"
            :src="contactData.headImg"
            fit="cover"
          >
            <div slot="error">
              <img
                src="./../../assets/defaultHead.jpg"
                style="width: 100%; height: 100%"
              />
            </div>
          </el-image>
        </el-col>
        <el-col :span="20">
          <p>{{ contactData.accountId }}</p>
          <p>{{ contactData.company?.name }} ({{ contactData.accountGroup?.name }})</p>
        </el-col>
      </el-row>
      <div class="footBtn">
         <el-button size="mini" @click="closeSendApply">{{$t('cancel')}}</el-button> <el-button type="primary" size="mini" @click="confirmSendApply">{{$t('uploadconfirm')}}</el-button>
      </div>
    </base-dialog>
    <!--创建远程指导-->
    <guide-dialog
      ref="ruleForm"
      :title="$t('addRemoteGuides')"
      width="476px"
      height="310px"
      class="datepickerBox"
      @enterMetting="enterMetting"
      v-if="showRemoteGuide"
      v-model="showRemoteGuide"
      :meetingList="meetingList"
      :isshowReserved="isshowReserved"
      :showFooter="showFooter"
      @close="showRemoteGuide = false"
      @cancelMeeting="cancelMeeting"
    >
    <template v-if="$utils.getLocalStorage('lang') === 'en'">
      <div class="datepickerSizeStartInput" @click="datepickerStartfocus">
        <el-input
        :placeholder="$t('startTimeGuide')"
        prefix-icon="el-icon-date"
        :value="this.$utils.setNowTimes(ruleForm.startDay)" size="small"
        style="width:260px"
        />
      </div>
      <div class="datepickerSizeEndInput" @click="datepickerEndfocus">
        <el-input
        :placeholder="$t('endTimeGuide')"
        prefix-icon="el-icon-date"
        :value="this.$utils.setNowTimes(ruleForm.endDay)" size="small"
        style="width:260px"
        />
      </div>
    </template>
      <el-form ref="ruleForm" class="remoteGuide" :model="ruleForm">
        <el-form-item :label="$t('remoteGuide')" prop="guidetitle">
          <el-input
            size="small"
            v-model="ruleForm.guidetitle"
            maxlength="15"
            show-word-limit
            :placeholder="$t('enterGuideAccount')"
          ></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="24"><div class="remoteGuideText">{{ $t('startTimeGuide') }}</div></el-col>
        </el-row>
         <el-form-item>
          <el-row type="flex" class="row-bg">
            <el-col :span="17">
              <el-form-item>
                <div id="datePickerChangeStart">
                  <el-date-picker
                    v-model="ruleForm.startDay"
                    size="small"
                    :placeholder="$t('startTimeGuide')"
                    :clearable="false"
                    ref="datepickerSize"
                    value-format="timestamp"
                    :format = "this.$utils.setNowTimes(ruleForm.startDay)"
                    :picker-options="pickerOptionsStart"
                    @change="changgeDay"
                    @focus="getChangeStartResize"
                    class="datepickerSizeStart"
                    style="width:260px"
                    :append-to-body="false"
                    >
                </el-date-picker>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item>
                <div>
                  <el-select v-model="ruleForm.startHours" @change="changgeHoursStart" size="small" :placeholder="$t('startTimeGuide')"
                  :popper-append-to-body="false"
                  style="width:115px">
                  <el-option
                    v-for="(item, index) in startHoursList"
                    :key="index"
                    :value="item">
                  </el-option>
                </el-select>
              </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item>
        <el-row>
          <el-col :span="24"><div class="remoteGuideText">{{ $t('endTimeGuide') }}</div></el-col>
        </el-row>
        <el-row type="flex" class="row-bg">
          <el-col :span="17">
            <el-form-item>
              <div class="datePickerChangeEnd">
                <el-date-picker
                  v-model="ruleForm.endDay"
                  size="small"
                  type="date"
                  ref="datepickerSize"
                  :clearable="false"
                  :placeholder="$t('endTimeGuide')"
                  :format = "this.$utils.setNowTimes(ruleForm.endDay)"
                  value-format="timestamp"
                  :picker-options="pickerOptionsEnd"
                  class="datepickerEndStart"
                  @change="changgeDayEnd"
                  @focus="getChangeEndResize"
                  :append-to-body="false"
                  style="width:260px"
                  >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <el-select v-model="ruleForm.endHours" @change="changgeHoursEnd" size="small" :placeholder="$t('endTimeGuide')"
              :popper-append-to-body="false"
              style="width:115px">
                <el-option
                  v-for="(item, index) in endHoursList"
                  :key="index"
                  :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
      <div class="remoteGuideBottom">
        <el-button
          type="primary"
          @click="confirmRemoteGuides"
          class="quidebutton"
          >{{ $t('createMetting') }}</el-button>
      </div>
    </guide-dialog>
    <!--预约远程指导-->
    <base-dialog
      ref="externalReserves"
      v-model="showSeeReserves"
      v-if="showSeeReserves"
      :title="$t('sendRemoteGuide')"
      width="300px"
      height="200px"
      @close="showSeeReserves = false"
    >
      <el-form ref="externalReserves" class="externalReserves">
        <el-form-item>
          <el-input
            v-model="value"
            :placeholder="$t('enterAccount')"
          ></el-input>
        </el-form-item>
      </el-form>
    </base-dialog>
    <!--加入远程指导-->
    <guide-dialog
      ref="showAppointmentMeetings"
      v-model="showAppointmentMeeting"
      v-if="showAppointmentMeeting"
      :visible.sync="showAppointmentMeeting"
      :meetingList="meetingList"
      :isshowReserved="isshowReserved"
      :showFooter="showFooter"
      @enterMetting="enterMetting"
      @cancelMeeting="cancelMeeting"
      :title="$t('AppointmentMeeting')"
      class="showMeeting"
      width="476px"
      height="auto"
      @close="showAppointmentMeeting = false"
    >
      <el-form ref="showAppointmentMeeting" class="externalContact" >
        <el-form-item>
          <el-input
            v-model="appointmentMeetingID"
            :placeholder="$t('enterAppointmentMeetingID')"
          >
          </el-input>
        </el-form-item>
      </el-form>
       <div class="showMeetingFooter">
          <el-button size="small" round @click="closeAppointmentMeeting">{{$t('cancel')}}</el-button>
<!--          <el-button size="small" id="fileOnSend" round @click="addAppointmentMeeting">{{$t('confirm')}}</el-button>-->
          <el-button size="small" round @click="addAppointmentMeeting">{{$t('confirm')}}</el-button>
      </div>
    </guide-dialog>
    <!-- 添加外部联系人分组 -->
    <base-dialog
      ref="showAddGroupDialog"
      v-model="showAddGroup"
      v-if="showAddGroup"
      :title="$t('addGroup')"
      height="auto"
      @close="showAddGroup = false"
      @confirm="confirmAddGroup"
    >
      <el-form ref="showAddGroup" class="showAddGroupBox">
        <el-form-item>
          <el-input
            v-model="groupName"
            :placeholder="$t('enterGroupName')"
          ></el-input>
        </el-form-item>
      </el-form>
    </base-dialog>
    <!-- 选择分组弹框 -->
    <base-dialog
      ref="showAddGroupDialog"
      v-model="showSelectGroup"
      v-if="showSelectGroup"
      :title="$t('moveGroup')"
      height="auto"
      @close="showSelectGroup = false"
      @confirm="confirmSelectGroup"
    >
      <el-row>
        <el-col :span="8" :push=1 class="selectGroup">{{$t('selectGroup')}}</el-col>
        <el-col :span="16"></el-col>
      </el-row>
      <el-row class="selectShowSelectGroup">
        <el-col :span="8" :push=1>
          <div class="showCurrenttGroupName">{{showCurrenttGroupName}}</div>
        </el-col>
        <el-col :span="16" :push=3>
          <el-select
            v-model="targetGroupId"
            :popper-append-to-body="false"
            size="small"
          >
          <template v-if="$utils.getLocalStorage('lang') === 'en'">
            <el-option
              v-for="item in $utils.lambdaDesc(copyNodes[1].children)"
              :key="item.uid"
              :label="item.name === '默认分组' ? 'Default grouping' : item.name"
              :value="item.uid"
            >
            </el-option>
          </template>
          <template v-else>
            <el-option
              v-for="item in $utils.lambdaDesc(copyNodes[1].children)"
              :key="item.uid"
              :label="item.name"
              :value="item.uid"
            >
            </el-option>
          </template>
          </el-select>

        </el-col>
      </el-row>
      <!-- <el-radio-group v-if="copyNodes.length" v-model="targetGroupId">
        <el-radio
          style="display: block; margin-bottom: 10px"
          v-for="item in copyNodes[1].children"
          :key="item.id"
          :label="item.id"
          >{{ item.name }}</el-radio
        >
      </el-radio-group> -->
    </base-dialog>
    <!-- 外部联系人管理 -->
    <base-dialog
      ref="showContactManage"
      v-model="showContactManage"
      v-if="showContactManage"
      :title="$t('outContactManage')"
      width="800px"
      height="auto"
      :showClose="false"
      @close="showContactManage = false"
      @confirm="confirmContactManage"
    >
      <el-container
        style="height: 400px; border-bottom: 1px solid #eee"
        v-if="cDataLists.length"
      >
        <el-aside width="200px" style="border-right: 1px solid #eee; padding-right: 15px"
        >
          <ul>
            <li
              v-for="item in $utils.lambdaDesc(contactGroupList)"
              :key="item.id"
              class="groupListMlku"
              :class="{ active: selectedGroupId === item.uid}"
              @click="changeExGroup(item.uid)"
            >
            <template v-if="$utils.getLocalStorage('lang') === 'en'">
              <template v-if="item.name === '默认分组'">
                Default grouping
              </template>
              <template v-else>
                 {{ item.name }}
              </template>
            </template>
            <template v-else>
              {{ item.name }}
            </template>
            </li>
          </ul>
        </el-aside>
        <el-main>
          <el-row>
            <el-col :span="8" class="groupTitle">{{$t('contactNickAccount')}}</el-col>
            <el-col :span="8" class="groupTitle">{{$t('contactNickName')}}</el-col>
            <el-col :span="8" class="groupTitle">{{$t('contactNickGroup')}}</el-col>
          </el-row>
          <template v-if="contactGrouptable[0]">
            <div v-if="!contactGrouptable[0].children" class="groupListNodata">{{$t('noData')}}</div>
          <el-row v-for="(item,index) in contactGrouptable[0].children" :key="index" class="groupList">
            <el-col :span="8"> <img :src="item.headImg ? item.headImg : require('./img/touxiang.png')" class="scopeLogo"/>{{item.accountId}}</el-col>
            <el-col :span="8">{{item.name}}</el-col>
            <el-col :span="8">
              <el-select
                  v-model="item.parentGroupId"
                  size="small"
                  @change="dialogChangeGroup(item.parentGroupId, item.uid)"
                  :popper-append-to-body="false"
                >
                <template v-if="$utils.getLocalStorage('lang') === 'en'">
                  <el-option
                    v-for="item in $utils.lambdaDesc(contactGroupList)"
                    :key="item.uid"
                    :label="item.name === '默认分组' ? 'Default grouping' : item.name"
                    :value="item.uid">
                 </el-option>
                </template>
                <template v-else>
                  <el-option
                    v-for="item in $utils.lambdaDesc(contactGroupList)"
                    :key="item.uid"
                    :label="item.name"
                    :value="item.uid">
                 </el-option>
                </template>
                </el-select>
          </el-col>
          </el-row>
          </template>
          <template v-else-if="!contactGrouptable[0]">
            <el-row>
              <el-col :span="24" class="groupListNodata">{{$t('noData')}}</el-col>
          </el-row>
          </template>
          <!-- <el-table
            class="contactMTable"
            :data="tableData"
            style="width: 100%"
            size="small"
            :key="Math.random()"
          >
            <el-table-column prop="accountId" :label="$t('contactNickAccount')">
              <template slot-scope="scope">
                <img :src="scope.row.headImg ? scope.row.headImg : require('./img/touxiang.png')" class="scopeLogo"/>{{scope.row.accountId}}
            </template>
            </el-table-column>
            <el-table-column prop="name" :label="$t('contactNickName')" />
            <el-table-column prop="group" :label="$t('contactNickGroup')">
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.value"
                  @change="dialogChangeGroup($event, scope.row)"
                  :popper-append-to-body="false"
                >
                  <el-option
                    v-for="item in $utils.lambdaDesc(cDataLists)"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
          </el-table> -->
        </el-main>
      </el-container>
    </base-dialog>
    <!-- 外部联系人分组添加操作 -->
    <ul
      class="outContactOptionLists"
      :style="{ left: pageX/zoom + 'px', top: pageY/zoom + 'px' }"
      v-if="showModelType === 'addGroup'"
    >
      <li @click="openAddGroup('add')">
        <i class="el-icon-circle-plus-outline" />
        <span>{{ $t("addGroup") }}</span>
      </li>
    </ul>
    <!-- 外部联系人分组编辑操作 -->
    <ul
      class="outContactOptionLists"
      :style="{ left: pageX/zoom + 'px', top: pageY/zoom + 'px' }"
      v-else-if="showModelType === 'groupOption'"
    >
      <li @click="userAccountGroupDeleteOutside">
        <i class="el-icon-delete" />
        <span>{{ $t("deleteGroup") }}</span>
      </li>
      <li @click="openAddGroup('edit')">
        <i class="el-icon-edit" />
        <span>{{ $t("reName") }}</span>
      </li>
    </ul>
    <!-- 外部联系人操作 -->
    <ul
      class="outContactOptionLists"
      :style="{ left: pageX/zoom + 'px', top: pageY/zoom + 'px' }"
      v-else-if="showModelType === 'contactOption'"
    >
      <li @click="openSelectGroup">
        <i class="el-icon-rank" />
        <span>{{ $t("moveGroup") }}</span>
      </li>
      <li @click="userAccountDeleteContacts">
        <i class="el-icon-delete" />
        <span>{{ $t("deleteContact") }}</span>
      </li>
      <li @click="openContactManage">
        <i class="el-icon-user" />
        <span>{{ $t("outContactManage") }}</span>
      </li>
    </ul>
<!-- 文件浏览 -->
    <el-dialog
      width="80%"
      top="1vh"
      ref="uploadFileBox"
      class="uploadFileBoxRead"
      v-if="showReadFile"
      :visible.sync="showReadFile"
      v-model="showReadFile"
      :show-close="true"
      @close="showReadFile = false"
      :append-to-body="false"
    >
    <div class="upload_heard">{{showFileName}} {{$t('showReadFile')}}
      <div class="uploadBtnBox">
        <div class="uploadBtnIcon" title="点击下载" @click="uploadLink(uploadFileData)"></div>
      </div>
    </div>
    <iframe class="uploadLink" :src="officeLink"></iframe>
    </el-dialog>
  </div>
</template>

<script>
import Bus from '../../helpers/bus'
export default {
  props: {
    onlineUserList: {
      // pass当前在线人员
      type: Array,
      default: () => [],
    },
    ownOnlineUserList: {
      // 自己当前在线人员
      type: Array,
      default: () => [],
    },
    sendUserID: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      searchStr: "", // 搜索关键字
      nodes: [], // 联系人列表
      copyNodes: [], // 联系人列表备份
      showExternalContacts: false,
      value: "",
      contactData: {},
      showSendApply: false,
      // 外部联系人分组
      pageX: 170,
      pageY: 0,
      showModelType: "",
      // 添加分组
      showAddGroup: false,
      groupName: "",
      // 移动分组
      showSelectGroup: false,
      targetGroupId: "",
      // 外部联系人管理
      showContactManage: false,
      // 查看预约
      showSeeReserves: false,
      // 加入预约
      appointmentMeetingID: "",
      showAppointmentMeeting: false,
      // 加入房间
      roomList: [],
      selectedGroupId: "",
      tableData: [],
      cDataLists: [],
      uniqueNo: "",
      metingID: '',
      createqrCodePic: '', // 服务器二维码
      // 创建远程指导
      iscenter: true,
      isshowReserved: false,
      showFooter: false,
      showRemoteGuide: false,
      meetingList: [], // 自己创建的预约
      addMeetingList: [], // 自己参加的预约
      rulesform: [],
      ruleForm: {
        guidetitle: '',
        startDay: '',
        endDay: '',
        startHours: '',
        endHours: '',
      },
      startHoursList: '',
      endHoursList: '',
      // 预约时间选择
      pickerOptionsStart: {
        disabledDate: (time) => {
          if (this.ruleForm.startDay > this.ruleForm.endDay) {
            this.ruleForm.endDay = this.ruleForm.startDay
          } else {
            return time.getTime() < Date.now() - 8.64e7;
          }
        }
      },
      pickerOptionsEnd: {
        disabledDate: (time) => {
          if (this.ruleForm.startDay) {
            return time.getTime() > this.ruleForm.startDay + 8.64e7 || time.getTime() < this.ruleForm.startDay;
          } else {
            return time.getTime() < Date.now() - 8.64e7;
          }
        },
      },
      // 文件上传
      uploadFileVisibleShow: false,
      fileList: [], // 上传文件列表
      uploadToName: '', // 目标用户名
      uploadID: '', // 账号"wjlcszx02"
      uploadToAccountId: '', // 目标用户名ID
      uploadName: '',
      uploadCompanyName: '',
      uploadAccountNickname: '',
      headers: {
        subject_token: this.$utils.getSessionStorage('token'),
      },
      isUploadFile: true, // 判断是否选择文件
      fileSize: '',
      showReadFile: false,
      uploadFileData: '',
      officeLink: '',
      showFileName: '',
      leftShowB: true,
      leftShowC: false,
      leftShowA: false,
      leftdropdownBox: false,
      messageResize: '',
      searchUser: true,
      searchUserList: [],
      noDataShow: "",
      leftSearchShow: true,
      merenArr: '',
      groupDisply: true,
      showCurrenttGroupName: '',
      contactGroupList: [],
      contactGrouptable: [],
      progressPercent: 0,
      progressFlag: false,
    };
  },
  beforeDestroy() {
    if (this.inviteFileTime) {
      clearInterval(this.inviteFileTime);
      this.inviteFileTime = null;
    }
  },
  computed: {
    zoom() {
      return this.getEleById('app').style.zoom
    },
    // 用户信息
    user() {
      return this.$store.state.user;
    },
    // 用户accountId
    // userId() {
    //   return this.user.accountId;
    // },
    // // 用户id
    // userAccountId() {
    //   return this.user.id;
    // },
    nickname() {
      return this.user.nickname
    },
    // 菜单是否折叠
    isFold() {
      return this.$store.state.isFold;
    },
    // 返回排序好的在线人员
    newNodes() {
      if (!this.nodes.length) return;
      const searchUsers = (data) => {
        // 进行排序
        if (data[0].nodeType === "ACCOUNT") {
          data.sort((a) => {
            if (
              // this.onlineUserList.indexOf(a.accountId) >= 0 &&
              this.ownOnlineUserList.indexOf(a.accountId) >= 0
            ) {
              return -1;
            }
            return 1;
          });
        }

        // 查找children
        data.forEach((item) => {
          if (item.children && item.children.length) searchUsers(item.children);
        });
      };
      const nodes = this.$utils.copyData(this.nodes);
      searchUsers(nodes);
      console.log('nodes', nodes)
      return nodes;
    },
  },
  mounted() {
    Bus.$on('leftdropdownBox', (content) => {
      console.log(content)
      this.leftdropdownBox = content
    });
    this.userAccountContacts();
    document.addEventListener("click", (e) => {
      this.showModelType = "";
      if (this.leftdropdownBox === true) {
        this.closeContactsDropDown(e)
      }
    });
    this.userAppointmentMeeting();
    this.checkMetting()
    this.initDate()
  },
  methods: {
    getEleById(id) {
      return document.getElementById(id);
    },
    getleftdropdownBox() {
      this.leftdropdownBox = !this.leftdropdownBox
      Bus.$emit('logoutdownShow', false)
      Bus.$emit('topdropdownSet', false)
      Bus.$emit('topdropdownHelp', false)
    },
    datepickerStartfocus() {
      document.querySelector('.datepickerSizeStart').querySelector('input').focus()
    },
    datepickerEndfocus() {
      document.querySelector('.datepickerEndStart').querySelector('input').focus()
    },
    // 时间兼容性
    getChangeEndResize() {
      this.$nextTick(() => {
        const docWidth = document.body.clientWidth
        if (docWidth < 1680) {
          const tt = this.$refs.datepickerSize.$el.childNodes
          tt[6].classList.add('datePickerEndChange')
        }
      })
    },
    getChangeStartResize() {
      this.$nextTick(() => {
        const docWidth = document.body.clientWidth
        if (docWidth < 1680) {
          const messageResize = document.getElementById('datePickerChangeStart').children[0].children[3]
          messageResize.classList.add('datePickerStartChange')
        }
      })
    },
    closeContactsDropDown(e) {
      const self = this;
      if (self.leftdropdownBox === true) {
        const tree = self.$refs.contactsdropdown
        if (tree) {
          if (!tree.contains(e.target)) {
            self.leftdropdownBox = false;
          }
        }
      }
    },
    // 初始化日期
    initDate() {
      // this.startHoursList = this.$utils.giveStartHoursStart()
      if (this.ruleForm.startHours === '23:30') {
        this.ruleForm.endHours = '00:00'
        this.ruleForm.endDay = new Date().getTime() + 8640000
        this.endHoursList = this.$utils.changeDayHours('00:00')
      } else if (this.ruleForm.startHours === '00:00') {
        this.ruleForm.endHours = '00:30'
        this.ruleForm.startDay = new Date().getTime() + 8640000
        this.ruleForm.endDay = new Date().getTime() + 8640000
        this.startHoursList = this.$utils.changeDayHours('00:00')
        this.endHoursList = this.$utils.changeDayHours('00:30')
      } else {
        this.ruleForm.endHours = this.$utils.giveEndHours()
        this.ruleForm.startHours = this.$utils.giveStartHours()
        this.ruleForm.endDay = new Date().getTime()
        this.ruleForm.startDay = new Date().getTime()
        this.startHoursList = this.$utils.giveStartHoursStart()
        this.endHoursList = this.$utils.giveStartHoursEnd()
      }
    },
    changgeHoursStart() {
      this.endHoursList = this.$utils.changeHours(this.ruleForm.startHours)
      if (this.ruleForm.startHours) {
        this.ruleForm.endHours = this.$utils.diffHoursTime(this.ruleForm.startHours)
        this.ruleForm.endDay = this.ruleForm.startDay
      }

      if (this.ruleForm.startHours === '23:30') {
        this.ruleForm.endDay = this.ruleForm.startDay + 86400000
        this.ruleForm.endHours = '00:00'
      }
    },
    changgeHoursEnd() {
      if (this.ruleForm.endHours < this.ruleForm.startHours) {
        this.ruleForm.endDay = this.ruleForm.startDay + 86400000
      }

      const startTimed = this.$utils.format(this.ruleForm.startDay, 'yyyy-MM-dd')
      const startTimeh = this.ruleForm.startHours
      const startTamp = this.$utils.changeTimestamp(startTimed, startTimeh)
      // // 结束日期
      const endTimed = this.$utils.format(this.ruleForm.endDay, 'yyyy-MM-dd')
      const endTTimeh = this.ruleForm.endHours
      const endTamp = this.$utils.changeTimestamp(endTimed, endTTimeh)
      const _hours = Math.floor((endTamp - startTamp) / (3600 * 1000));
      if (_hours >= 24) {
        this.ruleForm.endDay = this.ruleForm.startDay
      }
    },
    changgeDay() {
      const nowdata = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1)
      console.log(new Date().getTime(), this.ruleForm.startDay)
      if (this.ruleForm.startDay > nowdata) {
        this.ruleForm.startHours = '00:00'
        this.ruleForm.endHours = '00:30'
        this.endHoursList = this.$utils.changeDayHours(this.ruleForm.endHours)
        this.startHoursList = this.$utils.changeDayHours(this.ruleForm.startHours)
      }
      if (this.ruleForm.startDay + 864000 < this.ruleForm.endDay) {
        this.ruleForm.endDay = this.ruleForm.startDay
      }
      if (this.ruleForm.startDay <= new Date().getTime()) {
        this.ruleForm.startHours = this.$utils.giveStartHours()
        this.ruleForm.endHours = this.$utils.giveEndHours()
      }
    },
    changgeDayEnd() {
      const startTimed = this.$utils.format(this.ruleForm.startDay, 'yyyy-MM-dd')
      const startTimeh = this.ruleForm.startHours
      const startTamp = this.$utils.changeTimestamp(startTimed, startTimeh)
      // // 结束日期
      const endTimed = this.$utils.format(this.ruleForm.endDay, 'yyyy-MM-dd')
      const endTTimeh = this.ruleForm.endHours
      const endTamp = this.$utils.changeTimestamp(endTimed, endTTimeh)
      const _hours = Math.floor((endTamp - startTamp) / (3600 * 1000));
      if (_hours >= 24) {
        this.$utils.showMsg(this.$t('timeCannot'), "warning");
        return;
      }
    },
    // 切换分组
    dialogChangeGroup(targetGroupId, targetUserAccountId) {
      console.log(targetGroupId, targetUserAccountId)
      if (targetGroupId === "-999") {
        targetGroupId = null
      }
      this.$ajax({
        url: this.$api.setOutsideUserAccountGroup,
        data: {
          targetUserAccountUid: targetUserAccountId,
          targetGroupUid: targetGroupId,
        },
      }).then((data) => {
        this.$utils.showMsg(this.$t("groupMoveSuccess"));
        this.userAccountContacts();
        this.changeExGroup(this.selectedGroupId)
      });
    },
    // 切换外部联系人管理分组
    changeExGroup(gid) {
      this.merenArr = {
        accountId: null,
        accountNickname: null,
        canAddOutsideUserAccount: null,
        children: null,
        companyId: null,
        companyName: null,
        headImg: null,
        uid: "-999",
        lastLoginDeviceType: null,
        name: this.$t('defaultDrouping'),
        nodeType: "GROUP",
        onlineStatus: null,
        parentType: "c",
        userAccountType: null,
      }
      if (gid === null) {
        this.selectedGroupId = null
        gid = "-999"
      } else {
        this.selectedGroupId = gid;
      }
      setTimeout(() => {
        this.contactGroupList = JSON.parse(JSON.stringify(this.nodes[1].children))
        const yy = this.contactGroupList.filter(item => {
          return item.uid === null || item.uid === "-999"
        })
        if (yy.length === 0) {
          this.contactGroupList.unshift(this.$utils.copyData(this.merenArr))
        }
        this.contactGrouptable = this.contactGroupList.filter(res => {
          return res.uid === gid
        })
        this.contactGroupList.filter(item => {
          return item.uid === null
        }).filter(res => {
          res.uid = "-999"
        })
        //  console.log(this.contactGrouptable[0].children)
      }, 200)
    },
    // 右键打开外部联系人管理
    openContactManage() {
      this.merenArr = {
        accountId: null,
        accountNickname: null,
        canAddOutsideUserAccount: null,
        children: null,
        companyId: null,
        companyName: null,
        headImg: null,
        uid: "-999",
        lastLoginDeviceType: null,
        name: this.$t('defaultDrouping'),
        nodeType: "GROUP",
        onlineStatus: null,
        parentType: "c",
        userAccountType: null,
      }

      this.cDataLists = JSON.parse(
        JSON.stringify(this.newNodes[1].children || [])
      );
      const tt = this.cDataLists.filter(item => {
        return item.uid === null || item.uid === "-999"
      })
      if (tt.length === 0) {
        this.cDataLists.unshift(this.$utils.copyData(this.merenArr))
      }
      this.contactGroupList = JSON.parse(
        JSON.stringify(this.nodes[1].children)
      );
      const yy = this.contactGroupList.filter(item => {
        return item.uid === null || item.uid === "-999"
      })
      if (yy.length === 0) {
        this.contactGroupList.unshift(this.$utils.copyData(this.merenArr))
      }

      this.contactGrouptable = this.contactGroupList.filter(res => {
        return res.uid === this.selectedGroupId
      })
      this.showContactManage = true
    },
    confirmContactManage() {
      this.showContactManage = false;
    },
    userAccountDeleteContacts() {
      this.$utils
        .confirm({
          content: this.$t("delContactConfirm"),
        })
        .then(() => {
          this.$ajax({
            url: this.$api.userAccountDeleteContacts,
            data: {
              targetUserAccountUid: this.targetData.uid,
            },
          }).then(() => {
            this.$utils.showMsg(this.$t("deleteSuccess"));
            this.$parent.getOnlineCenterUser()
            this.userAccountContacts();
          });
        })
        .catch(() => {});
    },
    confirmSelectGroup() {
      if (this.targetGroupId === "-999") {
        this.targetGroupId = null
      }
      this.$ajax({
        url: this.$api.setOutsideUserAccountGroup,
        data: {
          targetUserAccountUid: this.targetData.uid,
          targetGroupUid: this.targetGroupId,
        },
      }).then(() => {
        this.showSelectGroup = false;
        this.$utils.showMsg(this.$t("groupMoveSuccess"));
        this.userAccountContacts();
      });
    },
    openSelectGroup() {
      console.log('this.targetData', this.targetData)
      if (this.targetData.parentGroupId === "-999") {
        this.targetData.parentGroupId = null
      }
      this.targetGroupId = this.targetData.parentGroupId;
      this.showSelectGroup = true;
      console.log('this.targetGroupId', this.targetGroupId)
      console.log('this.copyNodes[1].children', this.copyNodes[1].children)
    },
    userAccountGroupDeleteOutside() {
      this.$parent.getOnresize()
      this.$utils
        .confirm({
          content: this.$t("delGroupConfirm"),
        })
        .then(() => {
          this.$ajax({
            url: this.$api.userAccountGroupDeleteOutside,
            data: {
              uid: this.targetData.uid,
            },
          }).then(() => {
            this.$utils.showMsg(this.$t("deleteSuccess"));
            this.userAccountContacts();
          });
        })
        .catch(() => {});
    },
    confirmAddGroup() {
      if (!this.groupName) {
        this.$utils.showMsg(this.$t("enterGroupName"), "warning");
        return;
      }
      const url =
        this.groupType === "add"
          ? this.$api.userAccountGroupAddOutside
          : this.$api.userAccountGroupUpdateOutside;
      const params = {
        name: this.groupName,
      };
      if (this.groupType === "edit") {
        params.groupUid = this.targetData.uid;
      }
      this.$ajax({
        url,
        data: params,
      }).then(() => {
        this.groupType === "add"
          ? this.$utils.showMsg(this.$t("groupAddSuccess"))
          : this.$utils.showMsg(this.$t("nameEditSuccess"));
        this.showAddGroup = false;
        this.userAccountContacts();
      });
    },
    openAddGroup(groupType) {
      this.groupType = groupType;
      if (this.groupType === "add") {
        this.groupName = "";
      } else {
        this.groupName = this.targetData.name;
      }
      this.showAddGroup = true;
    },
    // 外部联系人分组-------------------
    nodeContextmenu(e, data) {
      console.log(data)
      if (data.uid === "-999") return
      if (data.parentGroupId) {
        this.selectedGroupId = data.parentGroupId
      }
      this.showCurrenttGroupName = data.accountId
      const clientHeight = document.documentElement.clientHeight;
      this.pageY = e.pageY;
      this.showModelType = "addGroup";
      this.targetData = data;
      if (data.nodeType === "c") {
        this.showModelType = "addGroup";
        if (this.pageY + 40 > clientHeight) {
          this.pageY = clientHeight - 50;
        }
      } else if (data.parentType === "c" && data.nodeType === "GROUP") {
        this.showModelType = "groupOption";
        if (this.pageY + 80 > clientHeight) {
          this.pageY = clientHeight - 90;
        }
      } else if (data.parentType === "c" && data.nodeType === "ACCOUNT") {
        this.showModelType = "contactOption";
        if (this.pageY + 120 > clientHeight) {
          this.pageY = clientHeight - 130;
        }
      }
    },
    closeSendApply() {
      this.showSendApply = false
    },
    // 添加外部联系人-------------------
    confirmSendApply() {
      this.showSendApply = false;
      this.$ajax({
        url: this.$api.userAccountApplyAddContacts,
        data: {
          targetUserAccountId: this.value,
        },
      }).then(() => {
        this.$utils.showMsg(this.$t("addContactSend"));
      });
    },
    openExternalContacts() {
      this.value = "";
      this.showExternalContacts = true;
    },
    // 添加联系人
    confirmExternalContacts() {
      if (!this.value) {
        this.$utils.showMsg(this.$t("enterAccount"), "warning");
        return;
      }
      this.$ajax({
        url: this.$api.userAccountFindInfo,
        data: {
          accountId: this.value,
        },
      })
        .then((data) => {
          if (!data) {
            this.$utils.showMsg(this.$t("notFundContact"), "warning");
            return;
          }
          this.contactData = data;
          this.showExternalContacts = false;
          this.showSendApply = true;
          // 兼容性
          this.$nextTick(() => {
            const messageResize = document.getElementsByClassName('baseDialog')[0]
            const docHeight = document.body.clientHeight
            const docWidth = document.body.clientWidth
            const standarHeight = docHeight / 1080
            console.log(docWidth)
            if (docWidth < 1680) {
              if (messageResize) {
                messageResize.childNodes[0].style.zoom = standarHeight;
              }
            }
          })
        })
        .then(() => {});
    },
    // 监听添加联系人结果
    onRecvUserMsg(data) {
      // 下发系统消息
      if (data.type === "APPLY_ADD_CONTACTS_RESULT") {
        if (data.body.status === "PASS") {
          this.userAccountContacts();
        }
      }
      // 下发删除好友消息
      if (data.type === "UPDATE_INVITE_BY_API" || data.type === "DELETE_CONTACTS") {
        this.$parent.getOnlineCenterUser()
        this.userAccountContacts();
      }
      // 点对点文件推送下发
      if (data.type === "PUSH_USER_ACCOUNT_FILE") {
        const elmessagebox = document.getElementById("meetingConfirmID")
        console.log(this.inviteFileTime)
        if (elmessagebox) {
          this.$msgbox.close();
        }
        if (this.inviteFileTime) {
          clearInterval(this.inviteFileTime)
          this.inviteFileTime = null
          this.$msgbox.close()
        }
        this.openGetPushFileRecord(data.body)
        console.log("$$$$$点对点文件推送下发$$$$$$")
      }
    },
    // 获取联系人
    userAccountContacts(needHandleOContacts) {
      this.$noLoading({
        url: this.$api.userAccountContacts,
      }).then((data) => {
        console.log(data)
        if (data.c && data.c.length) {
          data.c.forEach((item) => {
            item.parentType = "c";
            if (item.children && item.children.length) {
              item.children.forEach((list) => {
                list.parentType = "c";
                list.parentGroupId = item.uid;
                list.value = "";
              });
            }
          });
        }
        const nodes = [
          {
            id: "b",
            name: this.$t("companyContacts"),
            children: data.b,
            nodeType: "b",
          },
          {
            id: "c",
            name: this.$t("externalContacts"),
            children: data.c,
            nodeType: "c",
          },
          {
            id: "a",
            name: this.$t("platformContacts"),
            children: data.a,
            nodeType: "a",
          },
        ];

        this.copyNodes = this.$utils.copyData(nodes);
        // this.copyNodes = this.newNodes
        this.copyNodes[1].children.filter(item => {
          if (item.uid === "-999") {
            item.uid = null
          }
        })
        this.merenArr = {
          accountId: null,
          accountNickname: null,
          canAddOutsideUserAccount: null,
          children: null,
          companyId: null,
          companyName: null,
          headImg: null,
          id: null,
          lastLoginDeviceType: null,
          name: this.$t('defaultDrouping'),
          nodeType: "GROUP",
          onlineStatus: null,
          parentType: "c",
          userAccountType: null,
        }
        this.copyNodes[1].children.filter(item => {
          return item.id === null
        }).filter(res => {
          res.children = null
        })
        const panduan = this.copyNodes[1].children.filter(item => {
          return item.id === null
        })
        if (panduan.length === 0) {
          this.copyNodes[1].children.unshift(this.$utils.copyData(this.merenArr))
        }
        if (nodes[1].children && nodes[1].children.length) {
          nodes[1].children.forEach((item) => {
            if (item.children) {
              this.$utils.nodupchang(item.children, 'companyName')
              // console.log(getGroupArr)
            }
          });
        }
        this.nodes = nodes;
        console.log(this.nodes)
        if (!this.nodes.length) return;
        const searchUsers = (data) => {
          data.forEach((item) => {
            if (item.nodeType === "ACCOUNT" && item.onlineStatus === "YES") {
              this.ownOnlineUserList.push(item.accountId);
            }
            if (item.children && item.children.length) {
              searchUsers(item.children);
            }
          });
        };
        searchUsers(this.$utils.copyData(this.nodes));
        // 刷新外部联系人管理
        if (needHandleOContacts) {
          this.cDataLists = JSON.parse(
            JSON.stringify(this.copyNodes[1].children || [])
          );
          this.cDataLists.forEach((list) => {
            if (this.selectedGroupId === list.id) {
              this.tableData = list.children || [];
            }
          });
          this.tableData.forEach((item) => {
            item.value = this.selectedGroupId;
          });
        }
      });
    },

    searchBlur() {
      this.noDataShow = ""
      if (!this.searchStr) {
        this.searchUser = true
      }
      if (this.searchStr && this.searchUserList.length === 0) {
        this.noDataShow = this.$t('noData')
      }
      this.userAccountContacts();
    },
    // 搜索联系人
    searchContact(value) {
      this.noDataShow = ""
      this.searchUserList = []
      this.$utils.debounce(() => {
        const copyNodes = this.$utils.copyData(this.copyNodes);
        if (!copyNodes.length) return;
        // 还原数据
        if (!value) {
          this.nodes = copyNodes;
          return;
        }
        // 查找搜索人员
        const users = [];
        const searchUsers = (data) => {
          data.forEach((item) => {
            this.searchUser = true
            this.noDataShow = ""
            if (
              (item.accountId && item.accountId.indexOf(value) >= 0) ||
              (item.name && item.name.indexOf(value) >= 0)
            ) {
              users.push(item);
            }
            if (item.children && item.children.length) {
              searchUsers(item.children);
            }
          });
        };
        searchUsers(copyNodes);
        if (users && users.length) {
          this.searchUserList = this.$utils.copyData(users).filter(item => {
            return item.nodeType === "ACCOUNT"
          })
          this.searchUserList = this.$utils.sortByKeyAsc(this.searchUserList, 'id')
          this.searchUser = false
          this.searchUserList.sort((a) => {
            if (this.onlineUserList.indexOf(a.accountId) >= 0 && this.ownOnlineUserList.indexOf(a.accountId) >= 0) {
              return -1;
            }
            return 1;
          });
        }
        this.nodes = users;
        if (this.searchStr && this.searchUserList.length === 0) {
          this.noDataShow = this.$t('noData')
        }
      });
    },
    // 邀请联系人
    invite(item) {
      console.log(item)
      this.$emit("invite", item);
    },
    // -------------------------------------------------预约-----------------------------------------
    // 创建预约指导
    confirmRemoteGuides() {
      if (!this.ruleForm.guidetitle) {
        this.$utils.showMsg(this.$t("enterGuideAccount"), "warning");
        return;
      }
      if (!this.ruleForm.startDay) {
        this.$utils.showMsg(this.$t("GuideAccountStartDay"), "warning");
        return;
      }
      if (!this.ruleForm.startHours) {
        this.$utils.showMsg(this.$t("GuideAccountStartHours"), "warning");
        return;
      }
      if (!this.ruleForm.endDay) {
        this.$utils.showMsg(this.$t("GuideAccountendDay"), "warning");
        return;
      }
      if (!this.ruleForm.endHours) {
        this.$utils.showMsg(this.$t("GuideAccountendHours"), "warning");
        return;
      }

      // 开始日期
      const startTimed = this.$utils.format(this.ruleForm.startDay, 'yyyy-MM-dd')
      const startTimeh = this.ruleForm.startHours
      const startTamp = this.$utils.changeTimestamp(startTimed, startTimeh)
      // // 结束日期
      const endTimed = this.$utils.format(this.ruleForm.endDay, 'yyyy-MM-dd')
      const endTTimeh = this.ruleForm.endHours
      const endTamp = this.$utils.changeTimestamp(endTimed, endTTimeh)

      if (endTamp < startTamp) {
        this.$utils.showMsg(this.$t('timeCannotEnd'), "warning");
        return;
      }
      if (endTamp === startTamp) {
        this.$utils.showMsg(this.$t('timeCannotBegin'), "warning");
        return;
      }

      const _hours = Math.floor((endTamp - startTamp) / (3600 * 1000));
      if (_hours >= 24) {
        this.$utils.showMsg(this.$t('timeCannot'), "warning");
        return;
      }

      this.$ajax({
        url: this.$api.createAppointmentMeeting,
        data: {
          meetingTheme: this.ruleForm.guidetitle,
          startTime: startTamp,
          endTime: endTamp,
          timeZone: 8,
        },
      })
        .then((data) => {
          // console.log(data)
          this.userAppointmentMeeting();
          this.isshowReserved = true;
          this.ruleForm.guidetitle = "";
        })
        .catch((e) => {
          // console.log(e)
        });
    },
    // 获取当前自己已经创建的预约信息
    userAppointmentMeeting() {
      this.$ajax({
        url: this.$api.getAppointmentMeetingRecord,
      }).then((data) => {
        this.meetingList = data
        this.meetingList = this.$utils.sortByKeyAsc(data, 'id');
        if (this.meetingList.length === 0) {
          this.isshowReserved = false
        }
      });
    },
    // 获取当前自己参与的预约会议记录
    getMeetingRecordForAppointment() {
      this.$ajax({
        url: this.$api.getMeetingRecordForAppointment,
      }).then((data) => {
        console.log(this.addMeetingList)
      });
    },
    // 添加预约指导隐藏显示
    openRemoteGuideOut() {
      this.ruleForm.guidetitle = "";
      this.showRemoteGuide = true;
      this.initDate()
      // this.startHoursList = this.$utils.giveStartHoursStart()
      // this.ruleForm.startHours = this.$utils.giveStartHours()
      // this.ruleForm.endHours = this.$utils.giveEndHours()
    },
    // 检测正在进行且未加入远程指导的预约
    checkMetting() {
      this.isshowReserved = true
      this.inviteMettingTimer = setInterval(() => {
        if (this.meetingList && this.meetingList.length) {
          const tempArr = this.$utils.sortByKeyAsc(this.meetingList, 'id')
          console.log(this.meetingList)
          // 弹出邀请等待弹框
          if (tempArr[0].startTime < new Date().getTime()) {
            this.$parent.getOnresize()
            this.$utils
              .confirmCss({
                title: this.$t("msg"),
                content: `<div class="meetingBox" id="meetingConfirmID"><div class="meetingBoxTitle">${this.$t("enterRoom")}</div><div class="meetingBoxTxt"><p>${this.$t("theme")}：${tempArr[0].theme}</p><p>${this.$t("timer")}：${this.$utils.format(tempArr[0].startTime, "yyyy-MM-dd hh:mm")} -  ${this.$utils.format(tempArr[0].validTime, "MM-dd hh:mm")} <span>GMT 08:00</span></p></div></div>`,
                showClose: true,
                customClass: 'mettingCustomClass',
                confirmText: this.$t("enter"),
                cancelText: this.$t("ignore"),
              })
              .then(() => {
                this.$ajax({
                  url: this.$api.getRoomUniqueNoByInvitation,
                  data: {
                    invitation: this.meetingList[0].roomIntegerNumber,
                  },
                }).then((data) => {
                  const res = {
                    body: {
                      roomUniqueNo: data.uniqueNo
                    }
                  }
                  this.$parent.addinviteRoomResponse(res)
                  clearInterval(this.inviteMettingTimer)
                  this.inviteMettingTimer = null
                })
              })
              .catch((e) => {
                clearInterval(this.inviteMettingTimer)
                this.inviteMettingTimer = null
              });
            clearInterval(this.inviteMettingTimer)
            this.inviteMettingTimer = null
          }
        }
      }, 1000)
    },
    // 根据邀请链接或者会议数字编号获取会议信息
    addAppointmentMeeting() {
      if (!this.appointmentMeetingID) {
        this.$utils.showMsg(this.$t("enterAppointmentMeetingID"), "warning")
        return
      }
      this.$noCall({
        url: this.$api.getRoomUniqueNoByInvitation,
        data: {
          invitation: this.appointmentMeetingID,
        },
      }).then((data) => {
        console.log(data.msg)
        this.getMeetingRecordForAppointment()
        this.showAppointmentMeeting = false
        const res = {
          body: {
            roomUniqueNo: data.uniqueNo
          }
        }
        if (data) {
          this.$parent.addinviteRoomResponse(res)
        }
      }).catch(err => {
        if (err.msg === 'DATA_000001') {
          this.$utils.showMsg(this.$t("enterCorrectAppointmentMeetingID"), "warning")
        }
        if (err.msg === 'INVITATION_MEETING_IS_LOCAK') {
          this.$utils.showMsg(this.$t("INVITATION_MEETING_IS_LOCAK"), "warning")
        }
      })
    },
    closeAppointmentMeeting() {
      this.appointmentMeetingID = ""
      this.showAppointmentMeeting = false
    },
    openAppointmentMeeting() {
      this.appointmentMeetingID = ""
      this.showAppointmentMeeting = true
    },
    // 取消预约
    cancelMeeting(id) {
      this.$ajax({
        url: this.$api.cancelAppointmentMeeting,
        data: {
          id: id,
        },
      }).then((data) => {
        this.userAppointmentMeeting();
      });
    },
    // 查看预约
    openReserve() {
      this.showSeeReserve = true;
    },
    // 进入会议
    enterMetting(roomID) {
      console.log(111111)
      this.$ajax({
        url: this.$api.getRoomUniqueNoByInvitation,
        data: {
          invitation: roomID,
        },
      }).then((data) => {
        console.log('enterMetting', data)
        const res = {
          body: {
            roomUniqueNo: data.uniqueNo
          }
        }
        this.$parent.addinviteRoomResponse(res)
        this.showRemoteGuide = false
        this.showAppointmentMeeting = false
      })
    },

    // -------------------文件上传---------------------------
    // 打开文件上传弹窗
    openUploadFileVisible(item) {
      console.log(item)
      this.fileSize = ''
      this.isUploadFile = true
      this.uploadFileVisibleShow = true
      this.uploadToName = item.name
      this.uploadID = item.accountId
      this.uploadToAccountId = item.uid
      this.uploadCompanyName = item.companyName
      this.uploadAccountNickname = item.accountNickname
      this.uploadName = item.name
      this.progressFlag = false
      this.progressPercent = 0
    },
    // 删除文件
    uploadFileRemove(file, fileList) {
      this.isUploadFile = true
      this.fileSize = ''
    },
    handleChange(file, fileList) {
      if (file.status === "ready") {
        if (fileList.length === 2) {
          fileList.shift()
        }
        this.fileSize = this.$utils.changeFileSize(file.size)
        if (file.size / (1024 * 1024) > 50) {
          // 限制文件大小
          // this.isUploadFile = false
          this.$nextTick(() => {
            const elicon = document.getElementsByClassName('el-icon-document')[0]
            if (this.$utils.fileSuffixType(file.name) === "doc") {
              elicon.classList.replace('el-icon-document', 'uploadIconDocx')
            }
            if (this.$utils.fileSuffixType(file.name) === "xls") {
              elicon.classList.replace('el-icon-document', 'uploadIconXls')
            }
            if (this.$utils.fileSuffixType(file.name) === "pdf") {
              elicon.classList.replace('el-icon-document', 'uploadIconPdf')
            }
            if (this.$utils.fileSuffixType(file.name) === "ppt") {
              elicon.classList.replace('el-icon-document', 'uploadIconPpt')
            }
            const fileOnSend = document.getElementById('fileOnSend')
            fileOnSend.setAttribute("disabled", true)
            fileOnSend.style.backgroundColor = '#ccc'
            this.$utils.showMsg('当前限制文件大小不能大于50M', "warning");
          })
        } else {
          this.$nextTick(() => {
            const elicon = document.getElementsByClassName('el-icon-document')[0]
            if (this.$utils.fileSuffixType(file.name) === "doc") {
              elicon.classList.replace('el-icon-document', 'uploadIconDocx')
            }
            if (this.$utils.fileSuffixType(file.name) === "xls") {
              elicon.classList.replace('el-icon-document', 'uploadIconXls')
            }
            if (this.$utils.fileSuffixType(file.name) === "pdf") {
              elicon.classList.replace('el-icon-document', 'uploadIconPdf')
            }
            if (this.$utils.fileSuffixType(file.name) === "ppt") {
              elicon.classList.replace('el-icon-document', 'uploadIconPpt')
            }
            const fileOnSend = document.getElementById('fileOnSend')
            fileOnSend.removeAttribute("disabled")
            fileOnSend.style.backgroundColor = '#0466B2'
          })
        }
        this.isUploadFile = false
      }
      if (file.status === "200") {
      }
    },
    uploadCancal() {
      this.isUploadFile = true
      this.uploadFileVisibleShow = false
    },
    // 发送文件上传文件
    uploadFileSubmit() {
      if (this.isUploadFile) {
        this.$utils.showMsg(this.$t("uploadFileTitle"), "warning");
        return
      }
      if (this.inviteFileTime) {
        clearInterval(this.inviteFileTime)
        this.$msgbox.close()
        this.inviteFileTime = null
      }
      this.$nextTick(() => {
        const fileOnSend = document.getElementById('fileOnSend')
        fileOnSend.setAttribute("disabled", true)
        fileOnSend.style.backgroundColor = '#ccc'
      })
      this.$refs.uploadFile.submit()
    },
    // 调用上传文件接口
    uploadFileLoad(info) {
      const that = this
      that.progressFlag = true
      that.progressPercent = 0
      const uploadFile = new FormData()
      // 添加参数
      console.log('that', that)
      uploadFile.append('file', info.file)
      uploadFile.append('userAccountUid', that.uploadToAccountId)
      that.$noLoading({
        url: that.$api.uploadPushFile,
        data: uploadFile,
        timeout: 1000000,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: progressEvent => {
          if (progressEvent.lengthComputable) {
            that.progressPercent = parseInt((progressEvent.loaded / progressEvent.total) * 100);
          }
        },
      }).then((data) => {
        // this.$parent.getUserAccount() // 更新图表
        console.log(data)
        if (that.videoUploadPercent === 100) {
          that.videoUploadPercent = 0
        }
        that.uploadFileVisibleShow = false
        const fileOnSend = document.getElementById('fileOnSend')
        fileOnSend.removeAttribute("disabled")
        fileOnSend.style.backgroundColor = '#0466B2'
        // this.getPushFileRecord()
        that.$utils.showMsg(this.$t("sendUploadFileTitle"), "warning");
      })
    },
    // 获取文件推送记录
    getPushFileRecord() {
      this.$ajax({
        url: this.$api.getPushFileRecord,
      }).then((data) => {
        console.log(data)
      })
    },
    // 点对点接受推送弹窗
    openGetPushFileRecord(data) {
      if (!data.isBackOut) { // 推送方已撤回 接收方要要删除
        let fileseconds = 30;
        let fileCss = ""
        if (this.$utils.fileSuffixType(data.fileName) === "doc") {
          fileCss = "fileIconDocx"
        }
        if (this.$utils.fileSuffixType(data.fileName) === "xls") {
          fileCss = "fileIconXls"
        }
        if (this.$utils.fileSuffixType(data.fileName) === "pdf") {
          fileCss = "fileIconPdf"
        }
        if (this.$utils.fileSuffixType(data.fileName) === "ppt") {
          fileCss = "fileIconPpt"
        }
        this.$parent.getOnresize()
        this.$utils
          .confirmCss({
            title: this.$t("uploadTitle"),
            content: `<div class="uploadBox">
            <div class="uploadBoxItem">
            <div class="itemBox">
              <span class="iconBox ${fileCss}"></span>
              ${data.fileName}
              </div>
            </div>
            <div class="uploadBoxTop">${this.$t("uploadIsRead")}（<span id="uploadFileCofirm">${fileseconds}</span>s）</div>
            </div>`,
            customClass: 'uploadCustomClass',
            showClose: true,
            cancelText: this.$t("uploadClose"),
            confirmText: this.$t("uploadPreview"),
          })
          .then(() => {
            clearInterval(this.inviteFileTime)
            this.inviteFileTime = null
            this.$ajax({
              url: this.$api.getKkFileViewForPushFile,
              data: {
                pushFileId: data.pushFileRecordId
              }
            }).then((data) => {
              this.uploadFileData = data
              this.showReadFile = true
              this.showFileName = data.fileName
              this.officeLink = data.kkFileViewUrl
            })
          })
          .catch(() => {
            clearInterval(this.inviteFileTime)
            this.inviteFileTime = null
          });
        this.$nextTick(() => {
          const uploadFileCofirm = document.getElementById("uploadFileCofirm");
          this.inviteFileTime = setInterval(() => {
            if (fileseconds <= 1) {
              clearInterval(this.inviteFileTime)
              this.inviteFileTime = null
            }
            fileseconds--;
            uploadFileCofirm.innerHTML = fileseconds;
          }, 1000);
        })
      }

      if (data.isBackOut === "YES") { // 推送方已撤回 接收方要要删除
      // 删除文件推送
        this.$ajax({
          url: this.$api.delPushFileRecord,
          data: {
            pushFileId: data.pushFileRecordId
          }
        }).then((data) => {
        })
      }
    },
    uploadLink(item) {
      // https://tmvafiles.tmva-cn.com 沙盒
      // https://tmvafiles.team-view.cn 测试
      // https://tmvafiles.ar-office.com 正式
      const imgOs = item.fileUrl.replace(process.env.VUE_APP_FILEDOWN_HOST, 'fliedown')
      var x = new XMLHttpRequest();
      x.open("GET", imgOs, true);
      x.responseType = 'blob';
      x.onload = function(e) {
        var url = window.URL.createObjectURL(x.response)
        var a = document.createElement('a');
        a.href = url
        a.download = item.fileName;
        a.click()
      }
      x.send();
    },
    // 左侧菜单
    getMenuShowA(res) {
      if (res === 'b') this.leftShowB = !this.leftShowB
      if (res === 'c') this.leftShowC = !this.leftShowC
      if (res === 'a') this.leftShowA = !this.leftShowA
    },
    getGgroupShow(res) {
      console.log(res)
      const imgCss = 'img' + res
      if (this.$refs[imgCss][0].classList[0] === 'childImgNone') {
        this.$refs[imgCss][0].classList.replace('childImgNone', 'childImg')
      } else if (this.$refs[imgCss][0].classList[0] === 'childImg') {
        this.$refs[imgCss][0].classList.replace('childImg', 'childImgNone')
      }
      if (this.$refs[res]) {
        if (this.$refs[res][0]) {
          if (this.$refs[res][0].classList[1] === 'childShow') {
            this.$refs[res][0].classList.replace('childShow', 'childHide')
          } else if (this.$refs[res][0].classList[1] === 'childHide') {
            this.$refs[res][0].classList.replace('childHide', 'childShow')
          }
        }
      }
    },
    getSearchShow() {
      this.leftSearchShow = !this.leftSearchShow
    }
  }
};
</script>

<style lang="scss">
.showMeeting{
  .el-dialog {
    border-radius: 6px;
  }
  .el-dialog__header{
    border-radius: 6px 6px 0 0;
  }
  .el-dialog__header{
    padding: 10px 15px;
    background-color: #F0F0F0;
    .el-dialog__title{
      font-size: 14px;
    }
    .el-dialog__headerbtn {
      top: 15px;
    }
  }
  .el-dialog__body{
    padding:15px;
  }
  .showMeetingFooter{
    text-align: center;
    margin-top: 15px;
    padding-bottom: 10px;
      .el-button {
        width: 80px;
        text-align: center;
        border-radius: 6px;
        margin: 0 10px;
        color: #fff;
        border: none;
      &:last-child {
        background-color: #0466B2;
      }

      &:first-child {
        color: #333;
        border: solid 1px #E6E8EA;
        // background-color: #ff4d4f;
      }
    }
  }
}
 .uploader-example {
    width: 400px;
    padding: 15px;
    margin: 40px auto 0;
    font-size: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .4);
  }
  .uploader-example .uploader-btn {
    margin-right: 4px;
  }
  .uploader-example .uploader-list {
    max-height: 440px;
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
  }

.showAddGroupBox{
  margin-top: 10px;
}
.remoteGuide{
  padding: 0px 15px 20px 15px;
  .el-form-item__label{
    color: #252A3B;
    margin-bottom: -10px;
  }
  .remoteGuideText{
    height: 18px;
    font-size: 14px;
    font-weight: 400;
    color: #252A3B;
    line-height: 18px;
  }
  .bottomText{
    margin-bottom: 15px;margin-top: -10px;
  }
}
.remoteGuideBottom{
  padding: 0px 15px 0px 15px;
  margin-top: -20px;
}
.groupTitle{
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 10px;
}
.scopeLogo{
  width: 30px;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 5px;
}
.groupList{
  padding-bottom: 8px;
}
.groupListNodata{
  text-align: center;
  padding: 20px;
}
.showCurrenttGroupName{
  line-height: 30px;
  height: 30px;
  padding-left: 3px;
  font-size: 13px;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 3px;
}
.selectShowSelectGroup{
  padding-bottom: 10px;
   .selectGroup{
    font-size: 14px;
    padding-bottom: 5px;
    font-weight: 600;
  }
}
.childHide{
  display: none;
}
.childShow{
  display: block;
}
.contactsDialog {
  .el-dialog{
    width: 476px;
    border-radius: 6px;
  }
  .el-dialog__header {
    background-color: #F0F0F0;
    border-radius: 6px 6px 0 0;
    .el-dialog__title{
      font-size: 14px;
      padding-left: 5px;
    }
  }
}
.dropdownBox {
  position: relative;
  display: inline-block;
  .el-icon-circle-plus-outline {
    font-size: 30px;
    padding-top: 3px;
  }
}
.dropdown-content {
  display: block;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  z-index: 11;
  top: 10px;
  left: 60px;
  line-height: 30px;
  border-radius: 8px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  .dropdownItem{
    cursor: pointer;
    p {
        padding: 5px 0px 5px 35px;
    }
    &:hover {
      background: #E9F4FE;
      border-radius: 8px;
    }
  }
}
.dropdown:hover .dropdown-content {
  display: block;
}
.el-dialog__header {
    padding: 10px;
  }
.el-upload-list__item {
  transition: none !important;
}
.contactMTable {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);

  th .cell {
    font-weight: bold;
    font-size: 16px;
  }
}
.contactMTable {
  td {
    font-size: 14px;
  }
}
.groupListMlku {
  height: 42px;
  line-height: 42px;
  font-size: 14px;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0 10px;
  cursor: pointer;

  &:hover {
    background: #f1f1f5;
  }

  &.active {
    background: #525f6b;
    color: #fff;
  }
}
.outContact{
  position: fixed;
  left: 360px;
  top: 300px;
  z-index: 999;
  width: 190px;
  background: #73E000;
  box-shadow: 0px 0px 6px 0px rgba(110, 129, 139, 0.3);
}
.outContactOptionLists {
  position: fixed;
  left: 170px;
  top: 360px;
  z-index: 999;
  width: 130px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(110, 129, 139, 0.3);

  li {
    line-height: 40px;
    border-bottom: 1px solid #eee;
    padding: 0 10px;
    color: #525f6b;
    box-sizing: border-box;
    &:hover {
      cursor: pointer;
      background: #f1f1f5;
    }

    i {
      font-size: 16px;
      vertical-align: middle;
    }

    span {
      padding-left: 10px;
      font-size: 14px;
      padding: 5px 10px;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
.externalContact {
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  .el-input {
    width: 100%;
  }
}

.sendApply {
  margin-left: 40px;
  margin-top: 20px;
  p {
    height: 14px;
    font-size: 14px;
    font-weight: 400;
    padding: 5px 0;
    color: #000;
    line-height: 14px;
  }
  .sendApplyImg {
    width: 50px;
     height: 50px;
     border-radius: 50%
  }
  .dialog-footer{
    border-top: solid 1px #E6E8EA;
    text-align: right;
  }
}
.footBtn{
    text-align: right;
    margin-top: 10px;
    padding-top: 15px;
    padding-right: 25px;
    border-top: solid 1px #DFDFDF;
  }

.leftNavs {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 300px;
  transition: all 0.3s;
  box-sizing: border-box;
  // background: #525f6b;

  &.active {
    left: -300px;
  }

  .listFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    line-height: 35px;

    .onlineText {
      display: none;
      color: #73e000;
    }

    .nickName {
      position: relative;
      padding-left: 15px;

      &:after {
        content: "";
        display: block;
        width: 6px;
        height: 6px;
        background-color: #9cadbd;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    // .leftSubRight {
    //   width: 80px;
    //   height: 20px;
    //   display: inline-block;
    //   vertical-align: middle;

    //   .uploadIcon {
    //     width: 18px;
    //     height: 18px;
    //     float: left;
    //     margin-left: 8px;
    //     background: url('./img/upload.png') no-repeat center;
    //   }
    //   .cameraIcon {
    //     width: 20px;
    //     height: 20px;
    //     float: left;
    //     background: url("./img/camera.png") no-repeat center;
    //   }
    // }

    &.active {
      .onlineText {
        display: inline;
      }
      .uploadIcon{
        background: url("./img/upload_active.png") no-repeat center !important;
      }
      .cameraIcon {
        background: url("./img/camera_active.png") no-repeat center !important;
      }
      .nickName {
        &:after {
          background-color: #73e000 !important;
        }
      }
    }
  }

  // .el-tree {
  //   color: #ffffff;
  //   background: #363F48;
  // }
  // .el-tree-node__content{
  //   padding-left: 20px !important;
  //   height: 52px;
  //   .el-tree-node__children{
  //      background:#6E818B;
  //      height: 40px;
  //   }
  //   .is-expanded{
  //     background:#6E818B;
  //   }
  //   &:hover {
  //     background-color: #6e818b;
  //     .nickName:after {
  //       background-color: #525f6b;
  //     }
  //     .leftSubRight {
  //       .cameraIcon {
  //         // background: url('./img/camera_black.png') no-repeat center;
  //       }
  //     }
  //   }
  // }
  // .el-tree-node__expand-icon{
  //   position: absolute;
  //   right: 2%;
  // }

  // .el-tree-node:focus > .el-tree-node__content {
  //   background: #525f6b;
  // }

  .leftSearch {
    width: 100%;
    height: 76px;
    box-sizing: border-box;
    padding: 19px 30px;
    background: #6E828C;
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #ccc;
    width: 100px;
    text-align: center;
    margin: 0 20px;
    &:hover {
      opacity: 0.8;
      color: #409eff;
    }
  }
  .line{
    width: 100%;
    background: #6E818B;
    height: 1px;
  }
  .leftContent {
    position: absolute;
    left: 0;
    top: 146px;
    right: 0;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    background: #6E818B;
    .noDatacontent{
      color: #fff;
      margin: 20px;
      padding: 20px;
    }
    .searchBox{
      color: #fff;
      .searchBTitleBox{
        background: #363F48;
        height: 52px;
        color: #ffffff;
        cursor: pointer;
       .searchBoxTitle{
          margin-left: 25px;
          width: 128px;
          height: 22px;
          font-size: 16px;
          float: left;
          padding-top: 18px;
      }
      .titleImgNone{
        float: right;
        padding: 18px;
        height: 13px;
        background: url('./img/leftnumenone.png') no-repeat center center;
        background-size: 13px 8px ;
      }
      .titleImg{
        float: right;
        padding: 18px;
        height: 13px;
        background: url('./img/leftnume.png') no-repeat center center;
        background-size: 8px 13px;
      }
    }
      .searchListBox{
        height: 40px;
        line-height: 40px;
        border-bottom: #9EACB3 1px solid;
        .titleline {
          margin-left: 20px;
          width: 100%;
          cursor: pointer;
          .nametitleleft{
            float: left;
            line-height: 40px;
            width: 200px;
            overflow:hidden;
            font-size: 14px;
            white-space: nowrap;
            text-overflow:ellipsis;
            &::before{
              content: '';
              margin-right: 14px;
              display: table;
              width: 6px;
              height: 6px;
              background: #9CADBD;
              border-radius: 50%;
              display: inline-block;
              vertical-align: middle;
            }
          }
          .nametitleright {
            float: left;
            width: 65px;
            height: 20px;
            .uploadIcon {
              width: 20px;
              height: 20px;
              float: left;
              padding: 11px 0;
              margin-left: 10px;
              background: url('./img/upload.png') no-repeat center;
              background-size: 20px 20px;
            }
            .cameraIcon {
              width: 16px;
              height: 19px;
              float: left;
              padding: 11px 0;
              background: url("./img/camera.png") no-repeat center;
              background-size: 16px 19px;
            }
          }
          &.active {
            background: #6E818B;
            color: #fff;
            .nametitleleft{
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              &::before{
                content: '';
                margin-right: 14px;
                width: 6px;
                height: 6px;
                background: #73E000;
                border-radius: 50%;
                display: inline-block;
                vertical-align: middle;
              }
            }
            .cameraIcon {
              width: 16px;
              height: 19px;
              float: left;
              padding: 11px 0px;
              background: url("./img/camera_active.png") no-repeat center;
              background-size: 16px 19px;
            }
            .uploadIcon {
              width: 20px;
              height: 20px;
              float: left;
              padding: 11px 0px;
              margin-left: 10px;
              background: url('./img/upload_active.png') no-repeat center;
              background-size: 20px 20px;
            }
            &:hover{
              opacity:1;
              // background: #9EACB3;
              .nametitleright {
                float: left;
                width: 65px;
                height: 20px;
                .uploadIcon {
                  width: 20px;
                  height: 20px;
                  float: left;
                  padding: 11px 0px;
                  margin-left: 10px;
                  background: url('./img/upload_active.png') no-repeat center;
                  background-size: 20px 20px;
                }
              .cameraIcon {
                width: 16px;
                height: 19px;
                float: left;
                padding: 11px 0px;
                background: url("./img/camera_active.png") no-repeat center;
                background-size: 16px 19px;
              }
            }
            }
          }
        }
        &:hover{
          opacity: 0.8;
          background: #9EACB3;
          .nametitleleft{
            float: left;
            line-height: 40px;
            width: 200px;
            overflow:hidden;
            white-space: nowrap;
            text-overflow:ellipsis;
            &::before{
              content: '';
              margin-right: 14px;
              width: 6px;
              height: 6px;
              background: #576470;
              border-radius: 50%;
              display: inline-block;
              vertical-align: middle;
            }
          }
          .nametitleright {
            float: left;
            width: 65px;
            height: 20px;
            .uploadIcon {
              width: 20px;
              height: 20px;
              float: left;
              padding: 11px 0px;
              margin-left: 10px;
              background: url('./img/upload_hover.png') no-repeat center;
              background-size: 20px 20px;
            }
            .cameraIcon {
              width: 16px;
              height: 19px;
              float: left;
              padding: 11px 0px;
              background: url("./img/camera_hover.png") no-repeat center;
              background-size: 16px 19px;
            }
          }
        }
      }
    }

    .leftTitle{
      .parantNodeCur, .parantNode{
        height: 52px;
        color: #ffffff;
        background: #363F48;
        cursor: pointer;
        border-bottom: 1px solid #6E818B;
       }
        .titleTxt{
          margin-left: 25px;
          width: 158px;
          height: 22px;
          font-size: 16px;
          float: left;
          padding-top: 18px;
        }
        .titleImg{
          float: right;
          padding: 18px;
          height: 13px;
          background: url('./img/leftnume.png') no-repeat center center;
          background-size: 8px 13px;
        }
        .titleImgNone {
          float: right;
          padding: 18px;
          height: 13px;
          background: url('./img/leftnumenone.png') no-repeat center center;
          background-size: 13px 8px ;
        }
      .childImg{
          float: right;
          padding: 10px 18px;
          height: 13px;
          background: url('./img/leftnume.png') no-repeat center center;
          background-size: 8px 13px;
        }
        .childImgNone {
          float: right;
          padding: 10px 18px;
          height: 13px;
          background: url('./img/leftnumenone.png') no-repeat center center;
          background-size: 13px 8px ;
        }

      .nodetitle{
        .childtitleBox{
          color: #ffffff;
          background: #48525D;
          height: 36px;
          line-height: 36px;
          border-bottom: #919293 1px solid;
          cursor: pointer;
        }
        .childtitle{
          font-size: 14px;
          height: 36px;
          float: left;
          width: 200px;
          line-height: 36px;
          padding-left: 31px;
        }
        .childGroup{
          background: #6E818B;
          color: #ffffff;
          .childGroupBox{
            background: #525F6B;
          }
          .childGroupBoxCompany{
            background: #626F79;
          }
          .childGroupBox, .childGroupBoxCompany{
            height: 40px;
            line-height: 40px;
            cursor: pointer;
            .childGroupBoxTitle{
              margin-left: 25px;
              width: 220px;
              line-height: 40px;
              margin-left: 36px;
              font-size: 14px;
              float: left;
              overflow:hidden;
              white-space: nowrap;
              text-overflow:ellipsis;
            }
          }
          .nametitle{
            height: 40px;
            line-height: 40px;
            cursor: pointer;
            .titleline {
              margin-left: 36px;
              border-bottom: #9EACB3 1px solid;
            }
            .nametitleleft{
               float: left;
               line-height: 40px;
               width: 180px;
               overflow:hidden;
               font-size: 14px;
               white-space: nowrap;
               text-overflow:ellipsis;
               &::before{
                content: '';
                margin-right: 14px;
                display: table;
                width: 6px;
                height: 6px;
                background: #9CADBD;
                border-radius: 50%;
                display: inline-block;
                vertical-align: middle;
               }
            }
            .nametitleright {
              float: right;
              width: 65px;
              height: 20px;
              .uploadIcon {
                width: 20px;
                height: 20px;
                float: left;
                padding: 11px 0;
                margin-left: 10px;
                background: url('./img/upload.png') no-repeat center;
                background-size: 20px 20px;
              }
              .cameraIcon {
                width: 16px;
                height: 19px;
                float: left;
                padding: 11px 0;
                background: url("./img/camera.png") no-repeat center;
                background-size: 16px 19px;
              }
            }
            &:hover{
              opacity: 0.8;
              background: #9EACB3;
              .nametitleleft{
                float: left;
                line-height: 40px;
                width: 180px;
                overflow:hidden;
                white-space: nowrap;
                text-overflow:ellipsis;
                &::before{
                  content: '';
                  margin-right: 14px;
                  width: 6px;
                  height: 6px;
                  background: #576470;
                  border-radius: 50%;
                  display: inline-block;
                  vertical-align: middle;
                }
              }

              .nametitleright {
                float: right;
                width: 65px;
                height: 20px;
                .uploadIcon {
                  width: 20px;
                  height: 20px;
                  float: left;
                  padding: 11px 0px;
                  margin-left: 10px;
                  background: url('./img/upload_hover.png') no-repeat center;
                  background-size: 20px 20px;
                }
                .cameraIcon {
                  width: 16px;
                  height: 19px;
                  float: left;
                  padding: 11px 0px;
                  background: url("./img/camera_hover.png") no-repeat center;
                  background-size: 16px 19px;
                }
              }
            }
            &.active {
              background: #6E818B;
              color: #fff;
              .nametitleleft{
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                &::before{
                  content: '';
                  margin-right: 14px;
                  width: 6px;
                  height: 6px;
                  background: #73E000;
                  border-radius: 50%;
                  display: inline-block;
                  vertical-align: middle;
                }
              }
              .cameraIcon {
                width: 16px;
                height: 19px;
                float: left;
                padding: 11px 0px;
                background: url("./img/camera_active.png") no-repeat center;
                background-size: 16px 19px;
              }
              .uploadIcon {
                width: 20px;
                height: 20px;
                float: left;
                padding: 11px 0px;
                margin-left: 10px;
                background: url('./img/upload_active.png') no-repeat center;
                background-size: 20px 20px;
              }
              &:hover{
                opacity:1;
                // background: #9EACB3;
                .nametitleright {
                  float: right;
                  width: 65px;
                  height: 20px;
                  .uploadIcon {
                    width: 20px;
                    height: 20px;
                    float: left;
                    padding: 11px 0px;
                    margin-left: 10px;
                    background: url('./img/upload_active.png') no-repeat center;
                    background-size: 20px 20px;
                  }
                .cameraIcon {
                  width: 16px;
                  height: 19px;
                  float: left;
                  padding: 11px 0px;
                  background: url("./img/camera_active.png") no-repeat center;
                  background-size: 16px 19px;
                }
              }
              }
            }

          }
        }
      }
    }

    &-inner {
      padding: 20px;
    }
    &::-webkit-scrollbar {
      display: none;
    }

  }

  .logo {
    width: 100%;
    height: 70px;
    background: #fff;

    img {
      height: 46px;
      margin-left: 22px;
      margin-top: 12px;
    }
  }
}

.guideBottom {
  width: 50%;
}
.el-date-editor--time-select {
  width: 150px !important;
}
.quidebutton {
  width: 100%;
}
.el-message-box__title {
  // font-size: 16px !important;
}
.el-message-box__message {
   font-size: 13px !important;
  // text-align: left !important;
  // margin-left: 40px !important;
}
.el-icon-folder-add {
  width: 20px;
}
.uploadCustomClass{
  width: 508px !important;
  .el-message-box__header{
    background-color: #F0F0F0;
    border-radius: 6px 6px 0 0;
  }
  .uploadBox{
      width: 100%;
      height: 150px;
      padding: 10px 0;
      box-sizing: border-box;
      font-size: 13px !important;
      margin-top: -15px !important;
      margin-bottom: 10px;
      .uploadBoxTop{
        text-align: center;
        font-size: 16px;
        margin-top: 25px;
        color: red;
      }
      .uploadBoxItem{
        width:100%;
        padding: 15px 0px;
        margin-top: 25px;
        .itemBox{
          width: 100%;
          font-size: 16px;
          &::before{
            content: '';
            display: inline-block;
            vertical-align: middle;
            clear: both;
          }
        }
        .fileIconDocx{
          background: url('./img/docx.png') no-repeat center center;
          display: inline-block;
          width: 25px;
          height: 25px;
          padding-right: 5px;
          &::before{
            content: '';
            display: inline-block;
            vertical-align: middle;
            clear: both;
          }
        }
        .fileIconXls{
          background: url('./img/xls.png') no-repeat center center;
          display: inline-block;
          width: 25px;
          height: 25px;
          padding-right: 5px;
          &::before{
            content: '';
            display: inline-block;
            vertical-align: middle;
            clear: both;
          }
        }
        .fileIconPdf{
          background: url('./img/pdf.png') no-repeat center center;
          display: inline-block;
          width: 25px;
          height: 25px;
          padding-right: 5px;
          &::before{
            content: '';
            display: inline-block;
            vertical-align: middle;
            clear: both;
          }
        }
        .fileIconPpt{
          background: url('./img/ppt.png') no-repeat center center;
          display: inline-block;
          width: 25px;
          height: 25px;
          padding-right: 5px;
          &::before{
            content: '';
            display: inline-block;
            vertical-align: middle;
            clear: both;
          }
        }
      }
  }

}

</style>
