/**
 * 公用api
 * */
import { socketHost } from './../config/path'
export const websocketUserAccount = `${socketHost}websocket/userAccount`; // 用户WebSocket连接
export const websocketRoom = `${socketHost}websocket/room`; // 会议室WebSocket连接
export const userAccountLogin = "userAccount/login"; // 登录
export const userAccountLogout = "userAccount/logout"; // 退出登录
export const userAccountContacts = "userAccount/contacts"; // 联系人列表
export const userAccountFindInfo = "userAccount/findInfo"; // 查询用户详情
export const roomGetRoomUniqueNo = "room/getRoomUniqueNo"; // 获取房间号
export const roomUserAccountInviteToRoom = "roomUserAccount/inviteToRoom"; // 邀请加入房间接口
export const roomUserAccountCancelInviteToRoom =
  "roomUserAccount/cancelInviteToRoom"; // 取消加入房间邀请接口
export const userAccountBatchGetAccountNickname =
  "userAccount/batchGetAccountNickname"; // 批量获取联系人昵称接口
export const userAccountSetNickname = "userAccount/setNickname"; // 设置联系人昵称
export const roomUserAccountApplyHost = "roomUserAccount/applyHost"; // 申请主讲
export const roomUserAccountGiveUpHost = "roomUserAccount/giveUpHost"; // 放弃主讲
export const roomUserAccountCancelHostApply = "roomUserAccount/cancelHostApply"; // 取消主讲申请
export const userAccountGetAccountType = "userAccount/getAccountType"; // 获取账号类型
export const equipmentGetEquipmentByWebCode = "equipment/getEquipmentByWebCode"; // oem通过二维码信息获取 设备/部件/故障信息
export const customEquipmentGetEquipmentByWebCode = "custom/equipment/getEquipmentByWebCode"; // oem通过二维码信息获取 设备/部件/故障信息
export const qrCodeScan = "qrCode/scan"; // tmva通过二维码信息获取 设备/部件/故障信息
export const customQrCodeScan = "custom/qrCode/scan"; // tmva通过二维码信息获取 设备/部件/故障信息
export const roomUserAccountGrantHost = "roomUserAccount/grantHost"; // 主讲授权
export const roomCheckStatus = "room/checkStatus"; // 查询会议室状态
export const createAppointmentMeeting = 'roomUserAccount/createAppointmentMeeting' // 创建预约会议
export const cancelAppointmentMeeting = 'roomUserAccount/cancelAppointmentMeeting' // 创建预约会议
export const getAppointmentMeetingRecord = 'roomUserAccount/getAppointmentMeetingRecord' // 获取当前自己已经创建的预约信息
export const getMeetingRecordForAppointment = 'roomUserAccount/getMeetingRecordForAppointment' // 获取当前自己参与的预约会议记录
export const createMeetingInvitation = 'roomUserAccount/createMeetingInvitation' // 在会议内创建会议邀请链接
export const getRoomUniqueNoByInvitation = 'room/getRoomUniqueNoByInvitation' // 根据邀请链接或者会议数字编号获取会议信息
export const inviteToRoom = 'roomUserAccount/inviteToRoom' // 邀请加入房间接口
export const removeMeetingUser = 'roomUserAccount/removeMeetingUser' // 主讲强制移出某人
export const roomLock = 'room/roomLock' // 主讲操作会议锁定
export const createqrCode = 'qrCode/create' // 服务器二维码生成
export const customMessage = 'message/customMessage' // 自定义消息接口
export const communicationRecordUpdateInviteStatus =
  "communicationRecord/updateInviteStatus"; // 更新申请状态
export const fileUploadUserAccountHeadImg =
  "tmva/file/uploadUserAccountHeadImg"; // 上传用户头像
export const userAccountUpdateMyHeadImg = "userAccount/updateMyHeadImg"; // 更换用户头像
export const cloudRecordGetRecords = "cloudRecord/getRecords"; // 查询云录制列表
export const cloudRecordGetdownloadurl = "cloudRecord/getdownloadurl"; // 获取云录制下载地址
export const cloudRecordDelete = "cloudRecord/delete"; // 删除云录制
export const messageFindMy = "message/findMy"; // 查看我的消息
export const communicationRecordFind = "communicationRecord/find"; // 查询通讯消息
export const userAccountApplyAddContacts = "userAccount/applyAddContacts"; // 添加外部联系人
export const userAccountConfirmAddContacts = "userAccount/confirmAddContacts"; // 确认添加外部联系人
export const messageReadMessage = "message/readMessage"; // 查询通讯消息
export const userAccountGroupAddOutside = "userAccountGroup/addOutside"; // 添加分组
export const userAccountGroupUpdateOutside = "userAccountGroup/updateOutside"; // 重命名分组
export const userAccountGroupDeleteOutside = "userAccountGroup/deleteOutside"; // 删除分组
export const userAccountSetOutsideUserAccountGroup =
  "userAccount/setOutsideUserAccountGroup"; // 移动分组
export const setOutsideUserAccountGroup =
  "userAccount/setOutsideUserAccountGroup"; // 设置外部联系人分组接口
export const userAccountDeleteContacts = "userAccount/deleteContacts"; // 删除好友接口
export const webTokenLogin = "userAccount/webTokenLogin"; // 第三方静默登录
export const getCompanyConfig = 'companyConfig/getCompanyConfig' // 获取企业配置
export const uploadPushFile = 'file/uploadPushFile' // 上传文件推送信息
export const cancelPushFile = 'file/cancelPushFile' // 取消文件推送信息
export const getKkFileViewForPushFile = 'file/getKkFileViewForPushFile' // 根据推送的文件信息获取预览地址
export const getPushFileRecord = 'file/getPushFileRecord' // 获取文件推送记录
export const delPushFileRecord = 'file/delPushFileRecord' // 删除文件推送记录
export const getTempUploadSignature = 'file/getTempUploadSignature' // 获取临时文件上传签名接口
export const findUserAccountStatistics = 'statistics/findUserAccountStatistics' // 账号统计数据导出
export const findCompanyStatistics = 'statistics/findCompanyStatistics' // 企业统计数据导出
export const sendCode = '/code/sendCode' // 发送短信
export const findLatest = '/version/findLatest' // 查询最新版本接口
export const checkForUpdat = 'version/checkForUpdate' // APP下载

export const findShengWangAccount = '/shengwang/findShengWangAccount' // 查询账户的所有声网账户信息
export const getShengWangToken = '/shengwang/getShengWangToken' // 生成声网token
export const findShengWangInfo = '/shengwang/findShengWangInfo' // 查询声网应用信息
export const userfindInfo = '/userAccount/findInfo' // 查询账号详情
export const checkRoomJoinAble = '/room/checkRoomJoinAble' // 入会前检查
