import html2canvas from "html2canvas";
import Clipboard from 'clipboard';
import { format, diffHours } from '../../helpers/utils'
import QRCode from 'qrcodejs2'

export default {
  name: 'GuideDialog',
  props: {
    title: { // 显示标题
      type: String,
      default: ''
    },
    center: { // 标题和按钮是否居中
      type: Boolean,
      default: false
    },
    width: { // 宽度
      type: String,
      default: '800px'
    },
    height: { // 高度
      type: String,
      default: '500px'
    },
    value: { // 是否显示弹框
      type: Boolean,
      default: true
    },
    showFooter: { // 是否显示底部按钮
      type: Boolean,
      default: true
    },
    showConfirm: { // 是否显示确定按钮
      type: Boolean,
      default: false
    },
    meetingList: { // 预约数据
      type: Array,
      default: () => {},
    },
    isshowReserved: {
      type: Boolean,
      default: false
    },
    showClose: {
      type: Boolean,
      default: true
    },
  },
  components: {
    html2canvas,
    Clipboard,
    format,
    diffHours,
    QRCode
  },
  data() {
    return {
      innerVisible: false,
      loadVisiblePic: false,
      // 预约详情
      companyName: '',
      nickname: '',
      meetingTimeStart: '',
      meetingTimeEnd: '',
      theme: '',
      meetingID: '',
      URl: '',
      meetingHours: 0,
      htmlUrl: '',
      guidePicData: '',
      arrlength: '',
      command: '',
      qrcode: '',
      pageID: '',
      dropdownShow: false,
      getdropId: '',
    }
  },
  filters: {
    dateFilter(val) {
      return format(val, 'yyyy-MM-dd')
    },
    hoursFilter(val) {
      return format(val, 'hh:mm')
    },
    splitChange(val) {
      return val.replace(/\s/g, "").replace(/[^\d]/g, "").replace(/(\d{3})(?=\d)/g, "$1 ")
    }
  },
  watch: {
    getdropId: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.dropdownShow = true
        }
        if (oldVal) {
          if (document.getElementById('drop' + oldVal)) {
            document.getElementById('drop' + oldVal).style.display = "none"
          }
        }
        console.log(newVal)
        console.log(oldVal)
      },
    },
  },
  mounted() {
    const docHeight = document.body.clientHeight
    const docWidth = document.body.clientWidth
    const standarHeight = docHeight / 1080
    const ddw = document.getElementsByClassName('baseDialog')[0]
    this.$nextTick(() => {
      if (docWidth < 1680) {
        if (ddw) {
          ddw.childNodes[0].style.zoom = standarHeight;
        }
      }
    })
    document.addEventListener("click", (e) => {
      if (this.dropdownShow === true) {
        this.closeDropdownShow(e)
      }
    });
  },
  methods: {
    /**
     * 打开弹出框
     * */
    confirm() {
      this.$emit('confirm');
    },
    /**
     * 打开弹出框
     * */
    close() {
      this.$emit('close');
    },

    // 取消预约
    cancelMeeting(id) {
      this.$emit("cancelMeeting", id)
    },
    // 进入会议
    enterMetting(roomID) {
      this.$emit("enterMetting", roomID)
    },
    getDropdownShow(res) {
      const drop = 'drop' + res
      const dropID = document.getElementById(drop)
      if (dropID) {
        this.dropdownShow = false
      } else {
        this.dropdownShow = true
      }
      this.getdropId = res
    },
    closeDropdownShow(e) {
      const self = this;
      if (self.dropdownShow === true) {
        const tree = self.$refs.showDropMore
        if (tree) {
          self.dropdownShow = false;
        }
      }
    },
    // 查看预约
    innerVisibleCom(id) {
      this.innerVisible = true
      if (this.meetingList) {
        console.log(this.meetingList)
        this.pageID = id
        const listMeeti = this.meetingList.filter(item => item.id === id)
        this.companyName = listMeeti[0].userAccountData.name
        this.nickname = listMeeti[0].userAccountData.nickname
        this.meetingID = listMeeti[0].roomIntegerNumber
        this.theme = listMeeti[0].theme
        setTimeout(() => {
          const shareUrl = listMeeti[0].invitationLink;
          const qrcode = new QRCode(this.$refs.qrCodeUrl, {
            text: shareUrl,
            width: 150, // 图像宽度
            height: 150, // 图像高度
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.H
          });
          qrcode.clear(); // 清除二维码
          qrcode.makeCode(shareUrl); // 生成另一个新的二维码
        }, 100);
        setTimeout(() => {
          const shareUrl = listMeeti[0].invitationLink;
          const qrcode = new QRCode(this.$refs.inLoadHtml, {
            text: shareUrl,
            width: 120, // 图像宽度
            height: 120, // 图像高度
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.H
          });
          qrcode.clear(); // 清除二维码
          qrcode.makeCode(shareUrl); // 生成另一个新的二维码
        }, 100);
        // 服务器二维码
        this.URl = listMeeti[0].invitationLink
        this.meetingTimeStart = listMeeti[0].startTime
        this.meetingTimeEnd = listMeeti[0].validTime
        this.meetingHours = diffHours(listMeeti[0].startTime, listMeeti[0].validTime)
        this.guidePicData = this.$t('remoteGuideCopy') + this.theme + "\n\n" + this.$t('remoteGuideDodeCopy') + this.meetingID + "\n\n" + this.$t('remoteGuideTimeCopy') + format(listMeeti[0].startTime, 'yyyy-MM-dd hh:mm') + ' - ' + format(listMeeti[0].validTime, 'yyyy-MM-dd hh:mm') + '  ' + this.$t('remoteGuideGMTCopy') + '\n\n' + this.$t('remoteGuideLinkCopy') + listMeeti[0].invitationLink;
      }
    },

    // 导入图片
    loadPic() {
      const canvas = document.createElement("canvas")
      canvas.width = 750
      canvas.height = 1334
      canvas.style.width = 750 + 'px'
      canvas.style.height = 1334 + 'px'
      html2canvas(this.$refs.imageWrapper, { canvas: canvas, scale: 1 }).then(canvas => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png"); // 下载链接
        link.setAttribute("download", this.$t('appointmentRemoteMeetingPic') + ".png");
        link.style.display = "none"; // a标签隐藏
        document.body.appendChild(link);
        link.click();
      })
    },
    // 复制
    copyContent() {
      var clipboard = new Clipboard('.copyBtn')
      clipboard.on('success', e => {
        console.log('复制成功')
        console.log(e);
        //  释放内存
        clipboard.destroy()
        // 清除文本选中状态
        e.clearSelection()
      })
      clipboard.on('error', e => {
        // 不支持复制
        console.log('该浏览器不支持复制')
        // 释放内存
        clipboard.destroy()
      })
    }
  }
};
