/**
 * 表单验证规则
 * item包含验证字段{
 *    label:         提示语
 *    noNull: true   非空验证
 *    rule: 'mobile' 类型验证
 *    len: 4         长度验证(优先级高于min、max)
 *    min: 2         最小长度验证
 *    max: 6         最大长度验证
 * }
 * */
import { Message } from "element-ui";
import vueInstance from "./../main";

// 显示错误信息
function showMsg(msg) {
  Message({
    dangerouslyUseHTMLString: true,
    message: `<strong>${msg}</strong>`,
    customClass: "tipsMsg",
    offset: 15,
    duration: 3000,
    type: "warning"
  });
}

// 非空验证
function validateNoNull(formData, item) {
  if (!item.noNull) return true;
  const value = formData[item.prop];
  if (value || value === 0) return true;
  showMsg(
    `${item.label || "内容"}${vueInstance.$t("noNull").toLocaleLowerCase()}`
  );
}

// 类型验证
function validateType(formData, item) {
  const value = formData[item.prop];
  if (!value || !item.rule) return true;
  switch (item.rule) {
    case "mobile": // 手机号
      if (/^1\d{10}$/.test(value)) return true;
      showMsg(`${item.label}格式不正确`);
      break;
    case "phone": // 固话
      if (
        /^(0\d{2,3}-)?\d{7,8}(-\d+)?$|^0\d{10,11}(-\d+)?$|^\d{7,8}(-\d+)?$/.test(
          value
        )
      )
        return true;
      showMsg(`${item.label}格式不正确`);
      break;
    case "mobileOrPhone": // 手机号或固话
      if (
        /^(0\d{2,3}-)?\d{7,8}(-\d+)?$|^0\d{10,11}(-\d+)?$|^\d{7,8}(-\d+)?$|^1\d{10}$/.test(
          value
        )
      )
        return true;
      showMsg(`${item.label}格式不正确`);
      break;
    case "idCard": // 身份证
      if (/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value)) return true;
      showMsg(`${item.label}格式不正确`);
      break;
    case "positive0": // 大于等于0的数字
      if (/^(0|([1-9]\d*))(\.\d+)?$/.test(value) || Number(value) >= 0)
        return true;
      showMsg(`${item.label}应为大于等于0的数字`);
      break;
    case "positive": // 大于0的数字
      if (/^(0|([1-9]\d*))(\.\d+)?$/.test(value) && Number(value) > 0)
        return true;
      showMsg(`${item.label}应为大于0的数字`);
      break;
    case "int": // 整数
      if (/^-?[0-9]\d*$/.test(value)) return true;
      showMsg(`${item.label}应为整数`);
      break;
    case "positiveInt": // 正整数
      if (/^\d+$/.test(value) && Number(value) > 0) return true;
      showMsg(`${item.label}应为大于0的整数`);
      break;
    case "positiveInt0": // 大于等于0的整数
      if (/^[0-9]\d*$/.test(value)) return true;
      showMsg(`${item.label}应为大于等于0的整数`);
      break;
    case "email": // 邮箱
      if (
        /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/.test(
          value
        )
      )
        return true;
      showMsg(`${item.label}格式不正确`);
      break;
    case "numOrLetter": // 字母或数字
      if (/^[0-9a-zA-Z]*$/.test(value)) return true;
      showMsg(`${item.label}格式不正确`);
      break;
    case "letter": // 字母
      if (/^[a-zA-Z]*$/.test(value)) return true;
      showMsg(`${item.label}格式不正确`);
      break;
    case "chinese": // 中文
      if (/^[\u4e00-\u9fa5]*$/.test(value)) return true;
      showMsg(`${item.label}格式不正确`);
      break;
    default: // do something
  }
}

// 长度验证，优先判断长度，然后判断范围
function validateLen(formData, item) {
  let value = formData[item.prop];
  if (!item.len && !item.min && !item.max) return true;
  if (!value) return;
  value = value.toString();
  // 长度验证
  if (item.len && value.length !== item.len) {
    showMsg(`${item.label}长度应为${item.len}`);
    return;
  }
  // 最小长度验证
  if (item.min && !item.max && value.length < item.min) {
    showMsg(`${item.label}最小长度应为${item.min}`);
    return;
  }
  // 最大长度验证
  if (item.max && !item.min && value.length > item.max) {
    showMsg(`${item.label}最大长度应为${item.max}`);
    return;
  }
  // 长度范围验证
  if (value.length < item.min || value.length > item.max) {
    showMsg(`${item.label}最小长度应为${item.min}，最大长度应为{item.max}`);
    return;
  }
  return true;
}

// 返回验证结果
export default function(formData, rules) {
  if (!formData || !rules || !rules.length) {
    showMsg("数据格式不正确");
    return;
  }
  for (let i = 0; i < rules.length; i++) {
    const item = rules[i];
    // 非空验证
    if (!validateNoNull(formData, item)) return;
    // 类型验证
    if (!validateType(formData, item)) return;
    // 长度验证
    if (!validateLen(formData, item)) return;
  }
  return true;
}
