export default {
  props: {
    src: {
      // 图片预览地址
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showSrc: "", // 图片展示地址
      rotate: 0, // 旋转角度
      scale: 1, // 缩放比例
      left: 0, // 移动位置左边
      top: 0, // 移动位置顶部
      cacleLeft: 0, // 重置时用
      cacleTop: 0 // 重置时用
    };
  },
  mounted() {
    this.checkImage();
    window.addEventListener("mousewheel", this.mousewheel);
    window.addEventListener("DOMMouseScroll", this.mousewheel);
  },
  beforeDestroy() {
    window.removeEventListener("mousewheel", this.mousewheel);
    window.removeEventListener("DOMMouseScroll", this.mousewheel);
  },
  methods: {
    // 监听滑轮滚动事件
    mousewheel(e) {
      let down = true;
      if (e.wheelDelta) {
        if (e.wheelDelta > 0) down = true;
        else down = false;
      } else if (e.detail) {
        if (e.detail > 0) down = false;
        else down = true;
      }
      if (down) {
        this.scale = +parseFloat(this.scale + 0.2).toFixed(1);
      } else {
        if (this.scale <= 0.4) return;
        this.scale = +parseFloat(this.scale - 0.2).toFixed(1);
      }
      return false;
    },
    // 鼠标按下
    mousedown(e) {
      const { clientWidth, clientHeight } = e.target;
      this.isDraw = true;
      this.startPageX = e.pageX;
      this.startPageY = e.pageY;
      this.oldLeft = this.left;
      this.oldTop = this.top;
      this.oldWidth = clientWidth;
      this.oldHeight = clientHeight;
    },
    // 鼠标抬起
    mouseup() {
      this.isDraw = false;
    },
    // 鼠标移动
    mousemove(e) {
      if (!this.isDraw) return;
      const moveX = e.pageX - this.startPageX;
      const moveY = e.pageY - this.startPageY;
      // 获取移动距离
      this.left = this.oldLeft + moveX;
      this.top = this.oldTop + moveY;
    },
    // 检查图片是否可以访问
    checkImage() {
      const image = new Image();
      image.src = this.src;
      image.onload = () => {
        this.showSrc = this.src;
        this.setImagePostion();
      };
      image.onerror = () => {
        this.showSrc = require("./img/defaultImg.png");
        this.setImagePostion(200, 200);
      };
    },
    // 设置图片位置
    setImagePostion(defaultWidth, defaultHeight) {
      this.$nextTick(() => {
        const { width, height } = document.getElementById("currentImg");
        const { clientWidth, clientHeight } = document.documentElement;
        this.left = (clientWidth - (defaultWidth || width)) / 2;
        this.top = (clientHeight - (defaultHeight || height)) / 2;
        this.cacleLeft = this.left;
        this.cacleTop = this.top;
      });
    },
    // 向左旋转
    rotateLeft() {
      this.rotate = this.rotate - 90;
    },
    // 向右旋转
    rotateRight() {
      this.rotate = this.rotate + 90;
    },
    // 缩小
    scaleReduce() {
      if (this.scale <= 0.4) return;
      this.scale = +parseFloat(this.scale - 0.2).toFixed(1);
    },
    // 放大
    scaleAdd() {
      this.scale = +parseFloat(this.scale + 0.2).toFixed(1);
    },
    // 重置缩放
    resetScale() {
      this.scale = 1;
      this.rotate = 0;
      this.left = this.cacleLeft;
      this.top = this.cacleTop;
    },
    // 下载
    downLoad() {
      this.$utils.downloadImg(this.showSrc)
    },
    // 关闭
    close() {
      this.$emit("close");
    }
  }
};
